import React from "react"
import { Card } from "react-bootstrap"

function OrgBasicCard({ organization }) {
  return (
    <Card body={true}>
      <div className="d-flex align-items-center align-middle">
        <img
          src={organization.logo}
          alt={organization.name}
          width={40}
          height={40}
          className="rounded-circle me-2"
        />
        <div className={"ms-2"}>{organization.name}</div>
      </div>
    </Card>
  )
}

export default OrgBasicCard
