import React, { useEffect, useState } from "react"
import { FaFolder, FaFolderOpen } from "react-icons/fa"
import { ListGroup } from "react-bootstrap"
import { LoadingView } from "../../../utils/components"
import { apiRequest } from "../../../utils/apiRequests"
import { useToast } from "../../../utils/functions"

const FolderTree = ({
  onDoubleClick,
  isTemplate,
  noActionIds = [],
  openAll = true,
}) => {
  const [folders, setFolders] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { showToast } = useToast()

  useEffect(() => {
    getFolders()
  }, [])

  const getFolders = async () => {
    setIsLoading(true)
    const { success, response } = await apiRequest.get(
      `/document/folders?template=${isTemplate}`
    )

    if (success) {
      setFolders(response.folders)
    } else {
      showToast({
        message: response.message,
        success: false,
      })
    }

    setIsLoading(false)
  }

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <ListGroup>
          <p
            className={"small pointer"}
            onDoubleClick={() => {
              onDoubleClick && onDoubleClick({ id: null, name: "No Folder" })
            }}
          >
            No Folder
          </p>
          {folders.map((folder) => (
            <FolderNode
              key={folder.id}
              folder={folder}
              onDoubleClick={onDoubleClick}
              noActionIds={noActionIds}
              openAll={openAll}
            />
          ))}
        </ListGroup>
      }
    />
  )
}

const FolderNode = ({ folder, onDoubleClick, noActionIds, openAll }) => {
  const [isOpen, setIsOpen] = useState(openAll)
  const canMove = !noActionIds.includes(folder.id)

  return (
    <div className="ms-2">
      <div
        className={`d-flex align-items-center gap-2 mb-2 ${
          canMove ? "pointer" : "text-muted"
        }`}
        onClick={() => setIsOpen(!isOpen)}
        onDoubleClick={
          onDoubleClick && canMove ? () => onDoubleClick(folder) : undefined
        }
      >
        {isOpen ? (
          <FaFolderOpen
            className={`${canMove ? "text-warning" : "text-muted"}`}
          />
        ) : (
          <FaFolder color="goldenrod" />
        )}
        <span>{folder.name}</span>
      </div>

      {isOpen && folder.sub_folders?.length > 0 && (
        <ListGroup className="ms-1">
          {folder.sub_folders.map((subFolder) => (
            <FolderNode
              key={subFolder.id}
              folder={subFolder}
              onDoubleClick={onDoubleClick}
              noActionIds={noActionIds}
              openAll={openAll}
            />
          ))}
        </ListGroup>
      )}
    </div>
  )
}

export default FolderTree
