import React, { useEffect, useState } from "react"
import { Card, Button, ProgressBar, Alert } from "react-bootstrap"
import axios from "axios"
import { FaTimes } from "react-icons/fa"

const DOC_EXT_TO_CONTENT_TYPE = {
  pdf: "application/pdf",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ppt: "application/vnd.ms-powerpoint",
  xls: "application/vnd.ms-excel",
  doc: "application/msword",
}

const FileUploadingView = ({
  file,
  presignedUrl,
  documentName,
  onSuccessAction,
  onSuccessBtnTxt,
  onClose,
}) => {
  const [uploadProgress, setUploadProgress] = useState(0)
  const [error, setError] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [uploading, setUploading] = useState(false)

  // Function to upload the file
  const uploadFile = async () => {
    if (!file || !presignedUrl) {
      setError("File or URL missing.")
      return
    }

    setUploading(true)
    setError(null)
    setSuccessMessage(null)
    const fileExt = file.name.split(".").pop().toLowerCase()

    try {
      // Upload the file to S3
      await axios.put(presignedUrl, file, {
        headers: {
          "Content-Type": DOC_EXT_TO_CONTENT_TYPE[fileExt],
          "Content-Disposition": "inline",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          setUploadProgress(percentCompleted)
        },
      })

      setSuccessMessage(`Upload successful for ${documentName}`)
    } catch (err) {
      setError(
        "Upload failed. Please try again or contact your admin if the error persists."
      )
    } finally {
      setUploading(false)
    }
  }

  useEffect(() => {
    uploadFile()
  }, [])

  if (!file || !presignedUrl) {
    return null
  } else {
    return (
      <Card className="text-center p-4 shadow w-100">
        <Card.Body>
          <h6>
            Uploading Document: <strong>{documentName}</strong>
          </h6>
          {uploadProgress > 0 && (
            <ProgressBar
              now={uploadProgress}
              label={`${uploadProgress}%`}
              animated
              className="mt-3"
            />
          )}

          {error && (
            <div>
              <Alert
                variant="danger"
                className="mt-3"
              >
                {error}
              </Alert>
              <Button
                variant="primary"
                className="mt-3"
                onClick={uploadFile}
                disabled={uploading}
              >
                Try Again
              </Button>
            </div>
          )}
          {successMessage && (
            <div>
              <Alert
                variant="success"
                className="mt-3"
              >
                {successMessage}
              </Alert>

              <Button
                variant="primary"
                className="mt-3"
                disabled={uploading}
                onClick={onSuccessAction}
              >
                {onSuccessBtnTxt}
              </Button>
            </div>
          )}

          {(error || successMessage) && (
            <div className={"mt-3"}>
              <Button
                variant="outline-danger"
                onClick={onClose}
              >
                <FaTimes className={"me-2"} /> Close
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
    )
  }
}

export default FileUploadingView
