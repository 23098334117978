import React, { useContext } from "react"
import {
  Accordion,
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
} from "react-bootstrap"
import { useAppModal, useOffCanvas } from "../../../utils/functions"
import { AppContext } from "../../../utils/components/AppContext"
import { AddItemButton, NoInfoCard } from "../../../utils/components"
import TimeOffPolicyForm from "../../Forms/Employee/TimeOffPolicyForm"
import { ApprovalWorkflowForm } from "../../Forms/Organization/ApprovalWorkflowForm"
import { FaEdit, FaTrash } from "react-icons/fa"
import { ApprovalWorkflowStepForm } from "../../Forms/Organization/ApprovalWorkflowStepForm"
import OrgEntitySelection from "../../Forms/Shared/OrgEntitySelection"
import GenericForm from "../../Forms/Shared/GenericForm"
import { SharedFormConfigs } from "../../Forms/FormsConfigs/SharedFormConfigs"
import { EmployeePopover } from "../EmployeeCards"

function ApprovalWorkflowsView({
  workflows,
  setWorkflows,
  positions,
  canManage,
  workflowType,
  putRequest,
  patchRequest,
  deleteRequest,
  addSupervisorStep,
  isMultiOrg,
}) {
  const { showOffCanvas } = useOffCanvas()
  const { updateContextState, setGlobalLoading } = useContext(AppContext)
  const { showAppModal } = useAppModal()

  return (
    <div>
      {canManage && (
        <div className={"mb-4"}>
          <AddItemButton
            title={"New Approval Workflow"}
            onClick={() => {
              updateContextState({
                formData: {
                  add_supervisor_step: addSupervisorStep,
                  type: workflowType,
                },
              })
              showOffCanvas({
                title: `Approval Workflow`,
                subtitle: `Add a new approval workflow.`,
                component: (
                  <ApprovalWorkflowForm
                    onSubmit={(updatedData) => {
                      putRequest(
                        "/organization/approval-workflows",
                        setGlobalLoading,
                        (response) => {
                          setWorkflows([
                            response.approval_workflow,
                            ...workflows,
                          ])
                        },
                        updatedData
                      )
                    }}
                  />
                ),
              })
            }}
          />
        </div>
      )}

      {workflows.length === 0 && (
        <NoInfoCard text={"No approval workflows have been added yet."} />
      )}

      <Row>
        {workflows.map((workflow, i) => {
          return (
            <Accordion
              className={"mb-4"}
              key={i}
              title={workflow.name}
            >
              <Accordion.Header className={"bg-primary text-white"}>
                {workflow.name}
              </Accordion.Header>
              <Accordion.Body>
                <Card className={"border-primary border-1 rounded-3"}>
                  <Card.Header
                    className={"py-4 d-flex justify-content-between"}
                  >
                    <div>
                      <Card.Title
                        as={"h4"}
                        className={"mb-3"}
                      >
                        {workflow.name}
                      </Card.Title>
                      <Card.Subtitle>{workflow.description}</Card.Subtitle>
                    </div>
                    <div>
                      {canManage && (
                        <Button
                          variant={"outline-primary"}
                          onClick={() => {
                            updateContextState({
                              formData: { ...workflow },
                            })
                            showOffCanvas({
                              title: `Edit ${workflow.name}`,
                              subtitle: `Edit the approval workflow.`,
                              component: (
                                <ApprovalWorkflowForm
                                  onSubmit={(updatedData) => {
                                    patchRequest(
                                      `/organization/approval-workflows/${workflow.id}`,
                                      setGlobalLoading,
                                      (response) => {
                                        const allWorkflows = [...workflows]
                                        allWorkflows[i] =
                                          response.approval_workflow
                                        setWorkflows(allWorkflows)
                                      },
                                      updatedData
                                    )
                                  }}
                                />
                              ),
                            })
                          }}
                        >
                          <FaEdit className={"me-2"} /> Edit Workflow
                        </Button>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <h4 className={"mb-3"}>Approval Steps</h4>
                    {workflow.steps.map((step, stepIndex) => {
                      return (
                        <Card
                          className={"mb-4 ms-3"}
                          key={stepIndex}
                        >
                          <Card.Header
                            className={"py-4 d-flex justify-content-between"}
                          >
                            <div>
                              <Card.Title
                                as={"h5"}
                                className={"mb-3"}
                              >
                                {step.order_position}. {step.name}
                              </Card.Title>
                              <Card.Subtitle>{step.description}</Card.Subtitle>
                            </div>
                            {canManage && !step.for_direct_supervisor && (
                              <div className={"d-flex flex-row"}>
                                <Button
                                  variant={"outline-primary"}
                                  onClick={() => {
                                    const stepcopy = { ...step }
                                    delete stepcopy.entities_data

                                    updateContextState({
                                      formData: {
                                        ...stepcopy,
                                        ...step.entities_data,
                                      },
                                    })
                                    showOffCanvas({
                                      title: `Edit ${step.name}`,
                                      subtitle: `Edit approval step.`,
                                      backdropClose: true,
                                      widthPercentage: 40,
                                      component: (
                                        <GenericForm
                                          formConfigs={SharedFormConfigs.approvalWorkflowStep(
                                            isMultiOrg
                                          )}
                                          onSubmit={(updatedData) => {
                                            patchRequest(
                                              `/organization/approval-workflow/${workflow.id}/approval-step/${step.id}`,
                                              setGlobalLoading,
                                              (response) => {
                                                const allWorkflows = [
                                                  ...workflows,
                                                ]
                                                allWorkflows[i] =
                                                  response.approval_workflow
                                                setWorkflows(allWorkflows)
                                              },
                                              updatedData
                                            )
                                          }}
                                        />
                                      ),
                                    })
                                  }}
                                >
                                  <FaEdit className={"me-2"} /> Edit Step
                                </Button>

                                {step.can_delete && (
                                  <Button
                                    variant={"outline-danger"}
                                    className={"ms-2"}
                                    onClick={() => {
                                      showAppModal({
                                        title: `Delete ${step.name}?`,
                                        component: (
                                          <p>
                                            Are you sure you want to delete the
                                            step <strong>{step.name}</strong>?
                                          </p>
                                        ),
                                        truthyFunction: () => {
                                          deleteRequest(
                                            `/organization/approval-workflow/${workflow.id}/approval-step/${step.id}`,
                                            setGlobalLoading,
                                            (response) => {
                                              const allWorkflows = [
                                                ...workflows,
                                              ]
                                              allWorkflows[i] =
                                                response.approval_workflow
                                              setWorkflows(allWorkflows)
                                            }
                                          )
                                        },
                                      })
                                    }}
                                  >
                                    <FaTrash />
                                  </Button>
                                )}
                              </div>
                            )}
                            {canManage && step.for_direct_supervisor && (
                              <p>This step cannot be modified</p>
                            )}
                          </Card.Header>
                          <Card.Body>
                            <div>
                              <h5 className={"mb-3"}>Approvers</h5>
                              <ul>
                                {step.approvers.map((approver, i) => {
                                  return (
                                    <li
                                      key={i}
                                      className={"fs-6 mb-3"}
                                    >
                                      {approver.is_employee && (
                                        <OverlayTrigger
                                          trigger="click"
                                          placement="top"
                                          overlay={
                                            <EmployeePopover
                                              employee={approver}
                                            />
                                          }
                                          rootClose
                                        >
                                          <Button
                                            variant="link"
                                            className=""
                                          >
                                            {approver.full_name}
                                          </Button>
                                        </OverlayTrigger>
                                      )}
                                      {!approver.is_employee && (
                                        <span>{approver.title}</span>
                                      )}
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          </Card.Body>
                        </Card>
                      )
                    })}
                  </Card.Body>

                  {canManage && (
                    <div className={"d-flex justify-content-center my-4"}>
                      <AddItemButton
                        title={"Add Step"}
                        onClick={() => {
                          updateContextState({
                            formData: {},
                          })
                          showOffCanvas({
                            title: `Add Step to "${workflow.name}"`,
                            subtitle: `Add a new step to the approval workflow.`,
                            backdropClose: true,
                            widthPercentage: 40,
                            component: (
                              <div>
                                <GenericForm
                                  formConfigs={SharedFormConfigs.approvalWorkflowStep(
                                    isMultiOrg
                                  )}
                                  onSubmit={(updatedData) => {
                                    putRequest(
                                      `/organization/approval-workflow/${workflow.id}/approval-step`,
                                      setGlobalLoading,
                                      (response) => {
                                        const allWorkflows = [...workflows]
                                        allWorkflows[i] =
                                          response.approval_workflow
                                        setWorkflows(allWorkflows)
                                      },
                                      updatedData
                                    )
                                  }}
                                />
                              </div>
                            ),
                          })
                        }}
                      />
                    </div>
                  )}
                </Card>
              </Accordion.Body>
            </Accordion>
          )
        })}
      </Row>
    </div>
  )
}

export default ApprovalWorkflowsView
