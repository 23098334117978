import React, { useState, useRef } from "react"
import {
  Table,
  Button,
  OverlayTrigger,
  Card,
  Popover,
  Overlay,
  Badge,
} from "react-bootstrap"
import {
  FaCog,
  FaEdit,
  FaGem,
  FaLock,
  FaUserLock,
  FaUsersCog,
} from "react-icons/fa"
import { EmployeePopover } from "./EmployeeCards"

export const PermissionPopover = React.forwardRef(
  ({ permission, ...props }, ref) => {
    return (
      <Popover
        ref={ref}
        {...props}
      >
        <Popover.Header
          as="h3"
          className={"bg-primary text-white py-3"}
        >
          {permission?.name}
        </Popover.Header>
        <Popover.Body>
          <div>{permission?.description}</div>
        </Popover.Body>
        <div
          className="popover-arrow"
          {...props.arrowProps}
        ></div>
      </Popover>
    )
  }
)

export function PermissionGroupHighlight({
  group,
  onManagePermissions,
  onManageMembersClick,
  onEditClick,
  canManage,
}) {
  const { id, name, description, is_default, permissions, members } = group

  return (
    <Card className={"mb-4 border-top"}>
      <Card.Header>
        <Card.Title>
          {name}
          {is_default && (
            <Badge
              className={"ms-2"}
              bg={"primary"}
              pill={true}
            >
              Default Group
            </Badge>
          )}
        </Card.Title>
        <div className={"pt-2 mb-3"}>
          <Card.Subtitle>{description}</Card.Subtitle>
        </div>
      </Card.Header>
      <Card.Body>
        <Table
          striped
          bordered
          hover
          className="mt-4"
          responsive={true}
        >
          <tbody>
            <tr>
              <td>Permissions</td>
              <td>
                {permissions.map((permission) => (
                  <OverlayTrigger
                    key={permission.id}
                    trigger="click"
                    placement="top"
                    overlay={<PermissionPopover permission={permission} />}
                    rootClose
                  >
                    <Button
                      variant="link"
                      className=""
                    >
                      {permission.name}
                    </Button>
                  </OverlayTrigger>
                ))}
              </td>
            </tr>
            <tr>
              <td>Members</td>
              <td>
                {group.is_all_employees && (
                  <p>
                    This group contains all active staff members. You cannot
                    directly add or remove members from this group. When you add
                    a new employee or deactivate an existing one, they will be
                    added or removed from this group accordingly.
                  </p>
                )}
                {members.map((member) => (
                  <OverlayTrigger
                    key={member.id}
                    trigger="click"
                    placement="top"
                    overlay={<EmployeePopover employee={member} />}
                    rootClose
                  >
                    <Button
                      variant="link"
                      className=""
                    >
                      {member.full_name}
                    </Button>
                  </OverlayTrigger>
                ))}
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
      {canManage && (
        <Card.Footer>
          <div className="d-flex justify-content-end py-3">
            {onEditClick && (
              <Button
                onClick={() => {
                  onEditClick()
                }}
                variant={"outline-primary"}
                className="me-2"
              >
                <FaEdit className={"me-2"} />
                Edit
              </Button>
            )}
            {onManagePermissions && (
              <Button
                onClick={() => {
                  onManagePermissions(permissions)
                }}
                variant="primary"
                className="me-2"
              >
                <FaUserLock className={"me-2"} />
                Permissions
              </Button>
            )}
            {onManageMembersClick && !group.is_all_employees && (
              <Button
                onClick={() => {
                  onManageMembersClick(group)
                }}
                variant="secondary"
                className="me-2"
              >
                <FaUsersCog className={"me-2"} />
                Members
              </Button>
            )}
          </div>
        </Card.Footer>
      )}
    </Card>
  )
}
