import React, { useContext } from "react"
import { Button, OverlayTrigger, Table } from "react-bootstrap"
import { NoInfoCard, TextWithLineBreaks } from "../../../utils/components"
import { FaTrashCan } from "react-icons/fa6"
import { FaEdit } from "react-icons/fa"
import { AppContext } from "../../../utils/components/AppContext"
import { Products } from "../../../enums/OrgEnums"
import OrgBasicCard from "../Organization/OrgBasicCard"
import EmployeeVerticalHighlightCard from "../Employee/EmployeeVerticalHighlightCard"
import { toTitleCase } from "../../../utils/functions"
import { EmployeePopover } from "../EmployeeCards"

function DocMembersTable({
  membersInfo,
  canManage,
  onDelete,
  onEdit,
  showPermissions = true,
  showRole = false,
  emptyText = "This document has not been shared with anyone",
}) {
  const { hasOrgProduct } = useContext(AppContext)
  const isMultiOrg = hasOrgProduct(Products.MULTI_ORGANIZATION)

  if (!membersInfo || membersInfo.length === 0) {
    return <NoInfoCard text={emptyText} />
  } else {
    return (
      <div>
        <Table
          striped
          bordered
          className={"text-center"}
          responsive={true}
        >
          <thead>
            <tr>
              {isMultiOrg && <th>organization</th>}
              <th>Shared By</th>
              <th>Entity Type</th>
              <th>Entity Names</th>
              {showRole && <th>Role</th>}
              {showPermissions && <th>Permissions</th>}
            </tr>
          </thead>
          <tbody>
            {membersInfo.map((member, index) => {
              const mng = canManage(member)
              return (
                <tr key={index}>
                  {isMultiOrg && (
                    <td>
                      <OrgBasicCard organization={member.organization} />
                    </td>
                  )}
                  <td>
                    <OverlayTrigger
                      trigger="click"
                      placement="top"
                      overlay={
                        <EmployeePopover employee={member.author_employee} />
                      }
                      rootClose
                    >
                      <Button
                        variant="link"
                        className=""
                      >
                        {member.author_employee.full_name}
                      </Button>
                    </OverlayTrigger>
                  </td>
                  <td>{member.type}</td>
                  <td>
                    <TextWithLineBreaks text={member.entity_names.join("\n")} />
                  </td>
                  {showRole && <td>{toTitleCase(member.role)}</td>}
                  {showPermissions && (
                    <td>
                      <TextWithLineBreaks
                        text={member.permissions.join("\n")}
                      />
                    </td>
                  )}
                  {mng && (
                    <td>
                      <Button
                        variant={"outline-primary"}
                        size={"sm"}
                        onClick={() => {
                          onEdit(member)
                        }}
                      >
                        <FaEdit />
                      </Button>
                      <Button
                        variant={"outline-danger"}
                        size={"sm"}
                        onClick={() => {
                          onDelete(member)
                        }}
                      >
                        <FaTrashCan />
                      </Button>
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

export default DocMembersTable
