import React, { useState } from "react"
import { ConditionalView, LoadingView } from "../../../utils/components"
import { Button } from "react-bootstrap"
import { FaChevronDown } from "react-icons/fa"
import { toQueryParams } from "../../../utils/functions"

function PagingItemsLoading({
  pagingInfo,
  endpoint,
  getRequest,
  onLoadComplete,
}) {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <div className={"my-4"}>
      <LoadingView
        isLoading={isLoading}
        centerLoader={false}
        fullHeight={false}
        spinnerText={""}
        text={""}
        view={
          <div className={"d-flex justify-content-center"}>
            <ConditionalView
              condition={pagingInfo.has_next}
              trueView={
                <Button
                  variant={"link"}
                  size={"md"}
                  onClick={() => {
                    const queryParams = toQueryParams(pagingInfo)
                    if (endpoint.includes("?")) {
                      endpoint += `&${queryParams}`
                    } else {
                      endpoint += `?${queryParams}`
                    }
                    getRequest(endpoint, setIsLoading, (res) => {
                      onLoadComplete(res)
                    })
                  }}
                  className={"w-100"}
                >
                  Load More <FaChevronDown className={"ms-2"} />
                </Button>
              }
              falseView={<div />}
            />
          </div>
        }
      />
    </div>
  )
}

export default PagingItemsLoading
