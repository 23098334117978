import React, { useContext } from "react"
import { NoInfoCard, StatusBadge } from "../../../utils/components"
import { Button, OverlayTrigger, Table } from "react-bootstrap"
import { FaWpforms } from "react-icons/fa"
import { EmployeePopover } from "../EmployeeCards"
import { dateFormats, formatDateInDayJs } from "../../../utils/dateTime"
import { AppContext } from "../../../utils/components/AppContext"

function FormMemberFormsTable({ forms, onRowClick }) {
  const { contextState, updateContextState, setGlobalLoading } =
    useContext(AppContext)
  const { employee } = contextState

  if (forms.length === 0) {
    return <NoInfoCard text={"There are no forms to display"} />
  } else {
    return (
      <div>
        <Table
          className={"table-striped"}
          responsive={true}
        >
          <thead>
            <tr>
              <th
                style={{
                  maxWidth: "50px",
                }}
              ></th>
              <th>Name</th>
              <th>Submissions</th>
              <th>Pending Submissions</th>
              <th>Form Owner</th>
              <th>Created On</th>
              <th>State</th>
            </tr>
          </thead>
          <tbody>
            {forms.map((form) => {
              return (
                <tr
                  key={form.id}
                  className={"align-middle pointer"}
                  onClick={() => {
                    onRowClick(form)
                  }}
                >
                  <td
                    className={
                      "align-items-center text-center align-middle fs-6"
                    }
                  >
                    <FaWpforms size={20} />
                  </td>
                  <td className={""}>{form.name}</td>
                  <td>{form.submissions_count}</td>
                  <td>{form.pending_submissions_count}</td>
                  <td
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    {form.author_employee.id !== employee.id ? (
                      <div>
                        <OverlayTrigger
                          trigger="click"
                          placement="top"
                          overlay={
                            <EmployeePopover employee={form.author_employee} />
                          }
                          rootClose
                        >
                          <Button
                            variant="link"
                            className=""
                          >
                            {form.author_employee.full_name}
                          </Button>
                        </OverlayTrigger>
                      </div>
                    ) : (
                      "You"
                    )}
                  </td>
                  <td>
                    {formatDateInDayJs(
                      form.timestamp,
                      dateFormats.shortDayShortMonthFullDayYearTime12Hr
                    )}
                  </td>
                  <td>
                    <StatusBadge status={form.status} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

export default FormMemberFormsTable
