import { FormFieldTypes } from "../../FormFields"

const SharedFormConfigs = {
  SingleEntitySelection: ({
    labelName = "Select The Organization Entity to Use",
    alertMsg,
    info,
  }) => {
    return [
      {
        valueKey: "entity_ids",
        type: FormFieldTypes.ENTITY_SELECTION,
        labelName: labelName,
        alertMsg: alertMsg,
        info: info,
        includeList: ["Positions", "Select Employees"],
        entitySelectionProps: {
          is_single_person: true,
        },
      },
    ]
  },
  nameAndDescription: (nameLabel = null, descriptionLabel = null) => {
    return [
      {
        valueKey: "name",
        labelName: nameLabel,
      },
      {
        valueKey: "description",
        type: FormFieldTypes.TEXT_AREA,
        labelName: descriptionLabel,
        isRequired: false,
      },
    ]
  },
  approvalWorkflowStep: (isMultiOrg = false) => {
    return [
      {
        valueKey: "name",
        labelName: "Step Name",
      },
      {
        valueKey: "description",
        type: FormFieldTypes.TEXT_AREA,
        labelName: "Step Description",
      },
      {
        valueKey: "entity_ids",
        type: FormFieldTypes.ENTITY_SELECTION,
        labelName: "Step Approvers",
        includeList: ["Select Employees", "Positions"],
        entitySelectionProps: {
          is_single_person: true,
          multiOrgEntities: isMultiOrg,
        },
      },
    ]
  },
}

export { SharedFormConfigs }
