import React, { useContext, useEffect, useState } from "react"
import { AppContext } from "../../../utils/components/AppContext"
import { useNavigate, useParams } from "react-router-dom"
import {
  AddItemButton,
  AppModalLocal,
  AppOffCanvasLocal,
  LoadingView,
} from "../../../utils/components"
import DashboardPageContainer from "../DashboardPageContainer"
import DocumentEditor from "./DocumentEditor"
import {
  toTitleCase,
  updateObjectState,
  useAppModal,
  useOffCanvas,
} from "../../../utils/functions"
import AppBreadCrumb from "../Shared/AppBreadCrumb"
import { AppRoutes } from "../../../utils/appRoutes"
import PageMetaTags from "../Shared/PageMetaTags"
import { Alert, Button, Card } from "react-bootstrap"
import { FaChevronLeft, FaShareAlt, FaUsersCog } from "react-icons/fa"
import DocMembersTable from "./DocMembersTable"
import GenericForm from "../../Forms/Shared/GenericForm"
import DocumentFormConfig from "../../Forms/FormsConfigs/DocumentFormConfig"
import OrgEntitySelection from "../../Forms/Shared/OrgEntitySelection"
import { MdOutlinePublish } from "react-icons/md"
import DocToolbar from "./DocToolbar"
import {
  CollabDocShareRoles,
  DocumentPermissions,
  DocumentRoles,
} from "../../../enums/DocumentsEnums"
import Badge from "react-bootstrap/Badge"
import { OffCanvasContext } from "../../../utils/components/OffCanvasContext"
import { FaFileCirclePlus } from "react-icons/fa6"
import { FormFieldTypes } from "../../FormFields"
import { DocIcon } from "./DocumentsUtils"
import { Products } from "../../../enums/OrgEnums"
import ProductPermissionCheckView from "../ProductPermissionCheckView"

function CollaborativeDoc({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
}) {
  const { contextState, updateContextState, setGlobalLoading } =
    useContext(AppContext)
  const { showAppModal, closeModal } = useAppModal()
  const { permissions } = contextState
  const { showOffCanvas } = useOffCanvas()
  const { employee } = contextState
  const { closeOffCanvas } = useContext(OffCanvasContext)

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [doc, setDoc] = useState({ author_employee: {}, permissions: [] })
  const [showMembersOffCanvas, setShowMembersOffCanvas] = useState(false)
  const [data, setData] = useState({})
  const [showAddCollaboratorModal, setShowAddCollaboratorModal] =
    useState(false)

  const { id } = useParams()

  const isDraft = window.location.pathname.includes("draft")

  useEffect(() => {
    let endpoint = `/document/${id}/${isDraft ? "draft" : "published"}/info`
    getRequest(endpoint, setIsLoading, (response) => {
      setDoc(response.doc)
    })
  }, [id])

  const hasPermission = (permission) => {
    return doc.permissions && doc.permissions.includes(permission)
  }

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <ProductPermissionCheckView product={Products.DOCUMENTS_MANAGEMENT}>
          <div>
            <PageMetaTags title={`Collaborative Document`} />

            {doc.id && (
              <AppBreadCrumb
                items={[
                  ...doc.folder_path,
                  { text: doc.name || "Document View" },
                ]}
              />
            )}

            {doc.id && (
              <div className={"d-flex justify-content-end my-3 gap-2"}>
                {doc.is_template && (
                  <Badge
                    bg={"info"}
                    className={"text-dark py-2 fw-bold"}
                  >
                    TEMPLATE
                  </Badge>
                )}

                <Badge
                  className={`py-2 fw-bold ${
                    doc.is_draft ? "text-dark" : "text-light"
                  }`}
                  bg={doc.is_draft ? "warning" : "success"}
                >
                  {isDraft ? "DRAFT" : "PUBLISHED"}
                </Badge>
              </div>
            )}

            {doc.id && (
              <div>
                <Card>
                  <Card.Header className={"py-3 align-middle"}>
                    <div className={"d-flex flex-row"}>
                      <DocIcon
                        extension={doc.extension}
                        size={32}
                      />
                      <div className={"ms-3"}>
                        <Card.Title
                          as={"p"}
                          className={"mb-1"}
                        >
                          {`${doc.name}${
                            doc.version_number
                              ? ` - Version ${doc.version_number}`
                              : ""
                          }`}
                        </Card.Title>
                        <small>{doc.description}</small>
                      </div>
                    </div>
                  </Card.Header>
                  <DocToolbar
                    doc={doc}
                    postRequest={postRequest}
                    showDownload={false}
                    getRequest={getRequest}
                    putRequest={putRequest}
                    showActivities={
                      !isDraft && hasPermission(DocumentPermissions.VIEW)
                    }
                    showViews={
                      !isDraft && hasPermission(DocumentPermissions.VIEW)
                    }
                    onMembersClick={() => {
                      setShowMembersOffCanvas(true)
                    }}
                    canChat={hasPermission(DocumentPermissions.CHAT)}
                    showChats={!doc.is_template}
                    onDelete={
                      doc.author_employee.id === employee.id &&
                      doc.is_draft &&
                      !doc.is_published
                        ? () => {
                            deleteRequest(
                              `/document/version/${doc.id}`,
                              setGlobalLoading,
                              (response) => {
                                navigate(AppRoutes.Documents.path)
                              }
                            )
                          }
                        : null
                    }
                    rightElements={
                      <div className={"d-flex justify-content-end gap-2"}>
                        {doc.author_employee.id === employee.id && isDraft && (
                          <>
                            <Button
                              variant={"success"}
                              onClick={() => {
                                showAppModal({
                                  title: "Publish Document",
                                  component: (
                                    <div>
                                      <Alert variant={"warning"}>
                                        Publishing this document will disable
                                        all editing on it. You will have to send
                                        the published version to people, people
                                        will be able to comment on it after
                                        publishing.
                                      </Alert>
                                    </div>
                                  ),
                                  truthyFunction: () => {
                                    patchRequest(
                                      `/document/version/${doc.id}/publish`,
                                      setGlobalLoading,
                                      (response) => {
                                        showAppModal({
                                          title:
                                            "Document Published Successfully",
                                          component: (
                                            <h5>
                                              Do you want to view the published
                                              document?
                                            </h5>
                                          ),
                                          truthyFunction: () => {
                                            navigate(
                                              `${AppRoutes.DocumentCollaborationPublished.paramLessPath}/${response.doc_id}`
                                            )
                                          },
                                        })
                                        // navigate back to documents
                                        navigate(`${AppRoutes.Documents.path}`)
                                      },
                                      {},
                                      false,
                                      false,
                                      false
                                    )
                                  },
                                })
                              }}
                            >
                              <MdOutlinePublish className={"me-2"} /> Publish
                            </Button>
                          </>
                        )}

                        {/*{hasPermission(DocumentPermissions.SHARE) && (*/}
                        {/*  <Button*/}
                        {/*    variant={"primary"}*/}
                        {/*    onClick={() => {*/}
                        {/*      setShowMembersOffCanvas(true)*/}
                        {/*    }}*/}
                        {/*  >*/}
                        {/*    {isDraft ? (*/}
                        {/*      <span>*/}
                        {/*        <FaUsersCog className={"me-2"} /> Collaborators*/}
                        {/*      </span>*/}
                        {/*    ) : (*/}
                        {/*      <span>*/}
                        {/*        <FaShareAlt className={"me-2"} /> Share*/}
                        {/*      </span>*/}
                        {/*    )}*/}
                        {/*  </Button>*/}
                        {/*)}*/}

                        {doc.is_published && doc.is_template && (
                          <Button
                            variant={"primary"}
                            onClick={() => {
                              showOffCanvas({
                                title: "New Document Info",
                                component: (
                                  <div>
                                    <GenericForm
                                      formConfigs={DocumentFormConfig.docInfo}
                                      onSubmit={async (data) => {
                                        closeOffCanvas()
                                        putRequest(
                                          `/document/generate-version-id?template-id=${doc.id}`,
                                          setGlobalLoading,
                                          (response) => {
                                            navigate(
                                              `${AppRoutes.DocumentCollaborationDraft.paramLessPath}/${response.document_id}`
                                            )
                                          },
                                          data
                                        )
                                      }}
                                    />
                                  </div>
                                ),
                              })
                            }}
                          >
                            <FaFileCirclePlus className={"me-2"} />
                            New Document from Template
                          </Button>
                        )}
                      </div>
                    }
                  />
                </Card>
              </div>
            )}

            {doc.id && (
              <div data-bs-theme={"light"}>
                <DocumentEditor
                  doc={doc}
                  setDoc={setDoc}
                  tokenEndpoint={`/document/${doc.id}/${
                    isDraft ? "draft" : "published"
                  }/token`}
                  getRequest={getRequest}
                />
              </div>
            )}

            <AppModalLocal
              show={showAddCollaboratorModal}
              setShow={setShowAddCollaboratorModal}
              title={
                isDraft ? "Add Collaborator" : "Share Document with Others"
              }
              component={
                <div>
                  {!data.entitiesSelected && (
                    <div>
                      <OrgEntitySelection
                        formState={data}
                        setFormState={setData}
                        multiOrgEntities={true}
                      />
                      <Button
                        variant={"primary"}
                        className={"my-4 w-100"}
                        disabled={
                          !data.entity_ids || data.entity_ids.length === 0
                        }
                        onClick={() => {
                          setData({
                            ...data,
                            entitiesSelected: true,
                            role: doc.is_template
                              ? CollabDocShareRoles.READER
                              : "",
                          })
                        }}
                      >
                        Next: Role
                      </Button>
                    </div>
                  )}

                  {data.entitiesSelected && (
                    <div>
                      <GenericForm
                        formConfigs={
                          doc.is_draft
                            ? DocumentFormConfig.roles(doc.is_template)
                            : DocumentFormConfig.collabShareRoles(
                                doc.is_template,
                                doc.permissions
                              )
                        }
                        stateData={data}
                        setStateData={setData}
                        useGlobalState={false}
                        onSubmit={(data) => {
                          const submitData = { ...data }
                          delete submitData.validations

                          postRequest(
                            `/document/${
                              doc.is_draft ? "draft" : "published"
                            }/${doc.id}/collaborator`,
                            setGlobalLoading,
                            (response) => {
                              setShowAddCollaboratorModal(false)
                              setData({})
                              updateObjectState(setDoc, {
                                members_info: response.members_info,
                              })
                            },
                            submitData,
                            false,
                            false
                          )
                        }}
                      />
                      <p
                        className={"mt-1 pointer text-center"}
                        onClick={() => {
                          setData({
                            entitiesSelected: false,
                          })
                        }}
                      >
                        <FaChevronLeft className={"me-2"} /> Back
                      </p>
                    </div>
                  )}
                </div>
              }
            />

            <AppOffCanvasLocal
              showOffCanvas={showMembersOffCanvas}
              setShowOffCanvas={setShowMembersOffCanvas}
              backdropClose={true}
              canvasWidthPercentage={70}
              title={doc.is_draft ? "Collaborators" : "Recipients"}
              component={
                <div>
                  {hasPermission(DocumentPermissions.SHARE) && (
                    <AddItemButton
                      title={"Add"}
                      onClick={() => {
                        setData({})
                        setShowAddCollaboratorModal(true)
                      }}
                    />
                  )}

                  <Alert
                    className={"my-4"}
                    variant={"warning"}
                  >
                    When multiple roles are assigned to an entity through
                    employee selection or entity selection (e.g. department),
                    The most permissive role will be applied to the entity. E.g.
                    If an entity is assigned both "Writer" and "Reader" roles,
                    the "Writer" role will be applied.
                  </Alert>

                  <DocMembersTable
                    membersInfo={doc.members_info}
                    showPermissions={true}
                    showRole={true}
                    canManage={(member) => {
                      return [
                        doc.author_employee.id,
                        member.author_employee_id,
                      ].includes(employee.id)
                    }}
                    onDelete={(member) => {
                      showAppModal({
                        title: "Delete Document Member",
                        component: (
                          <p>
                            Are you sure you want to delete this document
                            member?
                          </p>
                        ),
                        truthyFunction: () => {
                          deleteRequest(
                            `/document${
                              doc.is_draft ? "/version" : ""
                            }/member/${member.id}`,
                            setGlobalLoading,
                            (response) => {
                              updateObjectState(setDoc, {
                                members_info: response.members_info,
                              })
                            }
                          )
                        },
                      })
                    }}
                    onEdit={(member) => {
                      updateContextState({
                        formData: {
                          role: member.role,
                        },
                      })

                      showAppModal({
                        title: "Edit Role",
                        hideFooter: true,
                        component: (
                          <div>
                            <GenericForm
                              formConfigs={
                                doc.is_draft
                                  ? [
                                      DocumentFormConfig.roles(
                                        doc.is_template
                                      )[0],
                                    ]
                                  : DocumentFormConfig.collabShareRoles(
                                      doc.is_template,
                                      doc.permissions
                                    ).slice(0, 2)
                              }
                              onSubmit={(data) => {
                                const submitData = { ...data }
                                delete submitData.validations

                                patchRequest(
                                  `/document/member/${member.id}/role`,
                                  setGlobalLoading,
                                  (response) => {
                                    updateObjectState(setDoc, {
                                      members_info: response.members_info,
                                    })
                                    closeModal()
                                  },
                                  submitData,
                                  false,
                                  false
                                )
                              }}
                            />
                          </div>
                        ),
                      })
                    }}
                  />
                </div>
              }
            />
          </div>
        </ProductPermissionCheckView>
      }
    />
  )
}

function CollaborativeDocPage() {
  return <DashboardPageContainer PageComponent={CollaborativeDoc} />
}

export default CollaborativeDocPage
