import React, { useEffect, useState } from "react"
import { Alert, Button, Card, Form } from "react-bootstrap"
import {
  isEmpty,
  isValidEmail,
  toTitleCase,
  updateObjectState,
  useAppModal,
} from "../utils/functions"
import { formDate, getUTCDateTime } from "../utils/dateTime"
import ImageCropInput from "./Forms/Shared/ImageCropInput"
import { FaInfo } from "react-icons/fa"
import OrgEntitySelection from "./Forms/Shared/OrgEntitySelection"
import { PDFDocument } from "pdf-lib"
import { parsePhoneNumberFromString } from "libphonenumber-js"
import FileUpload from "./Forms/Shared/FileUpload"

export const FormFieldTypes = {
  TEXT: "text",
  EMAIL: "email",
  PASSWORD: "password",
  NUMBER: "number",
  DATE: "date",
  TEL: "tel",
  URL: "url",
  SEARCH: "search",
  COLOR: "color",
  RANGE: "range",
  HIDDEN: "hidden",
  MONTH: "month",
  WEEK: "week",
  TIME: "time",
  DATETIME_LOCAL: "datetime-local",
  IMAGE: "image",
  TEXT_AREA: "textarea",
  STRING_SELECT: "stringSelect",
  OBJECT_SELECT: "objectSelect",
  STRING_CHECKBOX: "stringCheckbox",
  OBJECT_CHECKBOX: "objectCheckbox",
  STRING_RADIO: "stringRadio",
  OBJECT_RADIO: "objectRadio",
  MULTI_STRING_SELECT: "multiStringSelect",
  MULTI_OBJECT_SELECT: "multiObjectSelect",
  SWITCH: "switch",
  PDF: "pdf",
  ENTITY_SELECTION: "entitySelection",
  PDF_FILE: "PDF File",
  FILE: "file",
}

const FormLabel = ({ labelName, valueKey, info, isRequired, hideLabel }) => {
  if (hideLabel) return null
  return (
    <>
      <Form.Label>
        {labelName || toTitleCase(valueKey)}
        <span className={isRequired ? "text-danger" : "text-muted"}>
          {isRequired ? "* (required)" : " (optional)"}
        </span>
      </Form.Label>
      {info && (
        <Form.Text
          as={"p"}
          className="text-muted"
        >
          <small>{info}</small>
        </Form.Text>
      )}
    </>
  )
}

const STR_SELECT_TYPES = [
  FormFieldTypes.STRING_SELECT,
  FormFieldTypes.STRING_CHECKBOX,
  FormFieldTypes.STRING_RADIO,
]

const OBJ_SELECT_TYPES = [
  FormFieldTypes.OBJECT_SELECT,
  FormFieldTypes.OBJECT_CHECKBOX,
  FormFieldTypes.OBJECT_RADIO,
]

const PDF_FILE_TYPES = [FormFieldTypes.PDF, FormFieldTypes.PDF_FILE]

export const FormFields = ({
  type = "text",
  labelName,
  state,
  setState,
  valueKey,
  info,
  validation,
  errorMsg,
  optionsList,
  optionsLabelKey = "name",
  optionsValueKey = "id",
  isRequired = true,
  hideLabel = false,
  imageRounded = false,
  imageWidth = 100,
  maxFileMb = 0.3,
  extraComponent,
  alertMsg,
  disabled = false,
  minValue = null,
  maxValue = null,
  includeList = null,
  entitySelectionProps = {},
  allowedFileExt = ".pdf,.docx,.xlsx,.pptx,.doc,.xls,.ppt",
  stickyValues = [],
}) => {
  const { showAppModal } = useAppModal()

  // first 17 values from from FormFieldTypes
  const BASIC_TYPES = Object.values(FormFieldTypes).slice(0, 15)

  const [isValid, setIsValid] = useState(null)

  if (minValue && maxValue && !isNaN(minValue) && !isNaN(maxValue)) {
    if (type === FormFieldTypes.NUMBER) {
      errorMsg = `Please enter a valid number between ${minValue} and ${maxValue}`
    } else if (PDF_FILE_TYPES.includes(type)) {
      errorMsg = `Please upload a PDF file with page count between ${parseInt(
        minValue
      )} and ${parseInt(maxValue)}`
    }
  }

  if (type === FormFieldTypes.SWITCH) {
    isRequired = false
  }

  useEffect(() => {
    if (isRequired || !isEmpty(state[valueKey])) {
      if (validation) {
        setIsValid(validation(state[valueKey]))
      } else if (type === FormFieldTypes.DATE) {
        const date = getUTCDateTime(state[valueKey])
        setIsValid(state[valueKey] ? date.isValid() : false)
      } else if (type === FormFieldTypes.EMAIL) {
        setIsValid(isValidEmail(state[valueKey]))
      } else if (type === FormFieldTypes.TEL) {
        const phone = parsePhoneNumberFromString(state[valueKey] || "")
        setIsValid(phone ? phone.isValid() : false)
      } else if (STR_SELECT_TYPES.includes(type)) {
        setIsValid(optionsList.includes(state[valueKey]))
      } else if (OBJ_SELECT_TYPES.includes(type)) {
        const isValid = optionsList.some(
          (option) => option[optionsValueKey] === state[valueKey]
        )
        setIsValid(isValid)
      } else if (type === FormFieldTypes.NUMBER) {
        const num = state[valueKey] ? parseFloat(state[valueKey]) : null
        if (!num) {
          setIsValid(false)
        } else {
          const valid =
            !isEmpty(num) &&
            !Number.isNaN(num) &&
            (minValue
              ? parseFloat(state[valueKey]) >= parseFloat(minValue)
              : true) &&
            (maxValue
              ? parseFloat(state[valueKey]) <= parseFloat(maxValue)
              : true)
          setIsValid(valid)
        }
      } else if (type === FormFieldTypes.SWITCH) {
        setIsValid(true)
      } else {
        setIsValid(!isEmpty(state[valueKey]))
      }
    } else {
      setIsValid(true)
    }
  }, [state[valueKey], optionsList])

  useEffect(() => {
    setState((prevState) => {
      const prevValidations = prevState.validations || {}
      const newValidations = {
        ...prevValidations,
        [valueKey]: isValid,
      }

      return {
        ...prevState,
        validations: newValidations,
      }
    })
  }, [isValid])

  useEffect(() => {
    if (type === FormFieldTypes.MULTI_STRING_SELECT) {
      if (!Array.isArray(state[valueKey])) {
        setState({ ...state, [valueKey]: stickyValues })
      }
    }
  }, [])

  if (type === FormFieldTypes.SWITCH) {
    return (
      <Card
        body={true}
        className="mb-4"
      >
        <Form.Group
          controlId={valueKey}
          className={"d-flex justify-content-between align-items-center"}
        >
          <FormLabel
            labelName={labelName}
            valueKey={valueKey}
            isRequired={isRequired}
            hideLabel={hideLabel}
          />
          <Form.Switch
            name={valueKey}
            value={state[valueKey] || false}
            checked={state[valueKey] === true}
            disabled={disabled}
            readOnly={disabled}
            onChange={(e) =>
              setState({ ...state, [valueKey]: e.target.checked })
            }
          />
        </Form.Group>
        {info && <small className="text-muted">{info}</small>}
      </Card>
    )
  }

  return (
    <Form.Group
      controlId={valueKey}
      className={"mb-4"}
    >
      <FormLabel
        labelName={labelName}
        valueKey={valueKey}
        info={info}
        isRequired={isRequired}
        hideLabel={hideLabel}
      />

      {alertMsg && (
        <Alert
          variant={"warning"}
          className={"d-flex"}
        >
          <FaInfo className={"me-2"} />
          <small>{alertMsg}</small>
        </Alert>
      )}

      {BASIC_TYPES.includes(type) && (
        <Form.Control
          type={type}
          name={valueKey}
          required={isRequired}
          value={state[valueKey] || ""}
          disabled={disabled}
          readOnly={disabled}
          autoComplete={type}
          onChange={(e) => {
            setState({ ...state, [valueKey]: e.target.value })
          }}
        />
      )}

      {type === FormFieldTypes.TEXT_AREA && (
        <div>
          <Form.Control
            as="textarea"
            name={valueKey}
            rows={5}
            required={isRequired}
            value={state[valueKey] || ""}
            disabled={disabled}
            readOnly={disabled}
            onChange={(e) => setState({ ...state, [valueKey]: e.target.value })}
          />
          {disabled && state[valueKey] && (
            <div className={"mt-2 text-center"}>
              <Button
                variant={"link"}
                onClick={() => {
                  showAppModal({
                    title: "",
                    size: "xl",
                    hideFooter: true,
                    component: <p>{state[valueKey]}</p>,
                  })
                }}
              >
                View / Expand
              </Button>
            </div>
          )}
        </div>
      )}

      {type === FormFieldTypes.STRING_SELECT && (
        <Form.Select
          name={valueKey}
          required={isRequired}
          value={state[valueKey] || ""}
          disabled={disabled}
          readOnly={disabled}
          onChange={(e) => setState({ ...state, [valueKey]: e.target.value })}
        >
          <option value="">Select an option</option>
          {optionsList.map((option) => {
            return (
              <option
                key={option}
                value={option}
              >
                {option}
              </option>
            )
          })}
        </Form.Select>
      )}

      {type === FormFieldTypes.OBJECT_SELECT && (
        <Form.Select
          name={valueKey}
          required={isRequired}
          value={state[valueKey] || ""}
          disabled={disabled}
          readOnly={disabled}
          onChange={(e) => setState({ ...state, [valueKey]: e.target.value })}
        >
          <option value="">Select an option</option>
          {optionsList.map((option, index) => (
            <option
              key={index}
              value={option[optionsValueKey]}
            >
              {option[optionsLabelKey]}
            </option>
          ))}
        </Form.Select>
      )}

      {type === FormFieldTypes.STRING_CHECKBOX && (
        <div>
          {optionsList.map((option, index) => {
            return (
              <Form.Check
                key={index}
                type="checkbox"
                label={option}
                name={valueKey}
                value={option}
                disabled={disabled}
                checked={state[valueKey] === option}
                readOnly={disabled}
                onChange={(e) => {
                  const value = e.target.value
                  const checked = e.target.checked
                  if (checked) {
                    setState({
                      ...state,
                      [valueKey]: value,
                    })
                  } else {
                    setState({
                      ...state,
                      [valueKey]: null,
                    })
                  }
                }}
              />
            )
          })}
        </div>
      )}

      {type === FormFieldTypes.OBJECT_CHECKBOX && (
        <div>
          {optionsList.map((option, index) => {
            return (
              <Form.Check
                key={index}
                type="checkbox"
                label={option[optionsLabelKey]}
                name={valueKey}
                value={option[optionsValueKey]}
                disabled={disabled}
                readOnly={disabled}
                onChange={(e) => {
                  const value = e.target.value
                  const checked = e.target.checked
                  if (checked) {
                    setState({
                      ...state,
                      [valueKey]: [...state[valueKey], value],
                    })
                  } else {
                    const updated = state[valueKey].filter(
                      (item) => item !== value
                    )
                    setState({ ...state, [valueKey]: updated })
                  }
                }}
              />
            )
          })}
        </div>
      )}

      {type === FormFieldTypes.STRING_RADIO && (
        <div>
          {optionsList.map((option, index) => {
            return (
              <Form.Check
                key={index}
                type="radio"
                label={option}
                name={valueKey}
                value={option}
                disabled={disabled}
                readOnly={disabled}
                checked={state[valueKey] === option}
                onChange={(e) =>
                  setState({ ...state, [valueKey]: e.target.value })
                }
              />
            )
          })}
        </div>
      )}

      {type === FormFieldTypes.OBJECT_RADIO && (
        <div>
          {optionsList.map((option, index) => {
            return (
              <Form.Check
                key={index}
                type="radio"
                label={option[optionsLabelKey]}
                name={valueKey}
                value={option[optionsValueKey]}
                checked={state[valueKey] === option[optionsValueKey]}
                disabled={disabled}
                readOnly={disabled}
                onChange={(e) =>
                  setState({ ...state, [valueKey]: e.target.value })
                }
              />
            )
          })}
        </div>
      )}

      {type === FormFieldTypes.IMAGE && (
        <ImageCropInput
          imgSrc={state[`${valueKey}_url`] || state[valueKey]}
          maxMb={maxFileMb}
          disabled={disabled}
          readOnly={disabled}
          onComplete={async (imageFile, imageUrl) => {
            setState((prevState) => ({
              ...prevState,
              [`${valueKey}_url`]: imageUrl,
              [valueKey]: imageFile,
            }))
          }}
          rounded={imageRounded}
          imageWidth={imageWidth}
        />
      )}

      {type === FormFieldTypes.MULTI_STRING_SELECT && (
        <Form.Group className="mb-3">
          {/*Add Select All option*/}
          <Form.Check
            type="checkbox"
            label="SELECT ALL"
            className={"fw-bold mb-2 small"}
            value="Select All"
            checked={
              state[valueKey] && state[valueKey].length === optionsList.length
            }
            disabled={disabled}
            onChange={(e) => {
              const { checked } = e.target
              const updatedValues = checked ? optionsList : stickyValues
              setState({ ...state, [valueKey]: updatedValues })
            }}
          />

          {optionsList.map((option, index) => (
            <Form.Check
              key={index}
              type="checkbox"
              label={option}
              value={option}
              checked={state[valueKey] && state[valueKey].includes(option)}
              disabled={disabled || stickyValues.includes(option)}
              required={
                isRequired && state[valueKey] && state[valueKey].length === 0
              }
              onChange={(e) => {
                const { value, checked } = e.target

                const stateVale = state[valueKey] || []
                const updatedValues = checked
                  ? [...stateVale, value]
                  : stateVale.filter((item) => item !== value)

                setState({ ...state, [valueKey]: updatedValues })
              }}
            />
          ))}
        </Form.Group>
      )}

      {type === FormFieldTypes.FILE && (
        <FileUpload
          valueKey={valueKey}
          maxFileMb={maxFileMb}
          minValue={minValue}
          maxValue={maxValue}
          disabled={disabled}
          setState={setState}
          allowedFileExt={allowedFileExt}
        />
      )}

      {PDF_FILE_TYPES.includes(type) && (
        <Form.Control
          type="file"
          name={valueKey}
          accept=".pdf"
          placeholder={"Upload a PDF file"}
          disabled={disabled}
          readOnly={disabled}
          onChange={(event) => {
            const file = event.target.files[0]

            // Check if file exists and is a valid PDF file under the max size
            if (
              file &&
              file.type === "application/pdf" &&
              file.size <= maxFileMb * 1000000
            ) {
              const reader = new FileReader()
              reader.readAsArrayBuffer(file) // Use ArrayBuffer to handle PDF data

              reader.onloadend = async () => {
                try {
                  const pdfData = new Uint8Array(reader.result)
                  const pdfDoc = await PDFDocument.load(pdfData)
                  const numPages = pdfDoc.getPageCount()

                  // Check if the number of pages falls within the allowed range
                  if (
                    (!minValue || numPages >= minValue) &&
                    (!maxValue || numPages <= maxValue)
                  ) {
                    setState((prevState) => ({
                      ...prevState,
                      [valueKey]: file,
                      [`${valueKey}_url`]: reader.result,
                    }))
                  } else {
                    // Show an error if page count is outside allowed limits
                    showAppModal({
                      title: "Invalid PDF",
                      hideFooter: true,
                      component: (
                        <p>
                          The uploaded PDF has {numPages} pages. It must have
                          between {parseInt(minValue)} and {parseInt(maxValue)}{" "}
                          pages.
                        </p>
                      ),
                    })
                  }
                } catch (error) {
                  showAppModal({
                    title: "Error",
                    hideFooter: true,
                    component: <p>There was an error reading the PDF file.</p>,
                  })
                }
              }
            } else {
              // Show an error modal if the file type or size is invalid
              showAppModal({
                title: "Invalid File",
                hideFooter: true,
                component: (
                  <p>
                    Invalid file type or size. Please select a PDF file smaller
                    than or equal to {maxFileMb} MB.
                  </p>
                ),
              })
            }
          }}
        />
      )}

      {type === FormFieldTypes.ENTITY_SELECTION && (
        <OrgEntitySelection
          formState={state}
          setFormState={setState}
          includeList={includeList}
          {...entitySelectionProps}
        />
      )}

      {!isValid && (
        <Alert
          variant={"danger"}
          className={"mt-2"}
        >
          {errorMsg ||
            `Please ${optionsList ? "select" : "enter"} a valid "${
              labelName || toTitleCase(valueKey)
            }"`}
        </Alert>
      )}

      {extraComponent && <div className={"mt-3"}>{extraComponent}</div>}
    </Form.Group>
  )
}
