import React, { useState, useEffect, useContext, useRef } from "react"
import {
  Card,
  Button,
  Form,
  ListGroup,
  Spinner,
  Badge,
  OverlayTrigger,
} from "react-bootstrap"
import axios from "axios"
import { AppContext } from "../../../utils/components/AppContext"
import { FaChevronUp, FaCommentSlash } from "react-icons/fa"
import AutoGrowTextarea from "./AutoGrowTextarea"
import {
  dateFormats,
  formatDate,
  formatDateInDayJs,
} from "../../../utils/dateTime"
import EmployeeHighlightCard from "../Employee/EmployeeHighlightCard"
import { EmployeePopover } from "../EmployeeCards"

export default function ChatComponent({
  endpoint,
  getRequest,
  putRequest,
  postRequest,
  canSendMessage,
  externalMessagesState,
  setExternalMessagesState,
  externalPagingInfo,
  setExternalPagingInfo,
}) {
  const { contextState } = useContext(AppContext)
  const currentEmployee = contextState?.employee

  // Store the list of messages plus pagination info
  const [messages, setMessages] = useState(externalMessagesState || [])
  const [pagination, setPagination] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [newMessage, setNewMessage] = useState("")
  const [isSending, setIsSending] = useState(false)
  const [lastMessage, setLastMessage] = useState(null)

  const scrollRef = useRef()

  // Fetch initial messages
  useEffect(() => {
    if (messages.length > 0) return
    fetchMessages(1)
  }, [])

  // If externalMessagesState changes, sync into our state
  useEffect(() => {
    if (externalMessagesState) {
      setMessages(externalMessagesState)
    }
  }, [externalMessagesState])

  useEffect(() => {
    if (externalPagingInfo) {
      setPagination(externalPagingInfo)
    }
  }, [externalPagingInfo])

  async function fetchMessages(pageNumber) {
    setIsLoading(true)
    getRequest(`${endpoint}/${pageNumber}`, setIsLoading, (response) => {
      let fetchedMsgs = response.messages
      if (pageNumber > 1) {
        fetchedMsgs = [...fetchedMsgs, ...messages]
      }

      if (setExternalMessagesState) {
        setExternalMessagesState(fetchedMsgs)
      } else {
        setMessages(fetchedMsgs)
      }

      if (setExternalPagingInfo) {
        setExternalPagingInfo(response.paging_info)
      } else {
        setPagination(response.paging_info)
      }
    })
  }

  async function fetchLatestMessages() {
    setIsLoading(true)
    postRequest(
      `${endpoint}/latest`,
      null,
      (response) => {
        let fetchedMsgs = response.messages
        if (fetchedMsgs.length === 0) {
          return
        }
        fetchedMsgs = [...messages, ...fetchedMsgs]

        if (setExternalMessagesState) {
          setExternalMessagesState(fetchedMsgs)
        } else {
          setMessages(fetchedMsgs)
        }
      },
      {
        last_message_time: lastMessage.timestamp,
      },
      false,
      false,
      false
    )
  }

  useEffect(() => {
    let intervalId

    if (lastMessage) {
      fetchLatestMessages()
      intervalId = setInterval(fetchLatestMessages, 3000)
    }

    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [lastMessage])

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      })
    }
  }

  useEffect(() => {
    const lastMsg = messages.length > 0 ? messages[messages.length - 1] : null
    if (lastMsg && lastMsg.id !== lastMessage?.id) {
      setLastMessage(lastMsg)
      scrollToBottom()
    }
  }, [messages])

  async function handleLoadPrevious() {
    if (pagination?.has_next) {
      await fetchMessages(pagination.next_page)
    }
  }

  async function handleSendMessage(e) {
    e.preventDefault()
    if (!newMessage.trim()) return
    if (!canSendMessage) return

    putRequest(
      endpoint,
      setIsSending,
      (resp) => {
        if (setExternalMessagesState) {
          setExternalMessagesState((prev) => [...prev, resp.chat_message])
        } else {
          setMessages((prev) => [...prev, resp.chat_message])
        }
        setNewMessage("")
      },
      { message: newMessage },
      false,
      false,
      false
    )
  }

  return (
    <Card
      className="border-0"
      style={{
        maxWidth: "600px",
        margin: "0 auto",
        // Use viewport height (vh) for a responsive dynamic height
        height: "80vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Card.Header className="d-flex justify-content-between align-items-center">
        <div>Messages</div>
      </Card.Header>

      {/* Optional: 'Load Previous' button if there's another page */}
      {pagination?.has_next && !isLoading && (
        <Button
          variant="link"
          size={"sm"}
          className="text-secondary mt-3"
          onClick={handleLoadPrevious}
        >
          Load Previous Messages <FaChevronUp />
        </Button>
      )}

      {/* Body: This will scroll if content is too tall */}
      <Card.Body
        className={`d-flex flex-column ${
          messages.length === 0 ? "justify-content-center" : ""
        }`}
        style={{ flex: 1, overflowY: "auto" }}
        ref={scrollRef}
      >
        {isLoading && (
          <div className={"text-center my-4"}>
            <Spinner
              animation="border"
              size="sm"
            />
          </div>
        )}
        {messages.length === 0 && !isLoading && (
          <div className="text-center text-muted align-middle">
            <FaCommentSlash
              className={"mb-3"}
              size={50}
            />
            <br />
            No messages yet
          </div>
        )}

        <div>
          {messages.map((msg) => {
            const isMe =
              currentEmployee && msg.employee.id === currentEmployee.id
            const msgEmp = msg.employee

            return (
              <Card
                key={msg.id}
                style={{
                  display: "flex",
                  maxWidth: "85%",
                }}
                className={`${isMe ? "ms-auto" : ""} mb-3`}
              >
                {!isMe && (
                  <Card.Header className="ps-1 d-flex align-items-center">
                    <img
                      src={msgEmp.profile_image}
                      alt={msgEmp.full_name}
                      width={30}
                      height={30}
                      className="rounded-circle"
                    />

                    <OverlayTrigger
                      trigger="click"
                      placement="top"
                      overlay={<EmployeePopover employee={msgEmp} />}
                      rootClose
                    >
                      <Button
                        variant="link"
                        className="my-auto"
                      >
                        {msgEmp.full_name}
                      </Button>
                    </OverlayTrigger>
                  </Card.Header>
                )}
                <Card.Body
                  className={`${
                    isMe ? "bg-primary" : "bg-secondary bg-opacity-75"
                  } text-white`}
                >
                  {msg.message}
                </Card.Body>

                <Card.Footer
                  className={"small text-end text-muted fst-italic"}
                  style={{ fontSize: "0.8rem" }}
                >
                  {formatDateInDayJs(
                    msg.timestamp,
                    dateFormats.daySingleDigitDMY12Hr
                  )}
                </Card.Footer>
              </Card>
            )
          })}
        </div>
      </Card.Body>

      {/* Footer: pinned at bottom for sending new message */}
      <Card.Footer className="bg-bodys px-0">
        {canSendMessage && (
          <>
            {!isSending && (
              <Form
                onSubmit={handleSendMessage}
                className="d-flex"
              >
                <AutoGrowTextarea
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Type a message..."
                  minRows={1}
                  maxRows={5}
                />
                <Button
                  type="submit"
                  variant="primary"
                  className={"ms-1"}
                  disabled={!canSendMessage}
                >
                  Send
                </Button>
              </Form>
            )}

            {isSending && (
              <div className={"text-center my-4"}>
                <Spinner
                  animation="border"
                  size="sm"
                  className={""}
                  variant={"primary"}
                />

                <p className={"text-muted mt-2 fst-italic"}>
                  Sending message...
                </p>
              </div>
            )}
          </>
        )}

        {!canSendMessage && (
          <div className="text-center text-muted small my-4 fst-italic">
            You have "View Only" permission
          </div>
        )}
      </Card.Footer>
    </Card>
  )
}
