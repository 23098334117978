import React from "react"
import { NoInfoCard, StatusBadge } from "../../../utils/components"
import { Button, OverlayTrigger, Table } from "react-bootstrap"
import { EmployeePopover } from "../EmployeeCards"
import { dateFormats, formatDateInDayJs } from "../../../utils/dateTime"
import { useAppModal, useOffCanvas } from "../../../utils/functions"
import ApprovalsTable from "../Employee/ApprovalsTable"
import FormSubmissionResponsesView from "./FormSubmissionResponsesView"

function FormSubmissionsTable({
  submissions,
  setSubmissions,
  fields,
  getRequest,
  postRequest,
  forApproval,
}) {
  const { showAppModal } = useAppModal()
  const { showOffCanvas } = useOffCanvas()

  if (submissions.length === 0) {
    return <NoInfoCard text={"There are no submissions to display"} />
  } else {
    return (
      <div>
        <Table
          striped
          bordered
          className={"text-center"}
          responsive={true}
        >
          <thead>
            <tr>
              <th
                style={{
                  minWidth: "175px",
                }}
              >
                Submitted By
              </th>
              <th
                style={{
                  minWidth: "175px",
                }}
              >
                Submitted On
              </th>
              <th>Status</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {submissions.map((submission) => {
              return (
                <tr key={submission.id}>
                  <td>
                    <OverlayTrigger
                      trigger="click"
                      placement="top"
                      overlay={
                        <EmployeePopover
                          employee={submission.author_employee}
                        />
                      }
                      rootClose
                    >
                      <Button
                        variant="link"
                        className=""
                      >
                        {submission.author_employee.full_name}
                      </Button>
                    </OverlayTrigger>
                  </td>
                  <td>
                    {formatDateInDayJs(
                      submission.timestamp,
                      dateFormats.shortDayShortMonthFullDayYearTime12Hr
                    )}
                  </td>
                  <td>
                    <StatusBadge
                      status={
                        submission.can_approve
                          ? "Awaiting your Action"
                          : submission.approval_status
                      }
                    />
                  </td>
                  <td>
                    <Button
                      variant="link"
                      onClick={() => {
                        showOffCanvas({
                          title: `Submission Details`,
                          subtitle: `Details of the submission by ${submission.author_employee.full_name}`,
                          widthPercentage: 40,
                          backdropClose: true,
                          component: (
                            <div>
                              <FormSubmissionResponsesView
                                fields={fields}
                                submission={submission}
                                getRequest={getRequest}
                                postRequest={postRequest}
                                forApproval={forApproval}
                                onActionComplete={(updatedSub) => {
                                  setSubmissions(
                                    submissions.map((sub) => {
                                      if (sub.id === submission.id) {
                                        return {
                                          ...updatedSub,
                                          can_approve: false,
                                        }
                                      } else {
                                        return sub
                                      }
                                    })
                                  )
                                }}
                              />
                            </div>
                          ),
                        })
                      }}
                    >
                      Responses{submission.can_approve ? " & Your Action" : ""}
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="link"
                      className=""
                      onClick={() => {
                        showAppModal({
                          title: `Submission Approvals`,
                          hideFooter: true,
                          size: "xl",
                          component: (
                            <ApprovalsTable
                              approvalSteps={submission.approval_steps}
                            />
                          ),
                        })
                      }}
                    >
                      Approvals
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

export default FormSubmissionsTable
