import React, { useEffect, useState } from "react"
import {
  getFileArrayBufferFromUrl,
  getFileFromUrl,
  sleep,
  updateObject,
  updateObjectState,
  useToast,
} from "../../../utils/functions"
import { LoadingView, NoInfoCard } from "../../../utils/components"
import { DocumentFormats } from "../../../enums/DocumentsEnums"
import DocPDFViewer from "./DocPDFViewer"
import { Card } from "react-bootstrap"
import { DocIcon } from "./DocumentsUtils"
import DocToolbar from "./DocToolbar"
import { AppRoutes } from "../../../utils/appRoutes"
import { useNavigate } from "react-router-dom"

function DocumentViewer({
  doc,
  setDoc,
  deleteRequest,
  putRequest,
  postRequest,
  getRequest,
  patchRequest,
  onChatClick,
  onActivitiesClick,
  onShareClick,
  onNewVersionClick,
  onOtherVersionsClick,
  onLockUnlockClick,
  onMembersClick,
  showToolbar = true,
  canPrint,
  canDownload,
  showActivities,
  showViews,
  toolbarProps = {},
}) {
  const { showToast } = useToast()

  const [isLoading, setIsLoading] = useState(false)
  const [fileBlobUrl, setFileBlobUrl] = useState(null)
  const navigation = useNavigate()
  const isPdf = doc.format === DocumentFormats.PDF

  const getFileBlobUrl = async () => {
    if (!isPdf) {
      await sleep(3) // wait 5 seconds because the file has just been made public
    }
    const blobUrl = await getFileFromUrl(doc.file_blob_url)
    if (blobUrl) {
      setFileBlobUrl(blobUrl)
      updateObjectState(setDoc, {
        local_file_url: blobUrl,
      })
    } else {
      showToast({
        title: "Error Getting PDF File",
        message:
          "An error occurred while trying to get the file. Please try again or contact your administrator if this error persists.",
      })
    }
  }

  useEffect(() => {
    getFileBlobUrl()
  }, [])

  return (
    <LoadingView
      isLoading={isLoading}
      text={"Getting Document..."}
      view={
        <div>
          {doc.local_file_url && (
            <div>
              <Card>
                <Card.Header className={"py-3 align-middle"}>
                  <div className={"d-flex flex-row"}>
                    <DocIcon
                      extension={doc.extension}
                      size={32}
                    />
                    <div className={"ms-3"}>
                      <Card.Title
                        as={"p"}
                        className={"mb-1"}
                      >
                        {`${doc.name}${
                          doc.version_number
                            ? ` - Version ${doc.version_number}`
                            : ""
                        }`}
                      </Card.Title>
                      <small>{doc.description}</small>
                    </div>
                  </div>
                </Card.Header>
                {showToolbar && (
                  <DocToolbar
                    doc={doc}
                    setDoc={setDoc}
                    {...toolbarProps}
                  />
                )}
              </Card>

              {isPdf && (
                <div>
                  <DocPDFViewer
                    doc={doc}
                    setDoc={setDoc}
                  />
                </div>
              )}
              {!isPdf && (
                <div>
                  {/*<iframe*/}
                  {/*  src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(*/}
                  {/*    doc.file_blob_url*/}
                  {/*  )}`}*/}
                  {/*  width="100%"*/}
                  {/*  height={window.innerHeight}*/}
                  {/*/>*/}

                  <iframe
                    src={`https://docs.google.com/gview?url=${encodeURIComponent(
                      doc.file_blob_url
                    )}&embedded=true`}
                    width="100%"
                    height={window.innerHeight * 0.9}
                    sandbox="allow-scripts allow-same-origin"
                  />
                </div>
              )}
            </div>
          )}

          {!doc.local_file_url && <NoInfoCard text={"Loading Document..."} />}
        </div>
      }
    />
  )
}

export default DocumentViewer
