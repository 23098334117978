import React, { useContext, useEffect, useState } from "react"
import { AppContext } from "../../../utils/components/AppContext"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import DashboardPageContainer from "../../components/DashboardPageContainer"
import {
  AddItemButton,
  AppModalLocal,
  AppOffCanvasLocal,
  LoadingView,
} from "../../../utils/components"
import PageMetaTags from "../../components/Shared/PageMetaTags"
import {
  toTitleCase,
  updateObject,
  updateObjectState,
  useAppModal,
  useOffCanvas,
} from "../../../utils/functions"
import { Button, Tab, Tabs } from "react-bootstrap"
import DocumentViewer from "./DocumentViewer"
import AppBreadCrumb from "../Shared/AppBreadCrumb"
import { AppRoutes } from "../../../utils/appRoutes"
import PaginatedEmployeesDisplay from "../Employee/PaginatedEmployeesDisplay"
import {
  formatDate,
  formatDateInDayJs,
  formatDateTime,
} from "../../../utils/dateTime"
import DocViewEditComponent from "./DocViewEditComponent"
import OrgEntitySelection from "../../Forms/Shared/OrgEntitySelection"
import AppOffCanvas from "../../../utils/components/AppOffCanvas"
import GenericForm from "../../Forms/Shared/GenericForm"
import DocumentFormConfig from "../../Forms/FormsConfigs/DocumentFormConfig"
import { FaChevronLeft } from "react-icons/fa"
import DocMembersTable from "./DocMembersTable"
import { DocumentPermissions } from "../../../enums/DocumentsEnums"
import { Products } from "../../../enums/OrgEnums"
import ProductPermissionCheckView from "../ProductPermissionCheckView"

function DocumentDetails({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
}) {
  const { contextState, updateContextState, setGlobalLoading } =
    useContext(AppContext)
  const { permissions, employee } = contextState
  const { showAppModal, closeModal } = useAppModal()

  const navigate = useNavigate()

  const { documentId } = useParams()

  const [searchParams, setSearchParams] = useSearchParams()
  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value })
  }
  const { showOffCanvas } = useOffCanvas()

  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(searchParams.get("activeTab"))
  const [showSecondLoader, setShowSecondLoader] = useState(false)
  const [doc, setDoc] = useState({ permissions: [] })
  const [employeesActivities, setEmployeesActivities] = useState({})
  const [showActivitiesModal, setShowActivitiesModal] = useState(false)
  const [showChatModal, setShowChatModal] = useState(false)
  const [showMembersOffCanvas, setShowMembersOffCanvas] = useState(false)

  const [showShareAppOffCanvas, setShowShareAppOffCanvas] = useState(false)
  const [data, setData] = useState({})

  const activateTab = (key) => {
    key = key || "view"
    setActiveTab(key)
    updateQueryParam("activeTab", key)
  }

  useEffect(() => {
    activateTab(searchParams.get("activeTab"))
  }, [searchParams])

  const isDraft = window.location.pathname.includes("draft")

  useEffect(() => {
    let endpoint = `/document/${documentId}`
    if (isDraft) {
      endpoint += "/draft"
    } else {
      endpoint += "/published"
    }

    getRequest(endpoint, setIsLoading, (response) => {
      setDoc(response.document)
    })
  }, [documentId])

  const getDocProps = () => {
    return {
      getRequest: getRequest,
      postRequest: postRequest,
      putRequest: putRequest,
      patchRequest: patchRequest,
      showActivities:
        !isDraft && doc.permissions.includes(DocumentPermissions.VIEW),
      showViews: !isDraft && doc.permissions.includes(DocumentPermissions.VIEW),
      canDownload:
        !doc.is_version &&
        doc.permissions.includes(DocumentPermissions.DOWNLOAD),
      canChat: doc.permissions.includes(DocumentPermissions.CHAT),
      showChats: !doc.is_template,
      onDelete: (docId) => {
        deleteRequest(
          `/document/version/${docId}`,
          setIsLoading,
          (response) => {
            navigate(AppRoutes.Documents.path)
          }
        )
      },
      onPublish: (docId) => {
        patchRequest(
          `/document/version/${docId}/publish`,
          setIsLoading,
          (response) => {
            navigate(
              `${AppRoutes.DocumentDetais.paramLessPath}/${response.doc_id}`
            )
          },
          {},
          false,
          false
        )
      },
      onNewVersionClick: !doc.is_draft ? null : () => {},
      onMembersClick: () => {
        setShowMembersOffCanvas(true)
      },
    }
  }

  useEffect(() => {
    if (data.permissions && !data.permissions.includes("View")) {
      setTimeout(() => {
        updateObjectState(setData, {
          permissions: ["View", ...data.permissions],
        })
      }, 300)
    }
  }, [data.permissions])

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <ProductPermissionCheckView product={Products.DOCUMENTS_MANAGEMENT}>
          <div>
            <PageMetaTags title={`Document View | ${toTitleCase(activeTab)}`} />

            {doc.id && (
              <AppBreadCrumb
                items={[
                  ...doc.folder_path,
                  { text: doc.name || "Document View" },
                ]}
              />
            )}

            {doc.id && (
              <div>
                <DocumentViewer
                  doc={doc}
                  setDoc={setDoc}
                  deleteRequest={deleteRequest}
                  putRequest={putRequest}
                  getRequest={getRequest}
                  postRequest={postRequest}
                  patchRequest={patchRequest}
                  toolbarProps={getDocProps()}
                />
                {/*<DocViewEditComponent doc={doc} />*/}
              </div>
            )}

            <AppOffCanvasLocal
              showOffCanvas={showShareAppOffCanvas}
              setShowOffCanvas={setShowShareAppOffCanvas}
              title={"Share Document"}
              subtitle={"Share this document with others"}
              component={
                <div>
                  {!data.entitiesSelected && (
                    <div>
                      <OrgEntitySelection
                        formState={data}
                        setFormState={setData}
                        multiOrgEntities={true}
                      />
                      <Button
                        variant={"primary"}
                        className={"my-4 w-100"}
                        disabled={
                          !data.entity_ids || data.entity_ids.length === 0
                        }
                        onClick={() => {
                          setData({
                            ...data,
                            entitiesSelected: true,
                          })
                        }}
                      >
                        Next: Permissions
                      </Button>
                    </div>
                  )}

                  {data.entitiesSelected && (
                    <div>
                      <GenericForm
                        formConfigs={DocumentFormConfig.permissions(
                          doc.permissions
                        )}
                        stateData={data}
                        setStateData={setData}
                        useGlobalState={false}
                        onSubmit={(data) => {
                          const submitData = { ...data }
                          delete submitData.validations

                          postRequest(
                            `/document/${doc.fe_type}/${doc.id}/share`,
                            setGlobalLoading,
                            (response) => {
                              setShowShareAppOffCanvas(false)
                              setData({})
                              updateObjectState(setDoc, {
                                members_info: response.members_info,
                              })
                            },
                            submitData,
                            false,
                            false
                          )
                        }}
                      />
                      <p
                        className={"mt-1 pointer text-center"}
                        onClick={() => {
                          setData({
                            permissions: ["View"],
                            entitiesSelected: false,
                          })
                        }}
                      >
                        <FaChevronLeft className={"me-2"} /> Back
                      </p>
                    </div>
                  )}
                </div>
              }
            />

            <AppOffCanvasLocal
              showOffCanvas={showMembersOffCanvas}
              setShowOffCanvas={setShowMembersOffCanvas}
              backdropClose={true}
              canvasWidthPercentage={70}
              title={"Document Members"}
              component={
                <div>
                  {doc.permissions.includes(DocumentPermissions.SHARE) && (
                    <div className={"mb-3"}>
                      <AddItemButton
                        title={"Add"}
                        onClick={() => {
                          setData({})
                          setShowShareAppOffCanvas(true)
                        }}
                      />
                    </div>
                  )}
                  <DocMembersTable
                    membersInfo={doc.members_info}
                    canManage={(member) => {
                      return [
                        doc.author_employee.id,
                        member.author_employee_id,
                      ].includes(employee.id)
                    }}
                    onDelete={(member) => {
                      showAppModal({
                        title: "Delete Document Member",
                        component: (
                          <p>
                            Are you sure you want to delete this document
                            member?
                          </p>
                        ),
                        truthyFunction: () => {
                          deleteRequest(
                            `/document/member/${member.id}`,
                            setGlobalLoading,
                            (response) => {
                              updateObjectState(setDoc, {
                                members_info: response.members_info,
                              })
                            }
                          )
                        },
                      })
                    }}
                    onEdit={(member) => {
                      updateContextState({
                        formData: {
                          permissions: member.permissions,
                        },
                      })

                      showAppModal({
                        title: "Edit Document Member Permissions",
                        hideFooter: true,
                        component: (
                          <div>
                            <GenericForm
                              formConfigs={[
                                DocumentFormConfig.permissions(
                                  doc.permissions
                                )[0],
                              ]}
                              onSubmit={(data) => {
                                const submitData = { ...data }
                                delete submitData.validations

                                if (!submitData.permissions.includes("View")) {
                                  submitData.permissions.push("View")
                                }

                                patchRequest(
                                  `/document/member/${member.id}/permissions`,
                                  setGlobalLoading,
                                  (response) => {
                                    updateObjectState(setDoc, {
                                      members_info: response.members_info,
                                    })
                                    closeModal()
                                  },
                                  submitData,
                                  false,
                                  false
                                )
                              }}
                            />
                          </div>
                        ),
                      })
                    }}
                  />
                </div>
              }
            />
          </div>
        </ProductPermissionCheckView>
      }
    />
  )
}

function DocumentDetailsPage() {
  return <DashboardPageContainer PageComponent={DocumentDetails} />
}

export default DocumentDetailsPage
