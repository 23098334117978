import React, { useContext } from "react"
import { Breadcrumb } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { AppContext } from "../../../utils/components/AppContext"

function AppBreadCrumb({ items }) {
  /*
  items: array of objects with the following
  [
    {
      text: string,
      href: string,
      path: string,
      onClick: function
      disabled: boolean
    }
   */
  const { contextState } = useContext(AppContext)

  const navigate = useNavigate()

  if (contextState.isMobileApp) {
    return null
  } else {
    return (
      <Breadcrumb>
        {items.map((item, index) => {
          const isLast = index === items.length - 1 && items.length > 1
          return (
            <Breadcrumb.Item
              key={index}
              href={item.href || "#"}
              active={isLast || item.disabled}
              onClick={
                isLast || item.disabled
                  ? undefined
                  : (e) => {
                      e.preventDefault()
                      if (item.onClick) {
                        item.onClick()
                      } else {
                        navigate(item.path)
                      }
                    }
              }
            >
              {item.text}
            </Breadcrumb.Item>
          )
        })}
      </Breadcrumb>
    )
  }
}

export default AppBreadCrumb
