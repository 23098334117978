import React, { useEffect, useState } from "react"
import FormsProductConfigs from "../FormsConfigs/FormsProductConfigs"
import { Button, Form } from "react-bootstrap"
import { FormFields, FormFieldTypes } from "../../FormFields"
import {
  FormProductFieldTypes,
  SelectFormFields,
  textFormFields,
} from "../../../enums/FormProductEnums"

function FormFieldBuilder({ state, formConfigs, onSubmit }) {
  const [localState, setLocalState] = useState(state || {})
  const [localFormConfigs, setLocalFormConfigs] = useState(
    formConfigs || FormsProductConfigs.formField
  )
  const [formConfigKeys, setFormConfigKeys] = useState([])
  const [formValid, setFormValid] = useState(false)
  const [initLoaded, setInitLoaded] = useState(false)

  const minMaxConfigs = (minLabel, maxLabel) => {
    return [
      {
        valueKey: "min_value",
        labelName: minLabel,
        type: FormFieldTypes.NUMBER,
      },
      {
        valueKey: "max_value",
        labelName: maxLabel,
        type: FormFieldTypes.NUMBER,
      },
    ]
  }

  useEffect(() => {
    const keys = localFormConfigs.map((field) => field.valueKey)
    setFormConfigKeys(keys)
  }, [localFormConfigs])

  useEffect(() => {
    const validations = localState.validations || {}
    const formValid = formConfigKeys.every((key) => {
      return validations[key] === true
    })
    setFormValid(formValid)
  }, [formConfigKeys, localState])

  useEffect(() => {
    if (!initLoaded && state?.type) {
    } else {
      setLocalState({
        type: localState.type,
      })
    }
    setInitLoaded(true)

    let newConfigs = []
    const stateType = localState.type
    if (textFormFields.includes(stateType)) {
      newConfigs = minMaxConfigs(
        "Minimum Input Characters",
        "Maximum Input Characters"
      )
    } else if (stateType === FormProductFieldTypes.NUMBER) {
      newConfigs = minMaxConfigs("Minimum Number", "Maximum Number")
    } else if (SelectFormFields.includes(stateType)) {
      newConfigs = [
        {
          valueKey: "options",
          labelName: "Choice Options",
          type: FormFieldTypes.TEXT_AREA,
          alertMsg: "Enter each option on a new line",
          info: "Enter the options that can be selected in this field",
        },
      ]
    } else if (stateType === FormProductFieldTypes.PDF_FILE) {
      newConfigs = minMaxConfigs("Minimum Page Count", "Maximum Page Count")
    }

    setLocalFormConfigs([...FormsProductConfigs.formField, ...newConfigs])
  }, [localState.type])

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <FormFields
          state={localState}
          setState={setLocalState}
          valueKey={"type"}
          labelName={"Field Type"}
          optionsList={Object.values(FormProductFieldTypes)}
          type={FormFieldTypes.STRING_SELECT}
        />

        {localState.type && (
          <div>
            {localFormConfigs.map((field, index) => {
              return (
                <FormFields
                  key={index}
                  {...field}
                  state={localState}
                  setState={setLocalState}
                />
              )
            })}

            <Button
              variant={"primary"}
              type={"submit"}
              className={"w-100 mt-4 mb-5"}
              disabled={!formValid}
              onClick={() => {
                onSubmit(localState)
              }}
            >
              Submit
            </Button>
          </div>
        )}
      </Form>
    </div>
  )
}

export default FormFieldBuilder
