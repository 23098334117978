import React, { useContext, useEffect, useState } from "react"
import { AppContext } from "../../../utils/components/AppContext"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import DashboardPageContainer from "../DashboardPageContainer"
import { AddItemButton, LoadingView } from "../../../utils/components"
import PageMetaTags from "../Shared/PageMetaTags"
import { toTitleCase, useOffCanvas } from "../../../utils/functions"
import { Badge, Tab, Tabs } from "react-bootstrap"
import DocumentsContainer from "./DocumentsContainer"
import GenericForm from "../../Forms/Shared/GenericForm"
import DocumentFormConfig from "../../Forms/FormsConfigs/DocumentFormConfig"
import { AppRoutes } from "../../../utils/appRoutes"
import { OffCanvasContext } from "../../../utils/components/OffCanvasContext"
import DocumentsTableList from "./DocumentsTableList"
import { EmpPermissions } from "../../../enums/EmpPermissions"
import { Products } from "../../../enums/OrgEnums"
import ProductPermissionCheckView from "../ProductPermissionCheckView"

function DocumentsMain({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
}) {
  const { contextState, updateContextState } = useContext(AppContext)
  const { permissions, labelsOverride } = contextState
  const { showOffCanvas } = useOffCanvas()
  const { closeOffCanvas } = useContext(OffCanvasContext)

  const navigate = useNavigate()

  const { id } = useParams()

  const [searchParams, setSearchParams] = useSearchParams()
  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value })
  }

  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(searchParams.get("activeTab"))
  const [showSecondLoader, setShowSecondLoader] = useState(false)
  const [fetchedTabs, setFetchedTabs] = useState([])

  const activateTab = (key) => {
    key = key || "mine"
    setActiveTab(key)
    updateQueryParam("activeTab", key)

    if (!fetchedTabs.includes(key)) {
      setFetchedTabs([...fetchedTabs, key])
    }
  }

  useEffect(() => {
    activateTab(searchParams.get("activeTab"))
  }, [searchParams])

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <ProductPermissionCheckView product={Products.DOCUMENTS_MANAGEMENT}>
          <div>
            <PageMetaTags title={`Documents | ${toTitleCase(activeTab)}`} />
            <Tabs
              activeKey={activeTab}
              className={"mb-2 pb-3"}
              variant={"underline"}
              onSelect={(key) => activateTab(key)}
            >
              <Tab
                eventKey="mine"
                title="Mine"
              >
                {fetchedTabs.includes("mine") && (
                  <LoadingView
                    isLoading={showSecondLoader}
                    centerLoader={false}
                    view={
                      <>
                        <DocumentsContainer
                          getRequest={getRequest}
                          putRequest={putRequest}
                          deleteRequest={deleteRequest}
                          patchRequest={patchRequest}
                          loaderCallback={setShowSecondLoader}
                          title={"My Documents"}
                          description={"Documents uploaded or created by you"}
                          uploadUrlEndpoint={"/document/generate-put-url"}
                          publishedEndpoint={"/mine/published"}
                          draftsEndpoint={"/mine/drafts"}
                          folderType={"Document"}
                        />
                      </>
                    }
                  />
                )}
              </Tab>

              <Tab
                eventKey="shared_with_me"
                title="Shared With Me"
              >
                {fetchedTabs.includes("shared_with_me") && (
                  <LoadingView
                    isLoading={showSecondLoader}
                    centerLoader={false}
                    view={
                      <>
                        <DocumentsContainer
                          getRequest={getRequest}
                          putRequest={putRequest}
                          patchRequest={patchRequest}
                          deleteRequest={deleteRequest}
                          loaderCallback={setShowSecondLoader}
                          title={"Documents Shared With Me"}
                          description={
                            "Documents that have been shared with you, forwarded to you or you have been invited to collaborate on."
                          }
                          publishedEndpoint={"/shared/published"}
                          draftsEndpoint={"/shared/drafts"}
                          showAddButton={false}
                        />
                      </>
                    }
                  />
                )}
              </Tab>

              <Tab
                eventKey="templates"
                title={"Templates"}
              >
                {fetchedTabs.includes("templates") && (
                  <LoadingView
                    isLoading={showSecondLoader}
                    centerLoader={false}
                    view={
                      <>
                        <DocumentsContainer
                          getRequest={getRequest}
                          putRequest={putRequest}
                          patchRequest={patchRequest}
                          deleteRequest={deleteRequest}
                          loaderCallback={setShowSecondLoader}
                          title={"Available Templates"}
                          description={
                            "Use these templates to get started with your document creation."
                          }
                          showAddButton={false}
                          publishedEndpoint={"/shared/published?template=true"}
                        />
                      </>
                    }
                  />
                )}
              </Tab>

              <Tab
                eventKey="template_publishing"
                title="Template Publishing"
              >
                {fetchedTabs.includes("template_publishing") && (
                  <LoadingView
                    isLoading={showSecondLoader}
                    centerLoader={false}
                    view={
                      <>
                        <DocumentsContainer
                          getRequest={getRequest}
                          putRequest={putRequest}
                          patchRequest={patchRequest}
                          deleteRequest={deleteRequest}
                          loaderCallback={setShowSecondLoader}
                          title={"Template Publishing Tool"}
                          description={
                            "Create, collaborate and publish document templates for others to use."
                          }
                          publishedEndpoint={
                            permissions.DOCUMENT_TEMPLATE_PUBLISHER
                              ? "/mine/published?template=true"
                              : null
                          }
                          draftsEndpoint={
                            permissions.DOCUMENT_TEMPLATE_PUBLISHER
                              ? "/mine/drafts?template=true"
                              : null
                          }
                          collabDraftsEndpoint={"/shared/drafts?template=true"}
                          showAddButton={
                            permissions.DOCUMENT_TEMPLATE_PUBLISHER
                          }
                          forTemplates={true}
                          folderType={"Template"}
                        />
                      </>
                    }
                  />
                )}
              </Tab>
            </Tabs>
          </div>
        </ProductPermissionCheckView>
      }
    />
  )
}

function DocumentsMainPage() {
  return <DashboardPageContainer PageComponent={DocumentsMain} />
}

export default DocumentsMainPage
