import React, { useEffect, useState } from "react"
import formsProductConfigs from "../FormsConfigs/FormsProductConfigs"
import FormsProductConfigs from "../FormsConfigs/FormsProductConfigs"
import { AppOffCanvasLocal } from "../../../utils/components"
import { FormFields, FormFieldTypes } from "../../FormFields"
import { Button, Form } from "react-bootstrap"
import { updateObjectState } from "../../../utils/functions"

function FormCreationForm({
  state,
  formConfigs,
  onSubmit,
  requireApprval,
  approvalWorkflows,
}) {
  const [localState, setLocalState] = useState(state || {})
  const [localFormConfigs, setLocalFormConfigs] = useState(
    formConfigs || FormsProductConfigs.mainform
  )
  const [formConfigKeys, setFormConfigKeys] = useState([])
  const [formValid, setFormValid] = useState(false)

  useEffect(() => {
    const keys = localFormConfigs.map((field) => field.valueKey)
    setFormConfigKeys(keys)
  }, [localFormConfigs])

  useEffect(() => {
    const validations = localState.validations || {}
    const formValid = formConfigKeys.every((key) => {
      return validations[key] === true
    })
    setFormValid(formValid)
  }, [formConfigKeys, localState?.validations])

  useEffect(() => {
    let configs = [...localFormConfigs]

    if (localState?.limit_submissions === true) {
      const maxSub = {
        valueKey: "max_submissions",
        type: FormFieldTypes.NUMBER,
        info: "Maximum number of submissions allowed",
        minValue: 1,
      }

      // insert maxSub at 3 index and shift the rest
      configs.splice(3, 0, maxSub)
      setLocalFormConfigs(configs)
    } else if (!localState?.limit_submissions) {
      setLocalFormConfigs(
        configs.filter((field) => field.valueKey !== "max_submissions")
      )
    }
  }, [localState?.limit_submissions])

  useEffect(() => {
    let configs = [...localFormConfigs]
    if (localState?.set_active_dates === true) {
      setLocalFormConfigs([
        ...configs,
        {
          valueKey: "completion_start_datetime",
          type: FormFieldTypes.DATETIME_LOCAL,
          info: "Date the form can be first completed",
        },
        {
          valueKey: "completion_end_datetime",
          type: FormFieldTypes.DATETIME_LOCAL,
          info: "Date the form must be completed by",
        },
      ])
    } else {
      setLocalFormConfigs(
        configs.filter(
          (field) =>
            field.valueKey !== "completion_start_datetime" &&
            field.valueKey !== "completion_end_datetime"
        )
      )
    }
  }, [localState?.set_active_dates])

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        {requireApprval && (
          <FormFields
            state={localState}
            setState={setLocalState}
            valueKey={"approval_workflow_id"}
            labelName={"Approval Workflow"}
            info={
              "Choose the approval workflow that this form should use for approval"
            }
            optionsList={approvalWorkflows}
            optionsLabelKey={"name"}
            optionsValueKey={"id"}
            type={FormFieldTypes.OBJECT_SELECT}
            alertMsg={
              'Create an approval workflow with at least 1 step in the "Approval Workflow" tab if you don\'t see any options here'
            }
          />
        )}

        {((requireApprval && localState.approval_workflow_id) ||
          !requireApprval) && (
          <div>
            {localFormConfigs.map((field, index) => {
              return (
                <FormFields
                  key={index}
                  {...field}
                  state={localState}
                  setState={setLocalState}
                />
              )
            })}

            <Button
              variant={"primary"}
              type={"submit"}
              className={"w-100 mt-4 mb-5"}
              disabled={!formValid}
              onClick={() => {
                onSubmit(localState)
              }}
            >
              Submit
            </Button>
          </div>
        )}
      </Form>
    </div>
  )
}

export default FormCreationForm
