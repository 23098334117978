import React, { useContext } from "react"
import EmployeeHighlightCard from "../Employee/EmployeeHighlightCard"
import { FaChevronRight, FaCopy, FaPlus, FaSitemap } from "react-icons/fa"
import { AiOutlineClose } from "react-icons/ai"
import { Button, Card, Table } from "react-bootstrap"
import { apiRequest } from "../../../utils/apiRequests"
import { AppRoutes } from "../../../utils/appRoutes"
import { TbSwitchVertical } from "react-icons/tb"
import { useAppModal } from "../../../utils/functions"
import ExpandableText from "../Shared/ExpandableText"
import CopyWithTooltip from "../Shared/CopyWithTooltip"
import { AppContext } from "../../../utils/components/AppContext"

function OrganizationStructureCard({ organization, getStructure }) {
  const { showAppModal } = useAppModal()
  const { contextState, updateContextState } = useContext(AppContext)

  const currentOrg = contextState.organization

  return (
    <Card className={organization.is_current ? "border-primary border-2" : ""}>
      <Card.Body>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className={"text-center"}>
            <img
              src={organization.logo}
              alt={`${organization.name} Logo`}
              width={70}
              height={70}
              className="rounded-circle me-3"
            />
            <div>
              <h5 className={"my-3"}>{organization.name}</h5>
              <div className={"small"}>
                <ExpandableText
                  text={organization.description}
                  lines={2}
                />
              </div>
            </div>
          </div>
        </div>

        <Table
          borderless={true}
          className={"mt-4"}
          responsive={true}
        >
          <tbody>
            <tr>
              <td className={"small"}>Org ID</td>
              <td className={"small"}>{organization.id}</td>
              <td>
                <CopyWithTooltip text={organization.id} />
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        <div className={"d-flex justify-content-between py-3"}>
          <div>
            <FaSitemap
              className={`${
                organization.is_current
                  ? "text-primary"
                  : "pointer text-muted hover-primary"
              }`}
              size={30}
              onClick={() => {
                if (organization.is_current) {
                  return
                }
                getStructure(organization.id)
              }}
            />
          </div>
          {organization.is_member && organization.id !== currentOrg.id && (
            <div className={"d-flex align-items-center"}>
              <Button
                variant="outline-primary"
                size={"sm"}
                onClick={() => {
                  showAppModal({
                    title: `Switch to ${organization.name}`,
                    component: (
                      <p>
                        Are you sure you want to switch to the{" "}
                        {organization.name} organization? Your page will reload
                        and you will be logged out of your current organization.
                      </p>
                    ),
                    truthyFunction: async () => {
                      const { success, response } = await apiRequest.get(
                        `/organization/switch/${organization.id}`
                      )
                      if (success) {
                        window.location.href = AppRoutes.Home.path
                      } else {
                        showAppModal({
                          title: "Error",
                          component: <p>{response.message}</p>,
                          hideFooter: true,
                        })
                      }
                    },
                  })
                }}
              >
                <TbSwitchVertical className={"me-2"} />
                Switch to Org
              </Button>
            </div>
          )}
          {organization.id === currentOrg.id && (
            <h6 className={"text-success fst-italic"}>Current Organization</h6>
          )}
        </div>
      </Card.Footer>
    </Card>
  )
}

export default OrganizationStructureCard
