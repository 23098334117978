import React from "react"
import Table from "react-bootstrap/Table"
import Badge from "react-bootstrap/Badge"
import { Button, OverlayTrigger, Dropdown } from "react-bootstrap"
import { EmployeePopover } from "../EmployeeCards"
import { StatusBadge } from "../../../utils/components"
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs"
import EmployeeHighlightCard from "./EmployeeHighlightCard"
import { formatDate } from "../../../utils/dateTime"

export function TimeOffRequestTableRow({
  request,
  onShowApproversClick,
  actions,
  policy,
  index,
}) {
  policy = policy || {}
  return (
    <tr>
      {request.employee && (
        <td>
          <EmployeeHighlightCard
            employee={request.employee}
            useCard={false}
          />
        </td>
      )}
      <td>{formatDate(request.timestamp)}</td>
      <td>
        <OverlayTrigger
          key={request.author_employee.id}
          trigger="click"
          placement="top"
          overlay={<EmployeePopover employee={request.author_employee} />}
          rootClose
        >
          <Button
            variant="link"
            className=""
          >
            {request.author_employee.full_name}
          </Button>
        </OverlayTrigger>
      </td>
      <td>{request.comment}</td>
      <td className="text-primary">
        <strong>
          {request.time_units} {policy.time_unit}
        </strong>
      </td>
      <td>{formatDate(request.start_date)}</td>
      <td>{formatDate(request.end_date)}</td>

      <td>
        <div
          className={"pointer"}
          onClick={() => {
            onShowApproversClick(request.approval_steps)
          }}
        >
          <StatusBadge
            status={request.status}
            className={"text-decoration-underline"}
          />
        </div>
      </td>
      {actions.length > 0 && (
        <td>
          <Dropdown>
            <Dropdown.Toggle
              variant="transparent"
              id="dropdown-basic"
              // as={BsThreeDotsVertical}
            >
              <BsThreeDots />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {actions.map((action, i) => (
                <Dropdown.Item
                  onClick={() => action.onClick(request, index)}
                  key={i}
                >
                  {action.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      )}
    </tr>
  )
}

export function TimeOffRequestsTable({
  requests,
  onShowApproversClick,
  actions,
  policies,
  showEmployee = true,
}) {
  const theadStyle = {
    minWidth: "200px",
  }

  return (
    <Table
      striped
      responsive
      bordered
      hover
      className="mb-4 text-center align-middle"
    >
      <thead className={"bg-primary"}>
        <tr className={"bg-primary"}>
          {showEmployee && (
            <th
              style={{
                minWidth: "300px",
              }}
            >
              For
            </th>
          )}
          <th style={theadStyle}>Submitted On</th>
          <th style={theadStyle}>By</th>
          <th style={theadStyle}>Reason</th>
          <th style={theadStyle}>Duration</th>
          <th style={theadStyle}>From</th>
          <th style={theadStyle}>To</th>
          <th style={theadStyle}>Status</th>
          <th style={theadStyle}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {requests.map((request, index) => {
          const policy = policies[request.time_off_policy_id]
          return (
            <TimeOffRequestTableRow
              request={request}
              key={index}
              index={index}
              onShowApproversClick={onShowApproversClick}
              actions={actions(request)}
              policy={policy}
            />
          )
        })}
      </tbody>
    </Table>
  )
}
