import React, { useContext, useState } from "react"
import { Alert, Button, Card } from "react-bootstrap"
import {
  estimateUploadTime,
  useAppModal,
  useOffCanvas,
  useToast,
} from "../../../utils/functions"
import { MdOutlinePublish } from "react-icons/md"
import {
  FaDownload,
  FaPrint,
  FaShareSquare,
  FaComments,
  FaLock,
  FaListUl,
  FaLockOpen,
  FaPlus,
  FaClock,
  FaFileSignature,
  FaEye,
  FaExternalLinkAlt,
} from "react-icons/fa"
import { FaTrashCan, FaUserGroup } from "react-icons/fa6"
import { AppOffCanvasLocal } from "../../../utils/components"
import PaginatedEmployeesDisplay from "../Employee/PaginatedEmployeesDisplay"
import { formatDateTime } from "../../../utils/dateTime"
import ChatComponent from "../Shared/ChatComponent"
import { DocumentPermissions } from "../../../enums/DocumentsEnums"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "../../../utils/appRoutes"
import { AppContext } from "../../../utils/components/AppContext"
import GenericForm from "../../Forms/Shared/GenericForm"
import DocumentFormConfig from "../../Forms/FormsConfigs/DocumentFormConfig"
import FileUploadingView from "../Shared/FileUploadingView"

function DocToolbar({
  doc,
  postRequest,
  getRequest,
  putRequest,
  onDelete,
  onPublish,
  onAddSignature,
  canChat,
  onShareClick,
  onNewVersionClick,
  onLockUnlockClick,
  isLocked,
  canDownload,
  onMembersClick,
  rightElements,
  showDownload = true,
  showActivities,
  showViews,
  showChats = true,
}) {
  const { setGlobalLoading, updateContextState } = useContext(AppContext)
  const { showOffCanvas, closeOffCanvas } = useOffCanvas()
  const fileBlobUrl = doc.local_file_url
  const { showAppModal } = useAppModal()
  const { showToast } = useToast()
  const isVersion = doc.is_version
  const isPublished = doc.is_published
  const isDraft = doc.is_draft
  const isPdf = doc.extension === "pdf"
  const docPermissions = doc.permissions || []

  const [showActivitiesModal, setShowActivitiesModal] = useState(false)
  const [employeesActivities, setEmployeesActivities] = useState([])
  const [activitiesPagingInfo, setActivitiesPagingInfo] = useState({})

  const [showViewsCanvas, setShowViewsCanvas] = useState(false)
  const [views, setViews] = useState([])
  const [viewsPagingInfo, setViewsPagingInfo] = useState({})
  const [showChatCanvas, setShowChatCanvas] = useState(false)
  const [messages, setMessages] = useState([])
  const [messagesPagingInfo, setMessagesPagingInfo] = useState({})
  const [isUploading, setIsUploading] = useState(false)
  const [uploadData, setUploadData] = useState({})

  const navigate = useNavigate()

  // Download: create a temporary <a> element
  const downloadDoc = () => {
    const link = document.createElement("a")
    link.href = fileBlobUrl
    link.download = `${doc.name}.${doc.extension}`
    link.click()
    // showToast({
    //   success: true,
    //   title: "Download Started",
    //   message: "Your download has started.",
    // })
    recordActivity("Downloaded")
  }

  const recordActivity = (type_) => {
    postRequest(
      `/document/${doc.id}/log-activity`,
      null,
      () => {
        setEmployeesActivities({})
      },
      {
        type: type_,
      }
    )
  }

  const hasPermission = (perm) => {
    return docPermissions.includes(perm)
  }

  const newFileVersion = async () => {
    updateContextState({
      formData: {},
    })

    showOffCanvas({
      title: `Add a new Version`,
      subtitle: `Add a new draft version for ${doc.name}`,
      component: (
        <div>
          <GenericForm
            formConfigs={[DocumentFormConfig.uploadDocument[2]]}
            onSubmit={async (data) => {
              setGlobalLoading(true)
              const uploadSecsEstimate = await estimateUploadTime(
                data.document_file_size_mb
              )

              putRequest(
                `/document/generate-put-url`,
                setGlobalLoading,
                (response) => {
                  setUploadData({
                    file: data.document_file,
                    presignedUrl: response.put_url,
                    documentName: doc.name,
                    doc: response.document,
                  })
                },
                {
                  existing_doc_id: doc.id,
                  document_type: "Uploaded File",
                  upload_secs_estimate: uploadSecsEstimate,
                  document_extension: data.document_file.name.split(".").pop(),
                },
                false,
                true,
                false
              )
            }}
          />
        </div>
      ),
    })
  }

  if (uploadData.file) {
    return (
      <FileUploadingView
        {...uploadData}
        onSuccessBtnTxt={`View Noew Draft Version`}
        onSuccessAction={() => {
          navigate(
            `${AppRoutes.DraftDocumentDetais.paramLessPath}/${uploadData?.doc?.id}`
          )
        }}
        onClose={() => {
          setUploadData({})
        }}
      />
    )
  }
  return (
    <div>
      <Card
        body={true}
        className={"align-middle"}
      >
        <div className={"d-flex justify-content-between my-auto"}>
          <div className={"d-flex gap-2"}>
            {isVersion && !isPublished && onPublish && doc.is_owner && (
              <Button
                variant="outline-success"
                onClick={() => {
                  showAppModal({
                    title: "Publish Document",
                    component: (
                      <div>
                        <p>
                          Are you sure you want to publish {doc.name}?<br />
                          Here is a list of what will happen after publishing:
                        </p>

                        <ul>
                          <li>
                            You won't be able to make any changes like adding
                            signature boxes
                          </li>
                          <li>
                            A new version will be created for the document
                          </li>
                          <li>You will be able to share it with others</li>
                          <li>
                            You or the people you share the doc with will be
                            able to have a chat conversation on the document
                          </li>
                          <li>
                            Activities such as view, print, download, sharing
                            etc will all be logged and will be viewable by you
                            or people with permission to view this info
                          </li>
                        </ul>
                      </div>
                    ),
                    truthyFunction: () => {
                      onPublish(doc.id)
                    },
                  })
                }}
              >
                <MdOutlinePublish className={"me-2"} />
                Publish
              </Button>
            )}

            {/*{isVersion && !isPublished && onAddSignature && (*/}
            {/*  <Button*/}
            {/*    variant={"outline-primary"}*/}
            {/*    onClick={() => {*/}
            {/*      showAppModal({*/}
            {/*        title: "Add Signature Boxes",*/}
            {/*        component: <p></p>,*/}
            {/*        hideFooter: true,*/}
            {/*      })*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <FaFileSignature className={"me-2"} />*/}
            {/*    Add Signature Boxes*/}
            {/*  </Button>*/}
            {/*)}*/}

            {showChats && (
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setShowChatCanvas(true)
                }}
                title="Document Chat"
                aria-label="Document Chat"
              >
                <FaComments />
              </Button>
            )}

            {showActivities && (
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setShowActivitiesModal(true)
                }}
                title="View Activities"
                aria-label="View Activities"
              >
                <FaListUl />
              </Button>
            )}

            {showViews && (
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setShowViewsCanvas(true)
                }}
                title="Document Views"
                aria-label="Document Views"
              >
                <FaEye />
              </Button>
            )}

            {isPublished &&
              !doc.is_version &&
              hasPermission(DocumentPermissions.EDIT) && (
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    if (doc.is_user_created) {
                      showAppModal({
                        title: "Add New Version",
                        component: (
                          <div>
                            <Alert variant={"info"}>
                              Are you sure you want to add a new version to{" "}
                              {doc.name}?
                              <br />
                              <br />
                              The current version will remain published untill
                              you publish the new version.
                            </Alert>
                          </div>
                        ),
                        truthyFunction: () => {
                          putRequest(
                            `/document/${doc.id}/add-version`,
                            setGlobalLoading,
                            (response) => {
                              navigate(`${AppRoutes.Documents.path}`)
                              showAppModal({
                                title: "New Version Added",
                                component: (
                                  <p>Do you want to view the new version?</p>
                                ),
                                truthyFunction: () => {
                                  navigate(
                                    `${AppRoutes.DocumentCollaborationDraft.paramLessPath}/${response.document_id}`
                                  )
                                },
                              })
                            },
                            {},
                            false,
                            false,
                            false
                          )
                        },
                      })
                    } else {
                      newFileVersion()
                    }
                  }}
                  title="Add New Version"
                  aria-label="Add New Version"
                >
                  <FaPlus />
                </Button>
              )}

            {isPublished && !doc.is_version && (
              <Button
                variant="outline-secondary"
                onClick={() => {
                  showOffCanvas({
                    title: "Older Versions",
                    subtitle: `View older versions of ${doc.name}`,
                    backdropClose: true,
                    component: (
                      <div>
                        {doc.versions.map((version, i) => {
                          if (version.number === doc.version_number) {
                            return null
                          }
                          return (
                            <Card
                              body={true}
                              key={i}
                              className={"mb-3"}
                            >
                              <Button
                                variant={"link"}
                                onClick={() => {
                                  // open in new tab
                                  if (doc.is_user_created) {
                                    window.open(
                                      `${AppRoutes.DocumentCollaborationDraft.paramLessPath}/${version.id}`,
                                      "_blank"
                                    )
                                  } else {
                                    window.open(
                                      `${AppRoutes.DraftDocumentDetais.paramLessPath}/${version.id}`,
                                      "_blank"
                                    )
                                  }
                                }}
                              >
                                Version {version.number}{" "}
                                <FaExternalLinkAlt className={"ms-2"} />
                              </Button>
                            </Card>
                          )
                        })}
                      </div>
                    ),
                  })
                }}
                title="View Other Versions"
                aria-label="View Other Versions"
              >
                <FaClock />
              </Button>
            )}

            {onLockUnlockClick && (
              <Button
                variant="outline-secondary"
                onClick={onLockUnlockClick}
                title={isLocked ? "Unlock Document" : "Lock Document"}
                aria-label={isLocked ? "Unlock Document" : "Lock Document"}
              >
                {isLocked ? <FaLock /> : <FaLockOpen />}
              </Button>
            )}

            {onMembersClick && (
              <Button
                variant="outline-secondary"
                onClick={onMembersClick}
                title={"Document Members"}
                aria-label={"Document Members"}
              >
                <FaUserGroup />
              </Button>
            )}
          </div>

          <div className={"d-flex flex-row align-items-end gap-2"}>
            {showDownload && (
              <Button
                variant="outline-success"
                title={"Download Document"}
                aria-label={"Download Document"}
                disabled={!canDownload}
                onClick={() => {
                  showAppModal({
                    title: "Download Document",
                    component: (
                      <p>
                        Are you sure you want to download {doc.name}? This
                        action will be logged in the Activities for this
                        document.
                      </p>
                    ),
                    truthyFunction: downloadDoc,
                  })
                }}
              >
                <FaDownload />
              </Button>
            )}

            {rightElements && rightElements}

            {isVersion && !isPublished && onDelete && (
              <Button
                variant="outline-danger"
                title={"Delete Document"}
                aria-label={"Delete Document"}
                onClick={() => {
                  showAppModal({
                    title: "Delete Document",
                    component: (
                      <div>
                        <p>
                          Are you sure you want to delete {doc.name}?<br />
                          This action cannot be undone.
                        </p>
                      </div>
                    ),
                    truthyFunction: () => {
                      onDelete(doc.id)
                    },
                  })
                }}
              >
                <FaTrashCan />
              </Button>
            )}
          </div>
        </div>
      </Card>

      <AppOffCanvasLocal
        showOffCanvas={showActivitiesModal}
        setShowOffCanvas={setShowActivitiesModal}
        title={"Document Activities"}
        canvasWidthPercentage={70}
        backdropClose={true}
        component={
          <div>
            <PaginatedEmployeesDisplay
              endpoint={`/document/${doc.id}/activities`}
              getRequest={getRequest}
              minimal={true}
              noEmployeesMsg={"No activities have been logged yet"}
              externalEmployees={employeesActivities}
              setExternalEmployees={setEmployeesActivities}
              externalPagingInfo={activitiesPagingInfo}
              setExternalPagingInfo={setActivitiesPagingInfo}
              extraColumns={[
                {
                  columnName: "Doc Version",
                  key: "version_number",
                },
                {
                  columnName: "Activitiy",
                  key: "activity",
                },
                {
                  columnName: "Date & Time",
                  renderComponent: (activity) => (
                    <span>{formatDateTime(activity.activity_timestamp)}</span>
                  ),
                },
              ]}
            />
          </div>
        }
      />

      <AppOffCanvasLocal
        showOffCanvas={showViewsCanvas}
        setShowOffCanvas={setShowViewsCanvas}
        title={"Document Views"}
        canvasWidthPercentage={70}
        backdropClose={true}
        component={
          <div>
            <PaginatedEmployeesDisplay
              endpoint={`/document/${doc.id}/views`}
              getRequest={getRequest}
              minimal={true}
              noEmployeesMsg={"No views have been logged yet"}
              externalEmployees={views}
              setExternalEmployees={setViews}
              externalPagingInfo={viewsPagingInfo}
              setExternalPagingInfo={setViewsPagingInfo}
              extraColumns={[
                {
                  columnName: "Organization",
                  key: "organization_name",
                },
                {
                  columnName: "First Viewed",
                  renderComponent: (emp) => {
                    const activity = emp.activity
                    return <span>{formatDateTime(activity.timestamp)}</span>
                  },
                },
                {
                  columnName: "Last Viewed",
                  renderComponent: (emp) => {
                    const activity = emp.activity
                    return <span>{formatDateTime(activity.last_viewed)}</span>
                  },
                },
              ]}
            />
          </div>
        }
      />

      {/*chats canvas*/}
      <AppOffCanvasLocal
        showOffCanvas={showChatCanvas}
        setShowOffCanvas={setShowChatCanvas}
        title={"Document Chat"}
        subtitle={"Discuss this document with others."}
        canvasWidthPercentage={30}
        backdropClose={true}
        component={
          <div>
            <ChatComponent
              endpoint={`/document/${doc.id}/${doc.fe_type}/chat`}
              getRequest={getRequest}
              putRequest={putRequest}
              postRequest={postRequest}
              canSendMessage={canChat}
              externalMessagesState={messages}
              setExternalMessagesState={setMessages}
              externalPagingInfo={messagesPagingInfo}
              setExternalPagingInfo={setMessagesPagingInfo}
            />
          </div>
        }
      />
    </div>
  )
}

export default DocToolbar
