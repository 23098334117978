import React, { useContext, useEffect, useState } from "react"
import { AppContext } from "../../../utils/components/AppContext"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { AppOffCanvasLocal, LoadingView } from "../../../utils/components"
import PageMetaTags from "../Shared/PageMetaTags"
import { toTitleCase } from "../../../utils/functions"
import { Card, Tab, Tabs } from "react-bootstrap"
import DashboardPageContainer from "../DashboardPageContainer"
import { EmpPermissions } from "../../../enums/EmpPermissions"
import RequestFormsContainer from "./RequestFormsContainer"
import ApprovalWorkflowsView from "../Organization/ApprovalWorkflowsView"
import FormMemberFormsTable from "../FormsProduct/FormMemberFormsTable"
import { AppRoutes } from "../../../utils/appRoutes"
import { Products } from "../../../enums/OrgEnums"
import ProductPermissionCheckView from "../ProductPermissionCheckView"

function ApprovalRequestsMain({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
}) {
  const {
    contextState,
    updateContextState,
    hasEmpPermission,
    hasAnyEmpPermission,
  } = useContext(AppContext)
  const { permissions, labelsOverride } = contextState

  const navigate = useNavigate()

  const { id } = useParams()

  const [searchParams, setSearchParams] = useSearchParams()
  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value })
  }

  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(searchParams.get("activeTab"))
  const [showSecondLoader, setShowSecondLoader] = useState(false)
  const [myForms, setMyForms] = useState([])
  const [otherForms, setOtherForms] = useState([])
  const [showAddFormOffCanvas, setShowAddFormOffCanvas] = useState(false)
  const [approvalWorkflows, setApprovalWorkflows] = useState([])
  const [assignedForms, setAssignedForms] = useState([])
  const [sharedWithMeForms, setSharedWithMeForms] = useState([])
  const [forApprovalForms, setForApprovalForms] = useState([])
  const [fetchedTabs, setFetchedTabs] = useState([])

  const canAdd = hasAnyEmpPermission([
    EmpPermissions.REQUESTS_FORM_PUBLISHER,
    EmpPermissions.REQUESTS_FORM_GLOBAL_PUBLISHER,
  ])

  const activateTab = (key) => {
    key = key || "created_forms"
    setActiveTab(key)
    updateQueryParam("activeTab", key)

    if (key === "for_approval" && !fetchedTabs.includes(key)) {
      getRequest(`/form/for-approval/requests`, setIsLoading, (res) => {
        setForApprovalForms(res.forms)
        setFetchedTabs(fetchedTabs.concat(key))
      })
    }
  }

  useEffect(() => {
    activateTab(searchParams.get("activeTab"))
  }, [searchParams])

  useEffect(() => {
    if (!canAdd) return
    getRequest(`/form/publisher/requests`, setIsLoading, (res) => {
      setMyForms(res.forms)
      setApprovalWorkflows(res.approval_workflows)
      // setAssignedForms(res.assigned_forms)
      // setSharedWithMeForms(res.forms_shared_with_me)
    })
  }, [])

  useEffect(() => {
    getRequest(`/form/shared-assigned/requests`, setIsLoading, (res) => {
      setAssignedForms(res.assigned_forms)
      setSharedWithMeForms(res.view_forms)
    })
  }, [])

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <ProductPermissionCheckView product={Products.APPROVAL_REQUESTS}>
          <div>
            <PageMetaTags title={`Approval Reqs | ${toTitleCase(activeTab)}`} />
            <Tabs
              activeKey={activeTab}
              className={"mb-2 pb-3"}
              variant={"underline"}
              onSelect={(key) => activateTab(key)}
            >
              {hasAnyEmpPermission([
                EmpPermissions.REQUESTS_FORM_PUBLISHER,
                EmpPermissions.REQUESTS_FORM_GLOBAL_PUBLISHER,
              ]) && (
                <Tab
                  eventKey="created_forms"
                  title="Created Forms"
                >
                  <LoadingView
                    isLoading={showSecondLoader}
                    centerLoader={false}
                    view={
                      <>
                        <RequestFormsContainer
                          title={"My Created Request Forms"}
                          subTitle={
                            "Create and manage request forms for your organization"
                          }
                          approvalWorkflows={approvalWorkflows}
                          patchRequest={patchRequest}
                          putRequest={putRequest}
                          postRequest={postRequest}
                          deleteRequest={deleteRequest}
                          forms={myForms}
                          setForms={setMyForms}
                          showAddForm={true}
                        />
                      </>
                    }
                  />
                </Tab>
              )}

              <Tab
                eventKey="for_submission"
                title="For Submission"
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <Card className={"mb-4"}>
                        <Card.Header className={"py-4"}>
                          <Card.Title>{"Forms Assigned to Me"}</Card.Title>
                          <Card.Subtitle
                            as={"p"}
                            className={"mt-2"}
                          >
                            {
                              "These are the forms assigned to you for submission"
                            }
                          </Card.Subtitle>
                        </Card.Header>
                      </Card>

                      <FormMemberFormsTable
                        forms={assignedForms}
                        onRowClick={(rowForm) => {
                          navigate(
                            `${AppRoutes.FormMemberSubmitView.paramLessPath}/${rowForm.id}`
                          )
                        }}
                      />
                    </>
                  }
                />
              </Tab>

              <Tab
                eventKey="for_viewing"
                title="For Viewing"
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <Card className={"mb-4"}>
                        <Card.Header className={"py-4"}>
                          <Card.Title>{"Forms Shared with Me"}</Card.Title>
                          <Card.Subtitle
                            as={"p"}
                            className={"mt-2"}
                          >
                            {
                              "These are the forms shared with you so you can view the submission and approval statuses"
                            }
                          </Card.Subtitle>
                        </Card.Header>
                      </Card>

                      <FormMemberFormsTable
                        forms={sharedWithMeForms}
                        onRowClick={(rowForm) => {
                          navigate(
                            `${AppRoutes.FormMemberView.paramLessPath}/${rowForm.id}`
                          )
                        }}
                      />
                    </>
                  }
                />
              </Tab>

              <Tab
                eventKey="for_approval"
                title="For Approval"
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <Card className={"mb-4"}>
                        <Card.Header className={"py-4"}>
                          <Card.Title>{"Forms for Approval"}</Card.Title>
                          <Card.Subtitle
                            as={"p"}
                            className={"mt-2"}
                          >
                            {
                              "These are the forms where you are an approver for submissions."
                            }
                          </Card.Subtitle>
                        </Card.Header>
                      </Card>

                      <FormMemberFormsTable
                        forms={forApprovalForms}
                        onRowClick={(rowForm) => {
                          navigate(
                            `${AppRoutes.FormApproverView.paramLessPath}/${rowForm.id}`
                          )
                        }}
                      />
                    </>
                  }
                />
              </Tab>

              {hasAnyEmpPermission([
                EmpPermissions.REQUESTS_FORM_PUBLISHER,
                EmpPermissions.REQUESTS_FORM_GLOBAL_PUBLISHER,
              ]) && (
                <Tab
                  eventKey="approval_workflows"
                  title="Approval Workflows"
                >
                  <LoadingView
                    isLoading={showSecondLoader}
                    centerLoader={false}
                    view={
                      <div>
                        <ApprovalWorkflowsView
                          workflows={approvalWorkflows}
                          setWorkflows={setApprovalWorkflows}
                          positions={[]}
                          canManage={canAdd}
                          workflowType={"REQUEST_FOMRS"}
                          putRequest={putRequest}
                          patchRequest={patchRequest}
                          addSupervisorStep={false}
                          deleteRequest={deleteRequest}
                          isMultiOrg={hasEmpPermission(
                            EmpPermissions.REQUESTS_FORM_GLOBAL_PUBLISHER
                          )}
                        />
                      </div>
                    }
                  />
                </Tab>
              )}
            </Tabs>

            <AppOffCanvasLocal
              showOffCanvas={showAddFormOffCanvas}
              setShowOffCanvas={setShowAddFormOffCanvas}
              title={"New Request Form"}
              subtitle={`Add a new Request form to your organization`}
              component={<div></div>}
              canvasWidthPercentage={40}
              backdropClose={true}
            />
          </div>
        </ProductPermissionCheckView>
      }
    />
  )
}

function ApprovalRequestsMainPage() {
  return <DashboardPageContainer PageComponent={ApprovalRequestsMain} />
}

export default ApprovalRequestsMainPage
