export const DocumentFormats = Object.freeze({
  PDF: "Pdf",
  DOCX: "Docx",
  XLSX: "Xlsx",
  PPTX: "Pptx",
  DOC: "DOC",
  XLS: "XLS",
  PPT: "PPT",
})

export const DOC_FORMATS = [DocumentFormats.DOCX, DocumentFormats.DOC]
export const XLS_FORMATS = [DocumentFormats.XLSX, DocumentFormats.XLS]
export const PPT_FORMATS = [DocumentFormats.PPTX, DocumentFormats.PPT]

export const DocumentPermissions = Object.freeze({
  VIEW: "View",
  // VIEW_ACTIVITY: "View Activity",
  CHAT: "Chat",
  EDIT: "Edit",
  SHARE: "Share",
  DOWNLOAD: "Download",
  // PRINT: "Print",
  // DELETE: "Delete",
})

export const DocumentRoles = Object.freeze({
  WRITER: "Writer",
  COMMENTATOR: "Commentator",
  READER: "Reader",
})

export const CollabDocShareRoles = Object.freeze({
  COMMENTATOR_W_Share: "Commentator w/ Share Permission",
  COMMENTATOR: "Commentator",
  READER: "Reader",
})
