import React, { useContext, useEffect, useState } from "react"
import { AppContext } from "../../../utils/components/AppContext"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import {
  AddItemButton,
  AppModalLocal,
  AppOffCanvasLocal,
  LoadingView,
} from "../../../utils/components"
import PageMetaTags from "../Shared/PageMetaTags"
import {
  toTitleCase,
  updateObjectState,
  useAppModal,
  useOffCanvas,
} from "../../../utils/functions"
import { Alert, Button, Card, Tab, Tabs } from "react-bootstrap"
import DashboardPageContainer from "../DashboardPageContainer"
import { AppRoutes } from "../../../utils/appRoutes"
import AppBreadCrumb from "../Shared/AppBreadCrumb"
import { FaChevronLeft, FaUserCheck } from "react-icons/fa"
import ApprovalsTable from "../Employee/ApprovalsTable"
import ApprovalWorkflowsView from "../Organization/ApprovalWorkflowsView"
import { EmpPermissions } from "../../../enums/EmpPermissions"
import FormFieldContainer from "./FormFieldContainer"
import OrgEntitySelection from "../../Forms/Shared/OrgEntitySelection"
import { CollabDocShareRoles } from "../../../enums/DocumentsEnums"
import GenericForm from "../../Forms/Shared/GenericForm"
import DocumentFormConfig from "../../Forms/FormsConfigs/DocumentFormConfig"
import FormsProductConfigs from "../../Forms/FormsConfigs/FormsProductConfigs"
import ResourceMembersTable from "../Shared/ResourceMembersTable"
import formsProductConfigs from "../../Forms/FormsConfigs/FormsProductConfigs"
import { MdPublish, MdUnpublished } from "react-icons/md"
import FormSubmissionsTable from "./FormSubmissionsTable"
import PagingItemsLoading from "../Shared/PagingItemsLoading"
import { Products } from "../../../enums/OrgEnums"
import ProductPermissionCheckView from "../ProductPermissionCheckView"

function FormDetails({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
}) {
  const { contextState, updateContextState, setGlobalLoading } =
    useContext(AppContext)
  const { permissions, labelsOverride, employee } = contextState
  const { showAppModal, closeModal } = useAppModal()
  const { showOffCanvas } = useOffCanvas()

  const navigate = useNavigate()

  const { id } = useParams()

  const [searchParams, setSearchParams] = useSearchParams()
  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value })
  }

  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(searchParams.get("activeTab"))
  const [showSecondLoader, setShowSecondLoader] = useState(false)
  const [form, setForm] = useState({})
  const [approvalWorkflow, setApprovalWorkflow] = useState({})
  const [formfields, setFormFields] = useState([])
  const [data, setData] = useState({})
  const [showAddMemberModal, setShowAddMemberModal] = useState(false)

  const [formSubmissions, setFormSubmissions] = useState([])
  const [pagingInfo, setPagingInfo] = useState({})
  const [getEndpoint, setGetEndpoint] = useState("")

  const activateTab = (key) => {
    key = key || "fields"
    setActiveTab(key)
    updateQueryParam("activeTab", key)
  }

  useEffect(() => {
    activateTab(searchParams.get("activeTab"))
  }, [searchParams])

  useEffect(() => {
    getRequest(`/form/${id}`, setIsLoading, (res) => {
      setForm(res.form)
      setApprovalWorkflow(res.approval_workflow)
      setFormFields(res.form_fields)
      setFormSubmissions(res.submissions)
      setPagingInfo(res.paging_info)
    })
  }, [])

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <ProductPermissionCheckView product={Products.APPROVAL_REQUESTS}>
          <div>
            <PageMetaTags title={`Form Details | ${toTitleCase(activeTab)}`} />
            <AppBreadCrumb
              items={[
                {
                  text: "Forms",
                  path: `${AppRoutes.Requests.path}?activeTab=${
                    employee.id === form?.author_employee?.id
                      ? "created_forms"
                      : "for_viewing"
                  }`,
                },
                {
                  text: form?.name || "Form",
                },
              ]}
            />

            <Card className={"mb-4"}>
              <Card.Header className={"py-4"}>
                <Card.Title>{form.name}</Card.Title>
                <Card.Subtitle
                  as={"p"}
                  className={"mt-2"}
                >
                  {form.description}
                </Card.Subtitle>
              </Card.Header>

              {(form.is_owner || approvalWorkflow) && (
                <Card.Footer
                  className={"d-flex justify-content-end py-3 gap-2"}
                >
                  {form.is_owner && (
                    <Button
                      variant={
                        form.is_published ? "outline-secondary" : "success"
                      }
                      onClick={() => {
                        showAppModal({
                          title: form.is_published
                            ? "Unpublish Form"
                            : "Publish Form",
                          component: (
                            <div>
                              <Alert variant={"warning"}>
                                {form.is_published
                                  ? "Are you sure you want to unpublish this form? This will disable all form submissions."
                                  : "Are you sure you want to publish this form? This action will allow users to make submissions against this form."}
                              </Alert>
                            </div>
                          ),
                          truthyFunction: () => {
                            getRequest(
                              `/form/${form.id}/publish-unpublish`,
                              setGlobalLoading,
                              (res) => {
                                setForm({
                                  ...form,
                                  is_published: res.is_published,
                                })
                              },
                              true
                            )
                          },
                        })
                      }}
                    >
                      {form.is_published ? (
                        <span>
                          <MdUnpublished className={"me-1"} /> Unpublish
                        </span>
                      ) : (
                        <span>
                          <MdPublish className={"me-1"} /> Publish
                        </span>
                      )}{" "}
                      Form
                    </Button>
                  )}

                  {approvalWorkflow && (
                    <Button
                      variant={"info"}
                      onClick={() => {
                        showAppModal({
                          title: `${approvalWorkflow.name} Workflow`,
                          hideFooter: true,
                          size: "lg",
                          component: (
                            <div>
                              <ApprovalWorkflowsView
                                workflows={[approvalWorkflow]}
                                setWorkflows={() => {}}
                                positions={[]}
                                canManage={false}
                              />
                            </div>
                          ),
                        })
                      }}
                    >
                      <FaUserCheck className={"me-2"} /> Approval Workflow
                    </Button>
                  )}
                </Card.Footer>
              )}
            </Card>
            <Tabs
              activeKey={activeTab}
              className={"mb-2 pb-3"}
              variant={"underline"}
              onSelect={(key) => activateTab(key)}
            >
              <Tab
                eventKey="fields"
                title="Form Fields"
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <FormFieldContainer
                        form={form}
                        fields={formfields}
                        setFields={setFormFields}
                        canManage={form.is_owner}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        deleteRequest={deleteRequest}
                      />
                    </>
                  }
                />
              </Tab>

              <Tab
                eventKey="submisions"
                title="Submissions"
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <FormSubmissionsTable
                        submissions={formSubmissions}
                        fields={formfields}
                        getRequest={getRequest}
                        postRequest={postRequest}
                        forApproval={false}
                        setSubmissions={() => {}}
                      />

                      <PagingItemsLoading
                        endpoint={`/form/${form.id}`}
                        pagingInfo={pagingInfo}
                        getRequest={getRequest}
                        onLoadComplete={(res) => {
                          setFormSubmissions([
                            ...formSubmissions,
                            ...res.submissions,
                          ])
                          setPagingInfo(res.paging_info)
                        }}
                      />
                    </>
                  }
                />
              </Tab>

              <Tab
                eventKey="members"
                title="Members"
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      {form.is_owner && (
                        <AddItemButton
                          title={"Add Member"}
                          onClick={() => {
                            setData({})
                            setShowAddMemberModal(true)
                          }}
                        />
                      )}
                      <div className={"mb-4"} />
                      <ResourceMembersTable
                        membersInfo={form?.members_info || []}
                        resourceOwnerEmployeeId={form?.author_employee?.id}
                        showPermissions={false}
                        roleHeader={"Action"}
                        roleKey={"action"}
                        showRole={true}
                        emptyText={"This form has not been shared with anyone"}
                        onDelete={(member) => {
                          showAppModal({
                            title: "Delete Form Member",
                            component: (
                              <Alert variant={"danger"}>
                                Are you sure you want to delete this form
                                member?
                              </Alert>
                            ),
                            truthyFunction: () => {
                              deleteRequest(
                                `/form/member/${member.id}`,
                                setGlobalLoading,
                                (response) => {
                                  updateObjectState(setForm, {
                                    members_info: response.members_info,
                                  })
                                }
                              )
                            },
                          })
                        }}
                        onEdit={(member) => {
                          updateContextState({
                            formData: {
                              action: member.action,
                            },
                          })

                          showOffCanvas({
                            title: "Edit Form Member",
                            backdropClose: true,
                            subtitle:
                              "Modify the action the member can perform on the form",
                            component: (
                              <div>
                                <GenericForm
                                  formConfigs={[
                                    formsProductConfigs.formMemberAction[0],
                                  ]}
                                  onSubmit={(submitData) => {
                                    patchRequest(
                                      `/form/member/${member.id}`,
                                      setGlobalLoading,
                                      (response) => {
                                        updateObjectState(setForm, {
                                          members_info: response.members_info,
                                        })
                                      },
                                      submitData
                                    )
                                  }}
                                />
                              </div>
                            ),
                          })
                        }}
                      />
                    </>
                  }
                />
              </Tab>
            </Tabs>

            <AppOffCanvasLocal
              showOffCanvas={showAddMemberModal}
              setShowOffCanvas={setShowAddMemberModal}
              title={"Share this Form"}
              subtitle={
                "Share this form with others people to view or fill out"
              }
              canvasWidthPercentage={40}
              backdropClose={true}
              component={
                <div>
                  {!data.entitiesSelected && (
                    <div>
                      <OrgEntitySelection
                        formState={data}
                        setFormState={setData}
                        multiOrgEntities={true}
                      />
                      <Button
                        variant={"primary"}
                        className={"my-4 w-100"}
                        disabled={
                          !data.entity_ids || data.entity_ids.length === 0
                        }
                        onClick={() => {
                          setData({
                            ...data,
                            entitiesSelected: true,
                            action: "",
                          })
                        }}
                      >
                        Next: Action
                      </Button>
                    </div>
                  )}

                  {data.entitiesSelected && (
                    <div>
                      <GenericForm
                        formConfigs={FormsProductConfigs.formMemberAction}
                        stateData={data}
                        setStateData={setData}
                        useGlobalState={false}
                        onSubmit={(data) => {
                          const submitData = { ...data }
                          delete submitData.validations

                          putRequest(
                            `/form/${form.id}/member`,
                            setGlobalLoading,
                            (response) => {
                              setShowAddMemberModal(false)
                              updateObjectState(setForm, {
                                members_info: response.members_info,
                              })
                            },
                            submitData,
                            false,
                            false
                          )
                        }}
                      />

                      <p
                        className={"mt-1 pointer text-center"}
                        onClick={() => {
                          setData({
                            entitiesSelected: false,
                          })
                        }}
                      >
                        <FaChevronLeft className={"me-2"} /> Back
                      </p>
                    </div>
                  )}
                </div>
              }
            />
          </div>
        </ProductPermissionCheckView>
      }
    />
  )
}

function FormDetailsPage() {
  return <DashboardPageContainer PageComponent={FormDetails} />
}

export default FormDetailsPage
