import React, { useContext } from "react"
import { Button, Table } from "react-bootstrap"
import { FaEdit } from "react-icons/fa"
import Badge from "react-bootstrap/Badge"
import { AppContext } from "../../utils/components/AppContext"
import EmployeesTableDisplayModal from "./Employee/EmployeesTableDisplayModal"

export const PositionsTable = ({
  positions,
  canManage,
  onEditClick,
  getRequest,
}) => {
  const { contextState, updateContextState } = useContext(AppContext)
  const { labelsOverride } = contextState

  return (
    <Table responsive={true}>
      <thead>
        <tr className={"small fw-bold"}>
          <th>Level</th>
          <th>Title</th>
          <th>Description</th>
          <th>{labelsOverride.department || "Department"}</th>
          <th>Single Person</th>
          <th>Assigned Employees</th>
          <th>Branch</th>
          <th>Branch Location</th>
          <th>Active</th>
        </tr>
      </thead>
      <tbody>
        {positions.map((position, index) => (
          <tr
            key={position.id}
            className={"align-middle small"}
          >
            <td>{position.level.name}</td>
            <td>
              {position.title}{" "}
              {position.is_organization_head && (
                <>
                  <br />
                  <Badge
                    className={"text-break mt-2"}
                    bg="primary"
                  >
                    Head of Organization
                  </Badge>
                </>
              )}
              {position.is_department_head && (
                <>
                  <br />
                  <Badge
                    className={"text-break mt-2"}
                    bg="secondary"
                  >
                    Head of {labelsOverride.department || "Department"}
                  </Badge>
                </>
              )}
            </td>
            <td>{position.description}</td>
            <td>{position?.department?.name}</td>
            <td>{position.for_single_person ? "Yes" : "No"}</td>
            <td>
              <EmployeesTableDisplayModal
                getRequest={getRequest}
                modalTitle={`Employees Assigned to the "${position.title}" Position`}
                endpoint={`/people/employees/position/${position.id}`}
              >
                <h5 className={"text-center text-primary"}>
                  {position.employees_count}
                </h5>
              </EmployeesTableDisplayModal>
            </td>
            <td>{position?.branch?.name}</td>
            <td>{position?.branch_location?.name}</td>
            <td>{position.is_active ? "Yes" : "No"}</td>
            {canManage && (
              <td className={"d-flex justify-content-end"}>
                <Button
                  onClick={() => {
                    onEditClick(position, index)
                  }}
                  variant={"link"}
                  size={"small"}
                >
                  <FaEdit className={"me-2"} />
                  Edit
                </Button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
