import { useState, useEffect, useRef, useMemo, useContext } from "react"
import { CKEditor, useCKEditorCloud } from "@ckeditor/ckeditor5-react"

import "../../../styles/ckEditorStyles.css"
import { apiRequest } from "../../../utils/apiRequests"
import { AppContext } from "../../../utils/components/AppContext"
import { appConfigs } from "../../../configs"
import { LoadingView } from "../../../utils/components"
import { updateObjectState } from "../../../utils/functions"
import {
  dateFormats,
  formatDateInDayJs,
  getDayJsDateTime,
} from "../../../utils/dateTime"
import { Button } from "react-bootstrap"
import { FaSave } from "react-icons/fa"
import { DocumentPermissions } from "../../../enums/DocumentsEnums"
import { AppConstants } from "../../../utils/appConstants"

export default function DocumentEditor({
  doc,
  setDoc,
  getRequest,
  postRequest,
  tokenEndpoint,
}) {
  const editorPresenceRef = useRef(null)
  const editorContainerRef = useRef(null)
  const editorMenuBarRef = useRef(null)
  const editorToolbarRef = useRef(null)
  const editorOutlineRef = useRef(null)
  const editorRef = useRef(null)
  const editorAnnotationsRef = useRef(null)
  const editorRevisionHistoryRef = useRef(null)
  const editorRevisionHistoryEditorRef = useRef(null)
  const editorRevisionHistorySidebarRef = useRef(null)
  const [isLayoutReady, setIsLayoutReady] = useState(false)
  const cloud = useCKEditorCloud({ version: "44.2.1", premium: true })

  const { contextState, updateContextState } = useContext(AppContext)
  const { employee, darkMode } = contextState
  const [currentDateTime, setCurrentDateTime] = useState(
    formatDateInDayJs(getDayJsDateTime(), dateFormats.shortDateTime24Hr)
  )
  const [savedData, setSavedData] = useState(doc.data || "")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLayoutReady(true)

    return () => setIsLayoutReady(false)
  }, [])

  const { DecoupledEditor, editorConfig } = useMemo(() => {
    if (cloud.status !== "success" || !isLayoutReady) {
      return {}
    }

    const {
      DecoupledEditor,
      Plugin,
      ButtonView,
      Alignment,
      Autoformat,
      AutoImage,
      AutoLink,
      Autosave,
      BalloonToolbar,
      Bold,
      Bookmark,
      CloudServices,
      Code,
      Emoji,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      Heading,
      HorizontalLine,
      ImageBlock,
      ImageCaption,
      ImageEditing,
      ImageInline,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      ImageUtils,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      Mention,
      PageBreak,
      Paragraph,
      PasteFromOffice,
      RemoveFormat,
      SimpleUploadAdapter,
      SpecialCharacters,
      SpecialCharactersArrows,
      SpecialCharactersCurrency,
      SpecialCharactersEssentials,
      SpecialCharactersLatin,
      SpecialCharactersMathematical,
      SpecialCharactersText,
      Strikethrough,
      Subscript,
      Superscript,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      TodoList,
      Underline,
    } = cloud.CKEditor

    const {
      CaseChange,
      Comments,
      DocumentOutline,
      ExportPdf,
      // ExportWord,
      FormatPainter,
      ImportWord,
      MergeFields,
      MultiLevelList,
      Pagination,
      PasteFromOfficeEnhanced,
      PresenceList,
      RealTimeCollaborativeComments,
      RealTimeCollaborativeEditing,
      RealTimeCollaborativeRevisionHistory,
      RealTimeCollaborativeTrackChanges,
      RevisionHistory,
      SlashCommand,
      TableOfContents,
      TrackChanges,
      TrackChangesData,
      TrackChangesPreview,
    } = cloud.CKEditorPremiumFeatures

    /**
     * The `AnnotationsSidebarToggler` plugin adds an icon to the right side of the editor.
     *
     * It allows to toggle the right annotations bar visibility.
     */
    class AnnotationsSidebarToggler extends Plugin {
      static get requires() {
        return ["AnnotationsUIs"]
      }

      static get pluginName() {
        return "AnnotationsSidebarToggler"
      }

      init() {
        this.toggleButton = new ButtonView(this.editor.locale)

        const NON_COLLAPSE_ANNOTATION_ICON =
          '<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" transform="matrix(-1,0,0,1,0,0)"><path d="M11.463 5.187a.888.888 0 1 1 1.254 1.255L9.16 10l3.557 3.557a.888.888 0 1 1-1.254 1.255L7.26 10.61a.888.888 0 0 1 .16-1.382l4.043-4.042z"></path></svg>'
        const COLLAPSE_ANNOTATION_ICON =
          '<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" transform="matrix(1,0,0,1,0,0)"><path d="M11.463 5.187a.888.888 0 1 1 1.254 1.255L9.16 10l3.557 3.557a.888.888 0 1 1-1.254 1.255L7.26 10.61a.888.888 0 0 1 .16-1.382l4.043-4.042z"/></svg>'

        const annotationsUIsPlugin = this.editor.plugins.get("AnnotationsUIs")
        const annotationsContainer = this.editor.config.get("sidebar.container")
        const sidebarContainer = annotationsContainer.parentElement

        this.toggleButton.set({
          label: "Toggle annotations sidebar",
          tooltip: "Hide annotations sidebar",
          tooltipPosition: "se",
          icon: COLLAPSE_ANNOTATION_ICON,
        })

        this.toggleButton.on("execute", () => {
          // Toggle a CSS class on the annotations sidebar container to manage the visibility of the sidebar.
          annotationsContainer.classList.toggle("ck-hidden")

          // Change the look of the button to reflect the state of the annotations container.
          if (annotationsContainer.classList.contains("ck-hidden")) {
            this.toggleButton.icon = NON_COLLAPSE_ANNOTATION_ICON
            this.toggleButton.tooltip = "Show annotations sidebar"
            annotationsUIsPlugin.switchTo("inline")
          } else {
            this.toggleButton.icon = COLLAPSE_ANNOTATION_ICON
            this.toggleButton.tooltip = "Hide annotations sidebar"
            annotationsUIsPlugin.switchTo("wideSidebar")
          }

          // Keep the focus in the editor whenever the button is clicked.
          this.editor.editing.view.focus()
        })

        this.toggleButton.render()

        sidebarContainer.insertBefore(
          this.toggleButton.element,
          annotationsContainer
        )
      }

      destroy() {
        this.toggleButton.element.remove()

        return super.destroy()
      }
    }

    /**
     * The `DocumentOutlineToggler` plugin adds an icon to the left side of the editor.
     *
     * It allows to toggle document outline visibility.
     */
    class DocumentOutlineToggler extends Plugin {
      static get pluginName() {
        return "DocumentOutlineToggler"
      }

      init() {
        this.toggleButton = new ButtonView(this.editor.locale)

        const DOCUMENT_OUTLINE_ICON =
          '<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 9.5a.5.5 0 0 0 .5-.5v-.5A.5.5 0 0 0 5 8H3.5a.5.5 0 0 0-.5.5V9a.5.5 0 0 0 .5.5H5Z"/><path d="M5.5 12a.5.5 0 0 1-.5.5H3.5A.5.5 0 0 1 3 12v-.5a.5.5 0 0 1 .5-.5H5a.5.5 0 0 1 .5.5v.5Z"/><path d="M5 6.5a.5.5 0 0 0 .5-.5v-.5A.5.5 0 0 0 5 5H3.5a.5.5 0 0 0-.5.5V6a.5.5 0 0 0 .5.5H5Z"/><path clip-rule="evenodd" d="M2 19a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2Zm6-1.5h10a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H8v15Zm-1.5-15H2a.5.5 0 0 0-.5.5v14a.5.5 0 0 0 .5.5h4.5v-15Z"/></svg>'
        const COLLAPSE_OUTLINE_ICON =
          '<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11.463 5.187a.888.888 0 1 1 1.254 1.255L9.16 10l3.557 3.557a.888.888 0 1 1-1.254 1.255L7.26 10.61a.888.888 0 0 1 .16-1.382l4.043-4.042z"/></svg>'

        const documentOutlineContainer = this.editor.config.get(
          "documentOutline.container"
        )
        const sidebarContainer = documentOutlineContainer.parentElement

        this.toggleButton.set({
          label: "Toggle document outline",
          tooltip: "Hide document outline",
          tooltipPosition: "se",
          icon: COLLAPSE_OUTLINE_ICON,
        })

        this.toggleButton.on("execute", () => {
          // Toggle a CSS class on the document outline container to manage the visibility of the outline.
          documentOutlineContainer.classList.toggle("ck-hidden")

          // Change the look of the button to reflect the state of the document outline feature.
          if (documentOutlineContainer.classList.contains("ck-hidden")) {
            this.toggleButton.icon = DOCUMENT_OUTLINE_ICON
            this.toggleButton.tooltip = "Show document outline"
          } else {
            this.toggleButton.icon = COLLAPSE_OUTLINE_ICON
            this.toggleButton.tooltip = "Hide document outline"
          }

          // Keep the focus in the editor whenever the button is clicked.
          this.editor.editing.view.focus()
        })

        this.toggleButton.render()

        sidebarContainer.insertBefore(
          this.toggleButton.element,
          documentOutlineContainer
        )
      }

      destroy() {
        this.toggleButton.element.remove()

        return super.destroy()
      }
    }

    const plugins = [
      Alignment,
      Autoformat,
      AutoImage,
      AutoLink,
      Autosave,
      BalloonToolbar,
      Bold,
      Bookmark,
      CaseChange,
      CloudServices,
      Code,
      Comments,
      DocumentOutline,
      Emoji,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      FormatPainter,
      Heading,
      HorizontalLine,
      ImageBlock,
      ImageCaption,
      ImageEditing,
      ImageInline,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      ImageUtils,
      // ImportWord,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      Mention,
      MergeFields,
      MultiLevelList,
      PageBreak,
      Pagination,
      Paragraph,
      PasteFromOffice,
      PasteFromOfficeEnhanced,
      PresenceList,
      RealTimeCollaborativeComments,
      RealTimeCollaborativeEditing,
      RealTimeCollaborativeRevisionHistory,
      RealTimeCollaborativeTrackChanges,
      RemoveFormat,
      RevisionHistory,
      SimpleUploadAdapter,
      SlashCommand,
      SpecialCharacters,
      SpecialCharactersArrows,
      SpecialCharactersCurrency,
      SpecialCharactersEssentials,
      SpecialCharactersLatin,
      SpecialCharactersMathematical,
      SpecialCharactersText,
      Strikethrough,
      Subscript,
      Superscript,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableOfContents,
      TableProperties,
      TableToolbar,
      TextTransformation,
      TodoList,
      TrackChanges,
      TrackChangesData,
      TrackChangesPreview,
      Underline,
    ]

    const docPermissions = doc.permissions || []

    if (
      doc.is_published &&
      docPermissions.includes(DocumentPermissions.DOWNLOAD)
    ) {
      plugins.push(ExportPdf)
    }

    if (doc.is_draft) {
      plugins.push(ImportWord)
    }

    return {
      DecoupledEditor,
      editorConfig: {
        toolbar: {
          items: [
            "previousPage",
            "nextPage",
            "|",
            "revisionHistory",
            "trackChanges",
            "comment",
            "|",
            "insertMergeField",
            "previewMergeFields",
            "|",
            "formatPainter",
            "|",
            "heading",
            "|",
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "|",
            "link",
            "insertTable",
            "|",
            "alignment",
            "|",
            "bulletedList",
            "numberedList",
            "multiLevelList",
            "todoList",
            "outdent",
            "indent",
          ],
          shouldNotGroupWhenFull: false,
        },
        plugins: plugins,
        extraPlugins: [DocumentOutlineToggler, AnnotationsSidebarToggler],
        simpleUpload: {
          uploadUrl: `${appConfigs.apiHostname}/document/${doc.id}/image-upload`,
          withCredentials: false,
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              AppConstants.authTokenKey
            )}`,
            // "Content-Type":
            //   'multipart/form-data; boundary="----WebKitFormBoundary7MA4YWxkTrZu0gW"',
          },
        },
        balloonToolbar: [
          "comment",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "|",
          "bulletedList",
          "numberedList",
        ],
        cloudServices: {
          webSocketUrl: appConfigs.ckeWebsocketUrl,
          tokenUrl: async () => {
            const { success, response } = await apiRequest.get(tokenEndpoint)
            return response.token
          },
        },
        collaboration: {
          channelId: doc.id,
        },
        comments: {
          editorConfig: {
            extraPlugins: [Autoformat, Bold, Italic, List, Mention],
            mention: {
              feeds: [
                {
                  marker: "@",
                  feed: async (query) => {
                    const { success, response } = await apiRequest.get(
                      `/employee/global/search?search-term=${query}&minimal=true`
                    )

                    return response.employees.map((emp) => ({
                      // id: employee.id,
                      id: `@${emp.full_name} ${emp.work_email}`,
                      text: `@${emp.full_name}`,
                      name: `@${emp.full_name}`,
                    }))
                  },
                  minimumCharacters: 2,
                },
              ],
            },
          },
        },
        documentOutline: {
          container: editorOutlineRef.current,
        },
        exportPdf: {
          stylesheets: [
            /* This path should point to application stylesheets. */
            /* See: https://ckeditor.com/docs/ckeditor5/latest/features/converters/export-pdf.html */
            "../../../styles/ckEditorStyles.css",
            /* Export PDF needs access to stylesheets that style the content. */
            "https://cdn.ckeditor.com/ckeditor5/44.2.1/ckeditor5.css",
            "https://cdn.ckeditor.com/ckeditor5-premium-features/44.2.1/ckeditor5-premium-features.css",
          ],
          fileName: `${doc.name}.pdf`,
          converterOptions: {
            format: "A4",
            margin_top: "15mm",
            margin_bottom: "15mm",
            margin_right: "15mm",
            margin_left: "15mm",
            page_orientation: "portrait",

            // Footer
            footer_html: `<div class="styled-counter">${employee.full_name} | ${employee.work_email} | ${employee.employee_number} | Version ${doc.version_number} | <span class="date"></span> | <span class="pageNumber"></span> of <span class="totalPages"></span></div>`,
            header_and_footer_css:
              "#footer { background: hsl(0, 0%, 95%); } .styled-counter { font-size: 0.9em; color: hsl(0, 0%, 60%); text-align: center; padding: 0.5em; margin-bottom: 0.5em; }",
          },
          dataCallback: (editor) => {
            apiRequest.post(`/document/${doc.id}/log-activity`, {
              type: "Downloaded",
            })
            const data = editor.getData()
            return data
          },
        },
        exportWord: {
          stylesheets: [
            /* This path should point to application stylesheets. */
            /* See: https://ckeditor.com/docs/ckeditor5/latest/features/converters/export-word.html */
            "./App.css",
            /* Export Word needs access to stylesheets that style the content. */
            "https://cdn.ckeditor.com/ckeditor5/44.2.1/ckeditor5.css",
            "https://cdn.ckeditor.com/ckeditor5-premium-features/44.2.1/ckeditor5-premium-features.css",
          ],
          fileName: "export-word-demo.docx",
          converterOptions: {
            document: {
              orientation: "portrait",
              size: "A4",
              margins: {
                top: "20mm",
                bottom: "20mm",
                right: "12mm",
                left: "12mm",
              },
            },
          },
        },
        fontFamily: {
          supportAllValues: true,
        },
        fontSize: {
          options: [10, 12, 14, "default", 18, 20, 22],
          supportAllValues: true,
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        image: {
          toolbar: [
            "toggleImageCaption",
            "imageTextAlternative",
            "|",
            "imageStyle:inline",
            "imageStyle:wrapText",
            "imageStyle:breakText",
            "|",
            "resizeImage",
          ],
        },
        initialData: doc.data || "",
        licenseKey: appConfigs.ckeLicenseKey,
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: "https://",
          decorators: {
            toggleDownloadable: {
              mode: "manual",
              label: "Downloadable",
              attributes: {
                download: "file",
              },
            },
          },
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true,
          },
        },
        mention: {
          feeds: [
            {
              marker: "@",
              feed: [
                /* See: https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html */
              ],
            },
          ],
        },
        menuBar: {
          isVisible: true,
        },
        mergeFields: {
          /* Read more: https://ckeditor.com/docs/ckeditor5/latest/features/merge-fields.html#configuration */
        },
        pagination: {
          pageWidth: "21cm",
          pageHeight: "29.7cm",
          pageMargins: {
            top: "20mm",
            bottom: "20mm",
            right: "12mm",
            left: "12mm",
          },
        },
        placeholder: "Type or paste your content here!",
        presenceList: {
          container: editorPresenceRef.current,
        },
        revisionHistory: {
          editorContainer: editorContainerRef.current,
          viewerContainer: editorRevisionHistoryRef.current,
          viewerEditorElement: editorRevisionHistoryEditorRef.current,
          viewerSidebarContainer: editorRevisionHistorySidebarRef.current,
          resumeUnsavedRevision: true,
        },
        sidebar: {
          container: editorAnnotationsRef.current,
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties",
          ],
        },
      },
    }
  }, [cloud, isLayoutReady])

  return (
    <div className={"overflow-auto"}>
      <div
        className={`main-container ${darkMode ? "dark-modez" : ""} bg-light`}
        data-bs-theme={"light"}
      >
        <div
          className={"presence ps-2 pt-2"}
          ref={editorPresenceRef}
        ></div>
        <div
          className="editor-container editor-container_document-editor editor-container_include-outline editor-container_include-annotations editor-container_include-pagination"
          ref={editorContainerRef}
        >
          <div
            className="editor-container__menu-bar"
            ref={editorMenuBarRef}
          ></div>
          <div
            className="editor-container__toolbar"
            ref={editorToolbarRef}
          ></div>
          <div className="editor-container__editor-wrapper">
            <div className="editor-container__sidebar">
              <div ref={editorOutlineRef}></div>
            </div>
            <div className="editor-container__editor">
              <div ref={editorRef}>
                {DecoupledEditor && editorConfig && (
                  <CKEditor
                    onReady={(editor) => {
                      editorToolbarRef.current.appendChild(
                        editor.ui.view.toolbar.element
                      )
                      editorMenuBarRef.current.appendChild(
                        editor.ui.view.menuBarView.element
                      )
                    }}
                    // onAfterDestroy={() => {
                    //   Array.from(editorToolbarRef.current.children).forEach(
                    //     (child) => child.remove()
                    //   )
                    //   Array.from(editorMenuBarRef.current.children).forEach(
                    //     (child) => child.remove()
                    //   )
                    // }}
                    editor={DecoupledEditor}
                    config={editorConfig}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      // updateObjectState(setDoc, { data })
                    }}
                  />
                )}
              </div>
            </div>
            <div className="editor-container__sidebar">
              <div ref={editorAnnotationsRef}></div>
            </div>
          </div>
        </div>

        <div
          className="revision-history"
          ref={editorRevisionHistoryRef}
        >
          <div className="revision-history__wrapper">
            <div
              className="revision-history__editor"
              ref={editorRevisionHistoryEditorRef}
            ></div>
            <div
              className="revision-history__sidebar"
              ref={editorRevisionHistorySidebarRef}
            ></div>
          </div>
        </div>
      </div>

      {appConfigs.isDev && doc.is_draft && !doc.is_published && (
        <Button
          variant={"primary"}
          className={"position-absolute"}
          disabled={isLoading}
          style={{
            bottom: 30,
            right: 50,
          }}
          onClick={() => {
            getRequest(
              `/document/version/${doc.id}/save`,
              setIsLoading,
              () => {},
              true
            )
          }}
        >
          <FaSave className={"me-2"} />
          Save Changes
        </Button>
      )}
    </div>
  )
}
