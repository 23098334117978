export const FormProductFieldTypes = Object.freeze({
  TEXT: "Text",
  TEXT_AREA: "Text Area",
  NUMBER: "Number",
  SINGLE_CHOICE: "Single Choice",
  MULTIPLE_CHOICE: "Multiple Choice",
  IMAGE: "Image",
  PDF_FILE: "PDF File",
  DATE: "Date",
})

export const textFormFields = [
  FormProductFieldTypes.TEXT,
  FormProductFieldTypes.TEXT_AREA,
]

export const SelectFormFields = [
  FormProductFieldTypes.SINGLE_CHOICE,
  FormProductFieldTypes.MULTIPLE_CHOICE,
]
