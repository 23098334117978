import { FormFieldTypes } from "../../FormFields"
import {
  DocumentPermissions,
  DocumentRoles,
  CollabDocShareRoles,
} from "../../../enums/DocumentsEnums"

const DocumentFormConfig = {
  docInfo: [
    {
      valueKey: "name",
    },
    {
      valueKey: "description",
      type: FormFieldTypes.TEXT_AREA,
      isRequired: false,
    },
  ],
  uploadDocument: [
    {
      valueKey: "name",
      labelName: "Document Name",
    },
    {
      valueKey: "description",
      labelName: "Document Description",
      type: FormFieldTypes.TEXT_AREA,
    },
    {
      valueKey: "document_file",
      labelName: "Document File",
      type: FormFieldTypes.FILE,
      maxFileMb: 25,
    },
  ],
  permissions: (permissions) => {
    return [
      {
        valueKey: "permissions",
        optionsList: permissions || Object.values(DocumentPermissions),
        type: FormFieldTypes.MULTI_STRING_SELECT,
        info: "Select the permissions you want to assign to the people or entities selected",
        alertMsg: 'The "View" permission cannot be removed',
        stickyValues: [DocumentPermissions.VIEW],
      },
      {
        valueKey: "invite_message",
        type: FormFieldTypes.TEXT_AREA,
        isRequired: false,
        info: "Add a message to be sent to the people or entities selected",
      },
    ]
  },
  roles: (isTemplate = false) => {
    return [
      {
        valueKey: "role",
        optionsList: Object.values(DocumentRoles),
        type: FormFieldTypes.STRING_SELECT,
        info: "Select the role you want to assign to the people or entities selected",
      },
      {
        valueKey: "invite_message",
        type: FormFieldTypes.TEXT_AREA,
        isRequired: false,
        info: "Add a message to be sent to the people or entities selected",
      },
    ]
  },
  collabShareRoles: (isTemplate, docPermissions = []) => {
    const fields = [
      {
        valueKey: "role",
        optionsList: Object.values(CollabDocShareRoles),
        type: FormFieldTypes.STRING_SELECT,
        info: "Select the role you want to assign to the people or entities selected",
        disabled: isTemplate,
        alertMsg: isTemplate
          ? "Only the 'Reader' role can be assigned for templates"
          : null,
      },
      {
        valueKey: "invite_message",
        type: FormFieldTypes.TEXT_AREA,
        isRequired: false,
        info: "Add a message to be sent to the people or entities selected",
      },
    ]
    if (docPermissions.includes(DocumentPermissions.DOWNLOAD)) {
      // Add allow_download in 2nd position
      fields.splice(1, 0, {
        valueKey: "allow_download",
        type: FormFieldTypes.SWITCH,
        info: "Allow the user to download the document as PDF",
      })
    }

    return fields
  },
  folderSharing: [
    {
      valueKey: "role",
      optionsList: Object.values(DocumentRoles),
      type: FormFieldTypes.STRING_SELECT,
      info: "Select the role you want to assign to the people or entities selected",
      alertMsg:
        "This will apply to the CREATED documents in this folder and all sub-folders within it.",
    },
    {
      valueKey: "permissions",
      optionsList: Object.values(DocumentPermissions),
      type: FormFieldTypes.MULTI_STRING_SELECT,
      stickyValues: [DocumentPermissions.VIEW],
    },
    {
      valueKey: "invite_message",
      type: FormFieldTypes.TEXT_AREA,
      isRequired: false,
      info: "Add a message to be sent to the people or entities selected",
    },
  ],
}

export default DocumentFormConfig
