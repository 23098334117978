export class EmpPermissions {
  // Employees
  static VIEW_EMPLOYEES_WORK_INFO = "VIEW_EMPLOYEES_WORK_INFO"
  static VIEW_SHIFT_SCHEDULES = "VIEW_SHIFT_SCHEDULES"

  // HR
  static VIEW_EMPLOYEES_PERSONAL_INFO = "VIEW_EMPLOYEES_PERSONAL_INFO"
  static VIEW_EMPLOYEE_DATA = "VIEW_EMPLOYEE_DATA"
  static MANAGE_EMPLOYEE_DATA = "MANAGE_EMPLOYEE_DATA"
  static VIEW_TEAMS = "VIEW_TEAMS"
  static MANAGE_TEAMS = "MANAGE_TEAMS"
  static VIEW_POSITIONS = "VIEW_POSITIONS"
  static MANAGE_POSITIONS = "MANAGE_POSITIONS"
  static VIEW_LEVELS = "VIEW_LEVELS"
  static MANAGE_LEVELS = "MANAGE_LEVELS"
  static VIEW_COMPENSATION = "VIEW_COMPENSATION"
  static MANAGE_COMPENSATION = "MANAGE_COMPENSATION"
  static MANAGE_CLOCKIN_CLOCKOUT = "MANAGE_CLOCKIN_CLOCKOUT"
  static MANAGE_EMPLOYEE_ORGANIZATION_TRANSITION =
    "MANAGE_EMPLOYEE_ORGANIZATION_TRANSITION"

  static VIEW_TIME_OFF_POLICIES = "VIEW_TIME_OFF_POLICIES"
  static MANAGE_TIME_OFF_POLICIES = "MANAGE_TIME_OFF_POLICIES"

  // Attendance
  static VIEW_ATTENDANCE = "VIEW_ATTENDANCE"
  static MANAGE_ATTENDANCE = "MANAGE_ATTENDANCE"
  static MANAGE_SCHEDULES = "MANAGE_SCHEDULES"
  static MANAGE_SHIFTS = "MANAGE_SHIFTS"

  // Performance Management
  static VIEW_EMPLOYEE_PERFORMANCE = "VIEW_EMPLOYEE_PERFORMANCE"
  static MANAGE_PERFORMANCE_REVIEWS = "MANAGE_PERFORMANCE_REVIEWS"

  // Discipline Management
  static VIEW_DISCIPLINARY_RECORDS = "VIEW_DISCIPLINARY_RECORDS"
  static MANAGE_DISCIPLINARY_RECORDS = "MANAGE_DISCIPLINARY_RECORDS"

  // Asset Management
  static VIEW_ASSET_ASSIGNMENT = "VIEW_ASSET_ASSIGNMENT"
  static MANAGE_ASSET_ASSIGNMENT = "MANAGE_ASSET_ASSIGNMENT"
  static VIEW_ASSETS = "VIEW_ASSETS"
  static MANAGE_ASSETS = "MANAGE_ASSETS"

  // Stats
  static VIEW_EMPLOYEES_STATS = "VIEW_EMPLOYEES_STATS"
  static VIEW_PAYROLL_STATS = "VIEW_PAYROLL_STATS"
  static VIEW_EXPENSES_STATS = "VIEW_EXPENSES_STATS"
  static VIEW_ASSETS_STATS = "VIEW_ASSETS_STATS"
  static VIEW_RECRUITMENT_STATS = "VIEW_RECRUITMENT_STATS"
  static VIEW_SCHEDULES_SHIFTS_STATS = "VIEW_SCHEDULES_SHIFTS_STATS"
  static VIEW_EMPLOYEES_ACTIVITIES = "VIEW_EMPLOYEES_ACTIVITIES"

  // Expense Management
  static VIEW_EXPENSE_REQUESTS = "VIEW_EXPENSE_REQUESTS"
  static MANAGE_EXPENSE_REQUESTS = "MANAGE_EXPENSE_REQUESTS"
  static DISBURSE_FUNDS = "DISBURSE_FUNDS"
  static VIEW_BUDGETS = "VIEW_BUDGETS"
  static MANAGE_BUDGETS = "MANAGE_BUDGETS"

  // Accounting
  static VIEW_CASH_ACCOUNTS = "VIEW_CASH_ACCOUNTS"
  static MANAGE_CASH_ACCOUNTS = "MANAGE_CASH_ACCOUNTS"

  // Payroll
  static VIEW_PAYROLL = "VIEW_PAYROLL"
  static MANAGE_PAYROLL = "MANAGE_PAYROLL"
  static DOWNLOAD_PAYROLL_DATA = "DOWNLOAD_PAYROLL_DATA"

  // Recruiting
  static VIEW_JOB_POSTS = "VIEW_JOB_POSTS"
  static MANAGE_JOB_POSTS = "MANAGE_JOB_POSTS"
  static VIEW_JOB_APPLICATIONS = "VIEW_JOB_APPLICATIONS"
  static MANAGE_JOB_APPLICATIONS = "MANAGE_JOB_APPLICATIONS"

  // Employee Communication
  static MAKE_ANNOUNCEMENTS = "MAKE_ANNOUNCEMENTS"
  static MANAGE_ANNOUNCEMENTS = "MANAGE_ANNOUNCEMENTS"

  // Admin
  static VIEW_PERMISSIONS = "VIEW_PERMISSIONS"
  static MANAGE_PERMISSIONS = "MANAGE_PERMISSIONS"
  static VIEW_ORGANIZATION_INFO = "VIEW_ORGANIZATION_INFO"
  static MANAGE_ORGANIZATION_INFO = "MANAGE_ORGANIZATION_INFO"
  static VIEW_ORGANIZATION_SUBSCRIPTION_DETAILS =
    "VIEW_ORGANIZATION_SUBSCRIPTION_DETAILS"
  static MANAGE_ORGANIZATION_SUBSCRIPTION = "MANAGE_ORGANIZATION_SUBSCRIPTION"

  // Branches
  static VIEW_BRANCHES = "VIEW_BRANCHES"
  static MANAGE_BRANCHES = "MANAGE_BRANCHES"

  // Departments
  static VIEW_DEPARTMENTS = "VIEW_DEPARTMENTS"
  static MANAGE_DEPARTMENTS = "MANAGE_DEPARTMENTS"

  // Documents
  static DOCUMENT_TEMPLATE_PUBLISHER = "DOCUMENT_TEMPLATE_PUBLISHER"
  static DOCUMENT_TEMPLATE_GLOBAL_PUBLISHER =
    "DOCUMENT_TEMPLATE_GLOBAL_PUBLISHER"

  // Requests
  static REQUESTS_FORM_PUBLISHER = "REQUESTS_FORM_PUBLISHER"
  static REQUESTS_FORM_GLOBAL_PUBLISHER = "REQUESTS_FORM_GLOBAL_PUBLISHER"
  static REQUEST_FROM_PROXY_COMPLETER = "REQUEST_FROM_PROXY_COMPLETER"

  // Surveys
  static SURVEY_FORM_PUBLISHER = "SURVEY_FORM_PUBLISHER"
  static SURVEY_FORM_GLOBAL_PUBLISHER = "SURVEY_FORM_GLOBAL_PUBLISHER"
  static SURVEY_FROM_PROXY_COMPLETER = "SURVEY_FROM_PROXY_COMPLETER"
}
