import { FaFileAlt, FaFilePdf } from "react-icons/fa"
import { SiGooglesheets } from "react-icons/si"
import { BsFiletypePpt, BsFiletypePptx } from "react-icons/bs"
import React from "react"

export const DOC_EXT_ICONS = {
  doc: { icon: FaFileAlt, color: "text-primary" },
  docx: { icon: FaFileAlt, color: "text-primary" },
  xls: {
    icon: SiGooglesheets,
    color: "text-success",
  },
  xlsx: {
    icon: SiGooglesheets,
    color: "text-success",
  },
  pdf: {
    icon: FaFilePdf,
    color: "text-danger",
  },
  ppt: { icon: BsFiletypePpt, color: "text-warning" },
  pptx: { icon: BsFiletypePptx, color: "text-warning" },
}

export const DocIcon = ({ extension, size = 20 }) => {
  const Icon = DOC_EXT_ICONS[extension].icon

  return (
    <p className={"text-center my-auto"}>
      <Icon
        size={size}
        className={DOC_EXT_ICONS[extension].color}
      />
      <br />
      <small>{extension.toUpperCase()}</small>
    </p>
  )
}
