import React, { useContext, useEffect, useRef, useState } from "react"
import { formatDateStrings } from "../../../utils/dateTime"
import { Button, Form } from "react-bootstrap"
import { isEmpty, updateObjectState } from "../../../utils/functions"
import { EmployeeFormFieldsDefinitions } from "../Employee/EmployeeFormFieldsDefinitions"
import { FormFields, FormFieldTypes } from "../../FormFields"
import { AppContext } from "../../../utils/components/AppContext"

function GenericForm({
  onSubmit,
  stateData, // Don't pass this if you want to use the global state
  setStateData, // Don't pass this if you want to use the global state
  formConfigs,
  useFormData = false,
  useGlobalState = true,
  showCompleteButton = true,
  completeButtonTitle = "Submit",
  updateGlobalStateOnSubmit = true,
  updateGlobalStateOnStateChange = false,
  doDateFormatting = false,
}) {
  const { contextState, updateContextState } = useContext(AppContext)
  let defaultState
  if (useGlobalState) {
    defaultState = contextState.formData
  } else {
    defaultState = stateData || {}
  }
  const [formState, setFormState] = useState(
    doDateFormatting
      ? { ...formatDateStrings({ ...defaultState }) }
      : { ...defaultState }
  )
  const firstRender = useRef(true)

  useEffect(() => {
    if (updateGlobalStateOnStateChange) {
      updateContextState({
        formData: { ...formState },
      })
    }
  }, [formState])

  const handleSubmit = (event) => {
    event.preventDefault()
    if (useGlobalState && updateGlobalStateOnSubmit) {
      updateContextState({
        formData: { ...formState },
      })
    }
    let stateToUse = stateData && !useGlobalState ? stateData : formState
    stateToUse = { ...stateToUse }

    if (useFormData) {
      const formData = new FormData()
      for (let field of formConfigs) {
        const valueKey = field.valueKey
        if (stateToUse[valueKey] && stateToUse[`${valueKey}_url`]) {
          formData.append(field.valueKey, stateToUse[field.valueKey])
          // delete item
          delete stateToUse[field.valueKey]
          delete stateToUse[`${field.valueKey}_url`]
        }
      }
      formData.append("inputs", JSON.stringify(stateToUse))
      onSubmit(formData)
    } else {
      onSubmit(stateToUse)
    }
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {formConfigs.map((field, index) => {
          return (
            <FormFields
              key={index}
              {...field}
              state={stateData && !useGlobalState ? stateData : formState}
              setState={
                setStateData && !useGlobalState ? setStateData : setFormState
              }
            />
          )
        })}

        {showCompleteButton && (
          <Button
            variant={"primary"}
            type={"submit"}
            className={"w-100 mt-4 mb-5"}
            disabled={
              setStateData
                ? !stateData.validations
                  ? true
                  : Object.values(stateData.validations).includes(false)
                : !formState.validations
                ? true
                : Object.values(formState.validations).includes(false)
            }
          >
            {completeButtonTitle}
          </Button>
        )}
      </Form>
    </div>
  )
}

export default GenericForm
