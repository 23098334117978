import { FormFieldTypes } from "../../FormFields"

const FormsProductConfigs = {
  mainform: [
    {
      valueKey: "name",
    },
    {
      valueKey: "description",
      type: FormFieldTypes.TEXT_AREA,
      labelName: "Description and / or Instructions",
    },
    {
      valueKey: "limit_submissions",
      type: FormFieldTypes.SWITCH,
      info: "Limit the number of times an individual can submit this form",
    },
    {
      valueKey: "set_active_dates",
      type: FormFieldTypes.SWITCH,
      info: "Set the dates this form will be active for",
    },
  ],
  formField: [
    {
      valueKey: "name",
      labelName: "Field Name",
    },
    {
      valueKey: "info",
      info: "Additional information / instructions about this field",
      labelName: "Field Information",
      isRequired: false,
    },
    {
      valueKey: "is_required",
      type: FormFieldTypes.SWITCH,
      labelName: "Make Field Required?",
    },
  ],
  formMemberAction: [
    {
      valueKey: "action",
      type: FormFieldTypes.STRING_CHECKBOX,
      labelName: "Member Action",
      optionsList: ["View", "Complete"],
      info: "The action you want the member to take on the form",
    },
    {
      valueKey: "invite_message",
      type: FormFieldTypes.TEXT_AREA,
      isRequired: false,
    },
  ],
}

export default FormsProductConfigs
