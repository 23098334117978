import React, { useContext, useEffect, useState } from "react"
import { toTitleCase, useOffCanvas, useToast } from "../../../utils/functions"
import { AppContext } from "../../../utils/components/AppContext"
import { useNavigate, useParams } from "react-router-dom"
import { AddItemButton, LoadingView } from "../../../utils/components"
import DashboardPageContainer from "../DashboardPageContainer"
import PageMetaTags from "../Shared/PageMetaTags"
import { Alert, Card } from "react-bootstrap"
import AppBreadCrumb from "../Shared/AppBreadCrumb"
import { AppRoutes } from "../../../utils/appRoutes"
import FormSubmissionsTable from "./FormSubmissionsTable"
import GenericForm from "../../Forms/Shared/GenericForm"
import { apiRequest } from "../../../utils/apiRequests"
import { FormFieldTypes } from "../../FormFields"
import PagingItemsLoading from "../Shared/PagingItemsLoading"
import { OffCanvasContext } from "../../../utils/components/OffCanvasContext"

function FormMemberFormDetails({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
}) {
  const { contextState, updateContextState, setGlobalLoading } =
    useContext(AppContext)
  const { permissions } = contextState
  const { showOffCanvas } = useOffCanvas()
  const { closeOffCanvas } = useContext(OffCanvasContext)

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState({})
  const [formSubmissions, setFormSubmissions] = useState([])
  const [fields, setFields] = useState(null)
  const [putUrls, setPutUrls] = useState(null)
  const [pagingInfo, setPagingInfo] = useState({})
  const [getEndpoint, setGetEndpoint] = useState("")

  const { showToast } = useToast()

  const { id } = useParams()

  const isSubmission = window.location.pathname.includes("submit")
  const isApprover = window.location.pathname.includes("approver")

  useEffect(() => {
    let endpoint = `/form/${id}/approver`
    if (!isApprover) {
      const action = isSubmission ? "Complete" : "View"
      endpoint = `/form/${id}/member/form/${action}`
    }
    setGetEndpoint(endpoint)

    getRequest(endpoint, setIsLoading, (res) => {
      setForm(res.form)
      setFormSubmissions(res.submissions)
      setFields(res.fields)
      setPagingInfo(res.paging_info)
    })
  }, [])

  const uploadFile = async (endpoint, file) => {
    const { success } = await apiRequest.uploadFileToS3(endpoint, file)
    if (!success) {
      showToast({
        success: false,
        message: "File upload failed. Please try again.",
      })
      return false
    }
    return true
  }

  const parseAndUploadFiles = async (data) => {
    const formFilefields = [FormFieldTypes.PDF_FILE, FormFieldTypes.IMAGE]

    const fileFields = []

    for (const field of fields) {
      if (formFilefields.includes(field.type) && data[field.id]) {
        fileFields.push({ ...field, file_type: data[field.id].type })
      }
    }

    if (fileFields.length === 0) {
      return {}
    }

    const { success, response } = await apiRequest.post(
      `/form/${form.id}/member/form/put-urls`,
      {
        fields: fileFields,
      }
    )

    if (!success) {
      showToast({
        success: false,
        message: "Failed to upload files. Please try again.",
      })
      return null
    }

    const putUrls = response.put_urls

    for (const field of fileFields) {
      const file = data[field.id]
      const uploaded = await uploadFile(putUrls[field.id], file)
      if (!uploaded) {
        return null
      }
    }

    return response.put_url_keys
  }

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div>
          <PageMetaTags title={`Form`} />
          <AppBreadCrumb
            items={[
              {
                text: "Forms",
                path: `${AppRoutes.Requests.path}?activeTab=${
                  isApprover
                    ? "for_approval"
                    : isSubmission
                    ? "for_submission"
                    : "for_viewing"
                }`,
              },
              {
                text: form?.name || "Form",
              },
            ]}
          />

          {form.id && (
            <div>
              <Card className={"mb-4"}>
                <Card.Header className={"py-4"}>
                  <Card.Title>{form.name}</Card.Title>
                  <Card.Subtitle
                    as={"p"}
                    className={"mt-2"}
                  >
                    {form.description}
                  </Card.Subtitle>
                </Card.Header>
              </Card>

              <h3 className={"mb-2 mt-4"}>
                {isSubmission ? "Your" : ""} Submissions
              </h3>

              {fields && isSubmission && (
                <div>
                  {!form.is_published && (
                    <div className={"d-flex justify-content-end"}>
                      <Alert variant={"warning"}>
                        You cannot make a submission because this form is not
                        published.
                      </Alert>
                    </div>
                  )}

                  {form.is_published && (
                    <div className={"mb-4"}>
                      <AddItemButton
                        title={"New Submission"}
                        onClick={() => {
                          updateContextState({
                            formData: {},
                          })
                          showOffCanvas({
                            title: `New Submission`,
                            subtitle: `Submit your response to the "${form.name}" form`,
                            widthPercentage: 40,
                            component: (
                              <div>
                                <GenericForm
                                  formConfigs={fields}
                                  onSubmit={async (submitData) => {
                                    setGlobalLoading(true)

                                    const putUrlKeys =
                                      await parseAndUploadFiles(submitData)

                                    if (putUrlKeys === null) {
                                      setGlobalLoading(false)
                                      return
                                    }

                                    const fieldIds = fields.map(
                                      (field) => field.id
                                    )

                                    // filter out data whose key is not in fieldIds from submitData. submitData is an object
                                    const updatedData = {}

                                    for (const id of fieldIds) {
                                      updatedData[id] = submitData[id]
                                    }

                                    putRequest(
                                      `/form/${form.id}/member/form/submit`,
                                      setGlobalLoading,
                                      (res) => {
                                        setFormSubmissions([
                                          res.submission,
                                          ...formSubmissions,
                                        ])
                                        closeOffCanvas()
                                      },
                                      {
                                        ...updatedData,
                                        ...putUrlKeys,
                                      },
                                      false,
                                      false
                                    )
                                  }}
                                />
                              </div>
                            ),
                          })
                        }}
                      />
                    </div>
                  )}
                </div>
              )}

              <FormSubmissionsTable
                submissions={formSubmissions}
                fields={fields}
                getRequest={getRequest}
                postRequest={postRequest}
                forApproval={isApprover}
                setSubmissions={setFormSubmissions}
              />

              <PagingItemsLoading
                endpoint={getEndpoint}
                pagingInfo={pagingInfo}
                getRequest={getRequest}
                onLoadComplete={(res) => {
                  setFormSubmissions([...formSubmissions, ...res.submissions])
                  setPagingInfo(res.paging_info)
                }}
              />
            </div>
          )}
        </div>
      }
    />
  )
}

function FormMemberFormDetailsPage() {
  return <DashboardPageContainer PageComponent={FormMemberFormDetails} />
}

export default FormMemberFormDetailsPage
