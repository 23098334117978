import React, { useContext, useEffect, useState } from "react"
import {
  AddItemButton,
  AppOffCanvasLocal,
  NoInfoCard,
} from "../../../utils/components"
import { useAppModal, useOffCanvas } from "../../../utils/functions"
import { AppContext } from "../../../utils/components/AppContext"
import { Alert, Button, Card, Col } from "react-bootstrap"
import FormFieldBuilder from "../../Forms/FormsProduct/FormFieldBuilder"
import { FormFields } from "../../FormFields"
import {
  FaChevronDown,
  FaChevronRight,
  FaChevronUp,
  FaEdit,
} from "react-icons/fa"
import { FaTrashCan } from "react-icons/fa6"

function FormFieldContainer({
  form,
  fields,
  setFields,
  canManage,
  putRequest,
  patchRequest,
  deleteRequest,
  loaderCallback,
}) {
  const { showOffCanvas } = useOffCanvas()
  const { updateContextState, setGlobalLoading } = useContext(AppContext)
  const { showAppModal } = useAppModal()

  const [data, setData] = useState({})
  const [showFieldOffCanvas, setShowFieldOffCanvas] = useState(false)
  const [showEditOffCanvas, setShowEditOffCanvas] = useState(false)
  const [editFieldData, setEditFieldData] = useState({})
  const getFieldsIds = () => {
    return fields.map((field) => field.id)
  }

  const [fieldsIds, setFieldsIds] = useState([])

  useEffect(() => {
    setFieldsIds(getFieldsIds())
  }, [])

  useEffect(() => {
    if (!form?.id) return
    const newFieldsIds = getFieldsIds()
    // newFieldsIds to objects with index + 1 as value
    const newFieldsIdsObjects = {}

    newFieldsIds.map((id, index) => {
      newFieldsIdsObjects[id] = index + 1
    })

    if (
      fieldsIds.length === newFieldsIds.length &&
      !fieldsIds.every((value, index) => value === newFieldsIds[index])
    ) {
      patchRequest(
        `/form/${form.id}/fields/order`,
        null,
        (res) => {
          setFieldsIds(newFieldsIds)
          setFields(res.form_fields)
        },
        newFieldsIdsObjects,
        false,
        false,
        false
      )
    }
  }, [fields])

  return (
    <div>
      {canManage && (
        <div className={"mb-4"}>
          <AddItemButton
            title={"New Field"}
            onClick={() => {
              setShowFieldOffCanvas(true)
            }}
          />
        </div>
      )}

      <Card>
        <Card.Header className={"py-4"}>
          <Card.Title>Form Fields</Card.Title>
          <Card.Subtitle
            as={"p"}
            className={"mt-2"}
          >
            Fields are displayed the way they will appear on the form when
            people fill it out
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          {fields.length === 0 && (
            <NoInfoCard text={"No fields have been added to this form"} />
          )}

          <Col
            lg={8}
            className={"offset-lg-2 mb-5"}
          >
            {fields.map((field, index) => {
              return (
                <Card
                  key={field.valueKey}
                  className={"mb-3"}
                >
                  {form.can_edit && (
                    <Card.Header
                      className={
                        "d-flex justify-content-between align-middle py-3"
                      }
                    >
                      <h2 className={"align-middle my-auto"}>{field.order}.</h2>
                      <div className={"d-flex gap-2"}>
                        <Button
                          variant={"outline-primary"}
                          disabled={index === 0 || fields.length === 1}
                          onClick={() => {
                            if (index > 0) {
                              const newFields = [...fields]
                              newFields.splice(
                                index - 1,
                                0,
                                newFields.splice(index, 1)[0]
                              )
                              setFields(newFields)
                            }
                          }}
                        >
                          <FaChevronUp />
                        </Button>

                        <Button
                          variant={"outline-primary"}
                          disabled={
                            index === fields.length - 1 || fields.length === 1
                          }
                          onClick={() => {
                            if (index < fields.length - 1) {
                              const newFields = [...fields]
                              newFields.splice(
                                index + 1,
                                0,
                                newFields.splice(index, 1)[0]
                              )
                              setFields(newFields)
                            }
                          }}
                        >
                          <FaChevronDown />
                        </Button>

                        <Button
                          variant={"outline-primary"}
                          onClick={() => {
                            setEditFieldData({
                              ...field.creation_data,
                              id: field.id,
                            })
                            setShowEditOffCanvas(true)
                          }}
                        >
                          <FaEdit />
                        </Button>
                        <Button
                          variant={"outline-danger"}
                          onClick={() => {
                            showAppModal({
                              title: `Delete Field`,
                              component: (
                                <Alert variant={"danger"}>
                                  <p>
                                    Are you sure you want to delete this field?
                                  </p>
                                </Alert>
                              ),
                              truthyFunction: () => {
                                deleteRequest(
                                  `/form/${form.id}/field/${field.id}`,
                                  setGlobalLoading,
                                  (res) => {
                                    setFields(
                                      fields.filter((f) => f.id !== field.id)
                                    )
                                  }
                                )
                              },
                            })
                          }}
                        >
                          <FaTrashCan />
                        </Button>
                      </div>
                    </Card.Header>
                  )}
                  <Card.Body>
                    <FormFields
                      state={data}
                      setState={setData}
                      {...field}
                    />
                  </Card.Body>
                </Card>
              )
            })}
          </Col>
        </Card.Body>
      </Card>

      <AppOffCanvasLocal
        title={"New Field"}
        subtitle={"Add a new field to your form"}
        showOffCanvas={showFieldOffCanvas}
        setShowOffCanvas={setShowFieldOffCanvas}
        backdropClose={true}
        component={
          <div>
            <FormFieldBuilder
              onSubmit={(formData) => {
                putRequest(
                  `/form/${form.id}/field`,
                  setGlobalLoading,
                  (res) => {
                    setShowFieldOffCanvas(false)
                    setFields([...fields, res.field])
                  },
                  formData,
                  false,
                  false
                )
              }}
            />
          </div>
        }
      />

      {editFieldData.id && (
        <AppOffCanvasLocal
          title={"Edit Field"}
          showOffCanvas={showEditOffCanvas}
          setShowOffCanvas={setShowEditOffCanvas}
          backdropClose={true}
          component={
            <div>
              <FormFieldBuilder
                state={editFieldData}
                onSubmit={(formData) => {
                  patchRequest(
                    `/form/${form.id}/field/${editFieldData.id}`,
                    setGlobalLoading,
                    (res) => {
                      setShowEditOffCanvas(false)
                      setFields(
                        fields.map((f) => {
                          if (f.id === editFieldData.id) {
                            return res.field
                          } else {
                            return f
                          }
                        })
                      )
                    },
                    formData,
                    false,
                    false
                  )
                }}
              />
            </div>
          }
        />
      )}
    </div>
  )
}

export default FormFieldContainer
