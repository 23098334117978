import React, { useState, useRef, useEffect } from "react"
import { Document, Page } from "react-pdf"
import { Button } from "react-bootstrap"
import {
  FaArrowLeft,
  FaArrowRight,
  FaSearchMinus,
  FaSearchPlus,
} from "react-icons/fa"

function DocPDFViewer({ doc }) {
  const [numPages, setNumPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [scale, setScale] = useState(1.0)

  // A ref to the scrolling container
  const containerRef = useRef(null)
  // Refs for each page wrapper
  const pageRefs = useRef([])

  /**
   * Called by react-pdf once the PDF is loaded
   */
  function onDocumentLoadSuccess(pdf) {
    setNumPages(pdf.numPages)
  }

  /**
   * Use an IntersectionObserver to watch which page is currently intersecting
   */
  useEffect(() => {
    if (!containerRef.current || !numPages) return

    // Create observer with ~50% threshold
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const pageIndex = parseInt(entry.target.dataset.index, 10)
            // We use (index + 1) because pages are 1-based
            setCurrentPage(pageIndex + 1)
          }
        })
      },
      {
        root: containerRef.current, // The scrollable container
        threshold: 0.6, // Consider page "current" when ~60% of it is visible
      }
    )

    // Observe each page container
    pageRefs.current.forEach((el) => {
      if (el) observer.observe(el)
    })

    // Cleanup on unmount or if numPages changes
    return () => {
      observer.disconnect()
    }
  }, [numPages])

  // Zoom controls
  const zoomIn = () => setScale((prev) => prev + 0.25)
  const zoomOut = () => setScale((prev) => Math.max(0.25, prev - 0.25))

  // (Optional) Buttons to scroll to next or prev page
  const goToPrevPage = () => {
    const prev = Math.max(currentPage - 1, 1)
    pageRefs.current[prev - 1]?.scrollIntoView({ behavior: "smooth" })
  }
  const goToNextPage = () => {
    const next = Math.min(currentPage + 1, numPages)
    pageRefs.current[next - 1]?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div
      style={{
        height: "90vh",
        overflow: "auto",
        position: "relative",
      }}
      ref={containerRef}
    >
      {doc?.local_file_url && (
        <Document
          file={doc.local_file_url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(err) => console.error("PDF load error:", err)}
        >
          {Array.from({ length: numPages }, (_, i) => (
            <div
              key={`page-wrapper-${i}`}
              ref={(el) => (pageRefs.current[i] = el)}
              data-index={i}
              style={{
                margin: "0 auto",
                padding: "20px 0",
                width: "fit-content",
              }}
            >
              <Page
                pageNumber={i + 1}
                scale={scale}
              />
            </div>
          ))}
        </Document>
      )}

      {/* Floating toolbar / bottom bar */}
      {numPages > 0 && (
        <div
          style={{
            position: "fixed",
            bottom: 20,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 9999,
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              background: "rgba(0,0,0,0.8)",
              color: "#fff",
              padding: "8px 12px",
              borderRadius: 20,
            }}
          >
            <Button
              size="sm"
              variant="outline-light"
              disabled={currentPage <= 1}
              onClick={goToPrevPage}
            >
              <FaArrowLeft />
            </Button>
            <span>
              Page {currentPage} / {numPages}
            </span>
            <Button
              size="sm"
              variant="outline-light"
              disabled={currentPage >= numPages}
              onClick={goToNextPage}
            >
              <FaArrowRight />
            </Button>

            {/* Zoom Buttons */}
            <Button
              variant="outline-light"
              size="sm"
              onClick={zoomOut}
            >
              <FaSearchMinus />
            </Button>
            <span
              className={"pointer"}
              onClick={() => {
                setScale(1)
              }}
            >
              {(scale * 100).toFixed(0)}%
            </span>
            <Button
              variant="outline-light"
              size="sm"
              onClick={zoomIn}
            >
              <FaSearchPlus />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default DocPDFViewer
