import React, { useContext } from "react"
import { FaEdit, FaFileAlt, FaFilePdf } from "react-icons/fa"
import { SiGooglesheets } from "react-icons/si"
import { BsFiletypePpt, BsFiletypePptx } from "react-icons/bs"
import { Alert, Button, OverlayTrigger, Table } from "react-bootstrap"
import { dateFormats, formatDateInDayJs } from "../../../utils/dateTime"
import { NoInfoCard, TextWithLineBreaks } from "../../../utils/components"
import { AppRoutes } from "../../../utils/appRoutes"
import { useNavigate } from "react-router-dom"
import { DOC_EXT_ICONS, DocIcon } from "./DocumentsUtils"
import { AppContext } from "../../../utils/components/AppContext"
import { EmployeePopover } from "../EmployeeCards"
import ThreeDotMenu from "../Shared/ThreeDotMenu"
import { SharedFormConfigs } from "../../Forms/FormsConfigs/SharedFormConfigs"
import { useAppModal, useOffCanvas } from "../../../utils/functions"
import GenericForm from "../../Forms/Shared/GenericForm"
import { BiTransfer } from "react-icons/bi"
import { FaTrashCan } from "react-icons/fa6"
import FolderTree from "./FolderTree"

function DocumentsTableList({
  documents,
  setDocuments,
  onRowClick,
  deleteRequest,
  patchRequest,
}) {
  const { contextState, updateContextState, setGlobalLoading } =
    useContext(AppContext)
  const { employee } = contextState
  const { showAppModal } = useAppModal()
  const { showOffCanvas } = useOffCanvas()

  if (documents.length === 0) {
    return <NoInfoCard text={"There are no documents to display"} />
  } else {
    return (
      <div>
        <Table
          className={"table-striped"}
          responsive={true}
        >
          <thead>
            <tr>
              <th
                style={{
                  maxWidth: "50px",
                }}
              ></th>
              <th>Name</th>
              <th>Version</th>
              <th>Owner</th>
              <th>Created On</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc) => {
              const ext = doc.format.toLowerCase()

              return (
                <tr
                  key={doc.id}
                  className={"align-middle"}
                >
                  <td
                    className={
                      "align-items-center text-center align-middle fs-6"
                    }
                  >
                    <DocIcon
                      extension={doc.extension}
                      size={20}
                    />
                  </td>
                  <td
                    className={"pointer"}
                    onClick={() => {
                      onRowClick(doc)
                    }}
                  >
                    {doc.name}
                    <br />
                    <span className={"flex-shrink-1 text-muted"}>
                      <TextWithLineBreaks text={doc.description} />
                    </span>
                  </td>
                  <td>{doc.version_number || "None"}</td>
                  <td
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    {doc.author_employee.id !== employee.id ? (
                      <div>
                        <OverlayTrigger
                          trigger="click"
                          placement="top"
                          overlay={
                            <EmployeePopover employee={doc.author_employee} />
                          }
                          rootClose
                        >
                          <Button
                            variant="link"
                            className=""
                          >
                            {doc.author_employee.full_name}
                          </Button>
                        </OverlayTrigger>
                      </div>
                    ) : (
                      "You"
                    )}
                  </td>
                  <td>
                    {formatDateInDayJs(
                      doc.timestamp,
                      dateFormats.shortDayShortMonthFullDayYearTime12Hr
                    )}
                  </td>

                  <td className={"text-end"}>
                    {doc.is_owner && (
                      <ThreeDotMenu
                        items={[
                          {
                            text: (
                              <span>
                                <FaEdit className={"me-2"} /> Rename
                              </span>
                            ),
                            disabled: !doc.can_edit,
                            onClick: () => {
                              updateContextState({
                                formData: { ...doc },
                              })

                              showOffCanvas({
                                title: "Rename Document",
                                subtitle:
                                  "Edit the name and description of the document",
                                component: (
                                  <GenericForm
                                    formConfigs={SharedFormConfigs.nameAndDescription()}
                                    onSubmit={(data) => {
                                      patchRequest(
                                        `/document/version/${doc.id}`,
                                        setGlobalLoading,
                                        (res) => {
                                          setDocuments(
                                            documents.map((f) => {
                                              if (f.id === doc.id) {
                                                return res.doc
                                              } else {
                                                return f
                                              }
                                            })
                                          )
                                        },
                                        data
                                      )
                                    }}
                                  />
                                ),
                              })
                            },
                          },
                          {
                            text: (
                              <span>
                                <BiTransfer className={"me-2"} /> Move
                              </span>
                            ),
                            onClick: () => {
                              showOffCanvas({
                                title: "Move Document",
                                subtitle:
                                  "Select a folder to move the document to",
                                backdropClose: true,
                                component: (
                                  <div>
                                    <Alert
                                      variant={"info"}
                                      className={"mb-4"}
                                    >
                                      Double click a folder to move the document
                                    </Alert>

                                    <FolderTree
                                      onDoubleClick={(folder) => {
                                        showAppModal({
                                          title: `Move "${doc.name}" to "${folder.name}" Folder?`,
                                          component: (
                                            <Alert variant={"warning"}>
                                              Are you sure you want to proceed?
                                            </Alert>
                                          ),
                                          truthyFunction: () => {
                                            patchRequest(
                                              `/document/${doc.id}/${doc.fe_type}/move/folder/${folder.id}`,
                                              setGlobalLoading,
                                              (res) => {
                                                setDocuments(
                                                  documents.filter(
                                                    (f) => f.id !== doc.id
                                                  )
                                                )
                                              },
                                              { folder_id: folder.id }
                                            )
                                          },
                                        })
                                      }}
                                      isTemplate={doc.is_template}
                                      noActionIds={[doc.folder_id]}
                                    />
                                  </div>
                                ),
                              })
                            },
                          },
                          {
                            text: (
                              <span className={"text-danger"}>
                                <FaTrashCan className={"me-2"} /> Delete
                              </span>
                            ),
                            disabled: !doc.can_delete,
                            onClick: () => {
                              showAppModal({
                                title: `Delete "${doc.name}"?`,
                                component: (
                                  <Alert variant={"danger"}>
                                    Are you sure? This cannot be undone.
                                  </Alert>
                                ),
                                truthyFunction: () => {
                                  deleteRequest(
                                    `/document/version/${doc.id}`,
                                    setGlobalLoading,
                                    () => {
                                      setDocuments(
                                        documents.filter((f) => f.id !== doc.id)
                                      )
                                    }
                                  )
                                },
                              })
                            },
                          },
                        ]}
                      />
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

export default DocumentsTableList
