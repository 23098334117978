import React, { useContext, useEffect, useState } from "react"
import {
  AddItemButton,
  AppOffCanvasLocal,
  LoadingView,
} from "../../../utils/components"
import { useOffCanvas } from "../../../utils/functions"
import { AppContext } from "../../../utils/components/AppContext"
import { Card } from "react-bootstrap"
import FormCreationForm from "../../Forms/FormsProduct/FormCreationForm"
import FormsProductFormsTable from "../FormsProduct/FormsProductFormsTable"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "../../../utils/appRoutes"

function RequestFormsContainer({
  forms,
  setForms,
  title,
  subTitle,
  approvalWorkflows,
  putRequest,
  patchRequest,
  postRequest,
  deleteRequest,
  loaderCallback,
  showAddForm = false,
}) {
  const { showOffCanvas } = useOffCanvas()
  const { updateContextState, setGlobalLoading } = useContext(AppContext)
  const [showAppOffCanvas, setShowAppOffCanvas] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const [workflowsWithSteps, setWorkflowsWithSteps] = useState([])

  useEffect(() => {
    if (approvalWorkflows) {
      let withSteps = []
      approvalWorkflows.map((workflow) => {
        if (workflow.steps.length > 0) {
          withSteps.push(workflow)
        }
      })
      setWorkflowsWithSteps(withSteps)
    }
  }, [approvalWorkflows])

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div>
          {showAddForm && (
            <div className={"mb-4"}>
              <AddItemButton
                title={"New Form"}
                onClick={() => {
                  setShowAppOffCanvas(true)
                }}
              />
            </div>
          )}

          <Card className={"mb-4"}>
            <Card.Header className={"py-4"}>
              <Card.Title>{title}</Card.Title>
              <Card.Subtitle
                as={"p"}
                className={"mt-2"}
              >
                {subTitle}
              </Card.Subtitle>
            </Card.Header>
          </Card>

          <FormsProductFormsTable
            forms={forms}
            setForms={setForms}
            deleteRequest={deleteRequest}
            patchRequest={patchRequest}
            workflowsWithSteps={workflowsWithSteps}
            onRowClick={(f) => {
              navigate(`${AppRoutes.FormDetails.paramLessPath}/${f.id}`)
            }}
          />

          {showAddForm && (
            <AppOffCanvasLocal
              title={"New Request Form"}
              subTitle={"Add a new request form for employees to submit"}
              showOffCanvas={showAppOffCanvas}
              setShowOffCanvas={setShowAppOffCanvas}
              backdropClose={true}
              component={
                <div>
                  <FormCreationForm
                    requireApprval={true}
                    approvalWorkflows={workflowsWithSteps}
                    onSubmit={(formData) => {
                      formData.type = "REQUEST"
                      putRequest(
                        `/form`,
                        setGlobalLoading,
                        (res) => {
                          setShowAppOffCanvas(false)
                          setForms([res.form, ...forms])
                        },
                        formData,
                        false,
                        false
                      )
                    }}
                  />
                </div>
              }
            />
          )}
        </div>
      }
    />
  )
}

export default RequestFormsContainer
