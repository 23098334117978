import React, { useContext } from "react"
import { Card, Table } from "react-bootstrap"
import { formatDate, formDate } from "../../utils/dateTime"
import { AppConstants } from "../../utils/appConstants"
import { AppContext } from "../../utils/components/AppContext"

function EmployeeTable({
  employees,
  onClick,
  pagingInfo,
  extraColumns,
  minimal = false,
  noEmployeesMsg = "No employees to display",
}) {
  /* extraColumns is an array of objects with the following structure:
    {
      columnName: "Column Name",
      key: "keyName", used to index into the employee object
      renderComponent: (employee) => { return <p>{employee.name}</p> }
    }
    if renderComponent is not provided, ignore key
   */

  const { contextState, updateContextState } = useContext(AppContext)
  const { labelsOverride } = contextState
  const startingIndex =
    Object.keys(pagingInfo || {}).length > 0
      ? (pagingInfo.current_page - 1) * pagingInfo.per_page + 1
      : 1

  extraColumns = extraColumns || []

  return (
    <div>
      {employees.length > 0 && (
        <Table
          striped
          bordered
          hover
          responsive
        >
          <thead>
            <tr className={"fs-6"}>
              <th></th>
              <th></th>
              <th>Name</th>
              <th>{labelsOverride.employee_number || "Employee Number"}</th>
              {!minimal && <th>Work Phone</th>}
              {!minimal && <th>Work Email</th>}
              <th>Position</th>
              {!minimal && <th>{labelsOverride.start_date || "Start Date"}</th>}
              {extraColumns &&
                extraColumns.map((column, index) => (
                  <th key={index}>{column.columnName}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {employees.map((employee, index) => {
              return (
                <tr
                  key={`${employee.id}-${index}`}
                  className={`align-middle small`}
                >
                  <td>{pagingInfo ? index + startingIndex : index + 1}</td>
                  <td className={"text-center"}>
                    <img
                      src={
                        employee.profile_image ||
                        AppConstants.defaultProfileImage
                      }
                      alt={employee.full_name}
                      width="30"
                      height="30"
                      className={"rounded-circle"}
                    />
                  </td>
                  <td
                    className={`${
                      onClick ? "pointer text-decoration-underline" : ""
                    } `}
                    onClick={onClick ? () => onClick(employee) : null}
                  >
                    {employee.full_name}
                  </td>
                  <td>{employee.employee_number}</td>
                  {!minimal && <td>{employee.work_phone}</td>}
                  {!minimal && <td>{employee.work_email}</td>}
                  <td>{employee?.position?.title || "Not Assigned"}</td>
                  {!minimal && <td>{formatDate(employee.start_date)}</td>}
                  {extraColumns &&
                    extraColumns.map((column, index) => (
                      <td key={index}>
                        {column.renderComponent
                          ? column.renderComponent(employee)
                          : employee[column.key]}
                      </td>
                    ))}
                </tr>
              )
            })}
          </tbody>
        </Table>
      )}
      {employees.length === 0 && (
        <Card
          body={true}
          className={"text-center py-4"}
        >
          <Card.Text>{noEmployeesMsg}</Card.Text>
        </Card>
      )}
    </div>
  )
}

export default EmployeeTable
