import React, { useContext, useEffect, useState } from "react"
import {
  updateObjectState,
  useAppModal,
  useOffCanvas,
} from "../../../utils/functions"
import { AppContext } from "../../../utils/components/AppContext"
import { useNavigate, useParams } from "react-router-dom"
import {
  AddItemButton,
  AppModalLocal,
  AppOffCanvasLocal,
  LoadingView,
} from "../../../utils/components"
import DashboardPageContainer from "../DashboardPageContainer"
import FoldersList from "./FoldersTable"
import AppBreadCrumb from "../Shared/AppBreadCrumb"
import { AppRoutes } from "../../../utils/appRoutes"
import { Alert, Button, Card } from "react-bootstrap"
import DocumentsContainer from "./DocumentsContainer"
import OrgEntitySelection from "../../Forms/Shared/OrgEntitySelection"
import {
  CollabDocShareRoles,
  DocumentPermissions,
} from "../../../enums/DocumentsEnums"
import GenericForm from "../../Forms/Shared/GenericForm"
import DocumentFormConfig from "../../Forms/FormsConfigs/DocumentFormConfig"
import { FaChevronLeft } from "react-icons/fa"
import DocMembersTable from "./DocMembersTable"
import { Products } from "../../../enums/OrgEnums"
import ProductPermissionCheckView from "../ProductPermissionCheckView"

function DocumentsFolder({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
}) {
  const { contextState, updateContextState, setGlobalLoading } =
    useContext(AppContext)
  const { permissions, employee } = contextState
  const { showOffCanvas } = useOffCanvas()
  const { showAppModal, closeModal } = useAppModal()

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [folder, setFolder] = useState({ permissions: [] })
  const [subfolders, setSubfolders] = useState([])
  const [showShareOffCanvas, setShowShareOffCanvas] = useState(false)
  const [showAddCollaboratorModal, setShowAddCollaboratorModal] =
    useState(false)
  const [data, setData] = useState({})

  const { id } = useParams()

  useEffect(() => {
    getRequest(`/document/folder/${id}`, setIsLoading, (resp) => {
      setFolder(resp.folder)
      setSubfolders(resp.sub_folders)
    })
  }, [id])

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <ProductPermissionCheckView product={Products.DOCUMENTS_MANAGEMENT}>
          <div>
            {folder.id && (
              <div>
                <AppBreadCrumb
                  items={[
                    {
                      text: "Documents",
                      path: AppRoutes.Documents.path,
                    },
                    ...folder.path,
                    { text: folder.name || "Folder" },
                  ]}
                />

                <DocumentsContainer
                  getRequest={getRequest}
                  putRequest={putRequest}
                  deleteRequest={deleteRequest}
                  patchRequest={patchRequest}
                  onMembersClick={() => {
                    setShowShareOffCanvas(true)
                  }}
                  showAddButton={folder.permissions.includes(
                    DocumentPermissions.EDIT
                  )}
                  title={`${folder.name}`}
                  description={folder.description || "No Description"}
                  publishedEndpoint={`/folder/${folder.id}/items/published`}
                  draftsEndpoint={`/folder/${folder.id}/items/drafts`}
                  folderId={folder.id}
                  folderType={folder.type}
                />
              </div>
            )}

            <AppModalLocal
              show={showAddCollaboratorModal}
              setShow={setShowAddCollaboratorModal}
              title={"Share Folder with Others"}
              component={
                <div>
                  {!data.entitiesSelected && (
                    <div>
                      <OrgEntitySelection
                        formState={data}
                        setFormState={setData}
                        multiOrgEntities={true}
                      />
                      <Button
                        variant={"primary"}
                        className={"my-4 w-100"}
                        disabled={
                          !data.entity_ids || data.entity_ids.length === 0
                        }
                        onClick={() => {
                          setData({
                            ...data,
                            entitiesSelected: true,
                          })
                        }}
                      >
                        Next: Role & Permissions
                      </Button>
                    </div>
                  )}

                  {data.entitiesSelected && (
                    <div>
                      <GenericForm
                        formConfigs={DocumentFormConfig.folderSharing}
                        stateData={data}
                        setStateData={setData}
                        useGlobalState={false}
                        onSubmit={(data) => {
                          const submitData = { ...data }
                          delete submitData.validations

                          postRequest(
                            `/document/folder/${folder.id}/share`,
                            setGlobalLoading,
                            (response) => {
                              setShowAddCollaboratorModal(false)
                              setData({})
                              updateObjectState(setFolder, {
                                members_info: response.members_info,
                              })
                            },
                            submitData,
                            false,
                            false
                          )
                        }}
                      />
                      <p
                        className={"mt-1 pointer text-center"}
                        onClick={() => {
                          setData({
                            entitiesSelected: false,
                          })
                        }}
                      >
                        <FaChevronLeft className={"me-1"} /> Back
                      </p>
                    </div>
                  )}
                </div>
              }
            />

            <AppOffCanvasLocal
              showOffCanvas={showShareOffCanvas}
              setShowOffCanvas={setShowShareOffCanvas}
              backdropClose={true}
              canvasWidthPercentage={70}
              title={"Members"}
              component={
                <div>
                  {folder.permissions.includes(DocumentPermissions.SHARE) && (
                    <AddItemButton
                      title={"Add"}
                      onClick={() => {
                        setShowAddCollaboratorModal(true)
                      }}
                    />
                  )}

                  <Alert
                    className={"my-4"}
                    variant={"warning"}
                  >
                    When multiple roles are assigned to an entity through
                    employee selection or entity selection (e.g. department),
                    The most permissive role will be applied to the entity. E.g.
                    If an entity is assigned both "Writer" and "Reader" roles,
                    the "Writer" role will be applied.
                    <br />
                    <br />
                    All ROLES & PERMISSIONS will be applied with preference to
                    ALL documents, folders, and sub-folders within this folder.
                  </Alert>

                  <DocMembersTable
                    membersInfo={folder.members_info}
                    showPermissions={true}
                    showRole={true}
                    emptyText={
                      "This folder has not been directly shared with others. Note that if a parent folder of this folder is shared with others, they will be able to access this folder."
                    }
                    canManage={(member) => {
                      return [
                        folder.author_employee.id,
                        member.author_employee_id,
                      ].includes(employee.id)
                    }}
                    onDelete={(member) => {
                      showAppModal({
                        title: "Delete Document Member",
                        component: (
                          <p>
                            Are you sure you want to delete this folder member?
                          </p>
                        ),
                        truthyFunction: () => {
                          deleteRequest(
                            `/document/folder/member/${member.id}`,
                            setGlobalLoading,
                            (response) => {
                              updateObjectState(setFolder, {
                                members_info: response.members_info,
                              })
                            }
                          )
                        },
                      })
                    }}
                    onEdit={(member) => {
                      updateContextState({
                        formData: {
                          role: member.role,
                          permissions: member.permissions,
                        },
                      })

                      showAppModal({
                        title: "Edit Role",
                        hideFooter: true,
                        component: (
                          <div>
                            <GenericForm
                              formConfigs={DocumentFormConfig.folderSharing.slice(
                                0,
                                2
                              )}
                              onSubmit={(data) => {
                                const submitData = { ...data }
                                delete submitData.validations

                                patchRequest(
                                  `/document/folder/member/${member.id}`,
                                  setGlobalLoading,
                                  (response) => {
                                    updateObjectState(setFolder, {
                                      members_info: response.members_info,
                                    })
                                    closeModal()
                                  },
                                  submitData,
                                  false,
                                  false
                                )
                              }}
                            />
                          </div>
                        ),
                      })
                    }}
                  />
                </div>
              }
            />
          </div>
        </ProductPermissionCheckView>
      }
    />
  )
}

function DocumentsFolderPage() {
  return <DashboardPageContainer PageComponent={DocumentsFolder} />
}

export default DocumentsFolderPage
