import React, { useRef } from "react"
import { Form } from "react-bootstrap"

export default function AutoGrowTextarea({
  value,
  onChange,
  placeholder = "",
  disabled = false,
  minRows = 1,
  maxRows = 5,
  className = "",
  style = {},
}) {
  const textAreaRef = useRef(null)

  // We'll assume ~20px line-height for calculations
  const LINE_HEIGHT_PX = 20

  const handleChange = (e) => {
    const textarea = e.target
    onChange(e) // pass event up so parent can manage `value`

    // Reset height to auto so we measure full scrollHeight
    textarea.style.height = "auto"

    // Calculate the new height
    const scrollHeight = textarea.scrollHeight
    const maxHeight = LINE_HEIGHT_PX * maxRows

    // Cap the height at maxHeight
    const newHeight = Math.min(scrollHeight, maxHeight)

    textarea.style.height = newHeight + "px"
  }

  return (
    <Form.Control
      as="textarea"
      ref={textAreaRef}
      rows={minRows}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={handleChange}
      className={className}
      style={{
        resize: "none",
        overflow: "auto",
        ...style,
      }}
    />
  )
}
