import React, { useCallback, useContext, useEffect, useState } from "react"
// import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom"
import Home from "./pages/Home"
import SignUp from "./pages/SignUp"
import Registration from "./pages/Registration"
import { AppRoutes } from "./utils/appRoutes"
import SignIn from "./pages/SignIn"
import { apiRequest } from "./utils/apiRequests"
import { AppContext } from "./utils/components/AppContext"
import Admin from "./pages/Admin"
import PeopleManagement from "./pages/PeopleManagement"
import EmployeeManagement from "./pages/EmployeeManagement"
import PayrollManagement from "./pages/PayrollManagement"
import RecruitmentManagement from "./pages/RecruitmentManagement"
import FinanceManagement from "./pages/FinanceManagement"
import OrganizationChartComponent from "./pages/OrganizationChart"
import TimeOffRequestsReview from "./pages/TimeOffRequestsReview"
import SchedulePage from "./pages/SchedulePage"
import MyProfilePage from "./pages/MyProfile"
import OrganizationTreePage from "./pages/OrganizationTreePage"
import IndexPage from "./pages/IndexPage"
import { useApi } from "./hooks/useApi"
import TermsOfServicePage from "./pages/TermsOfServicePage"
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage"
import ManagersHubPage from "./pages/ManagersHubPage"
import SupervisorTeamViewPage from "./pages/SupervisorTeamViewPage"
import PasswordResetPage from "./pages/PasswordResetPage"
import GoSLIndexPage from "./pages/gosl/GoslIndexPage"
import { appConfigs } from "./configs"
import BulkAddEmployeesPage from "./pages/BulkAddEmployeesPage"
import { Image, Spinner } from "react-bootstrap"
import ContactCardDownload from "./pages/ContactCardDownload"
import StaffMainPage from "./pages/Staff/StaffMainPage"
import StaffOrganizationViewPage from "./pages/Staff/StaffOrganizationViewPage"
import SubscriptionExpiredPage from "./pages/SubscriptionExpiredPage"
import OrganogramPage from "./pages/Organogram"
import PerformanceMainPage from "./pages/PerformanceMainPage"
import PerformanceReviewPeriodDetailsPage from "./pages/PerformanceReviewPeriodDetailsPage"
import PerformanceReviewPeriodFormPage from "./pages/PerformanceReviewPeriodFormPage"
import EmployeePerformanceReviewsPage from "./pages/EmployeePerformanceReviewsPage"
import FillPerformanceReviewFormPagePage from "./pages/FillPerformanceReviewFormPage"
import EmployeePerformancePeriodFormReviewsPage from "./pages/EmployeePerformancePeriodFormReviews"
import AccountingMainPage from "./pages/AccountingMainPage"
import PayPeriodDetailsPage from "./pages/PayPeriodDetailsPage"
import AppointmentsPage from "./pages/AppointmentsPage"
import AttendanceMainPage from "./pages/AttendanceMainPage"
import TimeOffMainPage from "./pages/TimeOffMainPage"
import OrganizationStructurePage from "./pages/OrganizationStructure"
import ManageExternamAppointmentsPage from "./pages/ManageExternamAppointmentsPage"
import ExternalAppointmentBookingPage from "./pages/ExternalAppointmentBookingPage"
import DocumentsMainPage from "./pages/components/Documents/DocumentsMainPage"
import DocumentDetailsPage from "./pages/components/Documents/DocumentDetailsPage"
import CollaborativeDocPage from "./pages/components/Documents/CollaborativeDocPage"
import DocumentsFolderPage from "./pages/components/Documents/DocumentFolderPage"
import ApprovalRequestsMainPage from "./pages/components/ApprovalRequests/ApprovalRequestsMainPage"
import FormDetailsPage from "./pages/components/FormsProduct/FormDetailsPage"
import FormMemberFormDetailsPage from "./pages/components/FormsProduct/FormMemberFormDetails"

function App() {
  const { contextState, updateContextState } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)

  const checkAuth = async () => {
    setIsLoading(true)
    const { success, response } = await apiRequest.get(`/user/authn`)
    updateContextState({
      authenticated: success,
      darkMode: localStorage.getItem("theme") === "dark",
      ...response,
    })
  }

  useEffect(() => {
    checkAuth()
  }, [])

  useEffect(() => {
    if ([true, false].includes(contextState.authenticated)) {
      setIsLoading(false)
    }
  }, [contextState.authenticated])

  if (isLoading) {
    return (
      <div
        className={`text-center min-vh-100 d-flex align-items-center justify-content-center flex-column`}
      >
        <Spinner
          animation="grow"
          role="status"
        >
          <Image
            src={appConfigs.primaryLogo}
            alt={`${appConfigs.appName} logo`}
            height={"45"}
            width={"45"}
            className="align-self-center"
          />
        </Spinner>
        <div className={"mt-4"}>
          <small>Loading...</small>
        </div>
      </div>
    )
  }

  return (
    <Router>
      <Routes>
        {/*<Route exact path={AppRoutes.Index.path} element={<IndexPage />} />*/}
        <Route
          exact
          path={AppRoutes.Index.path}
          element={
            appConfigs.IndexPage ? <appConfigs.IndexPage /> : <IndexPage />
          }
        />
        <Route
          exact
          path={AppRoutes.Home.path}
          element={<Home />}
        />
        <Route
          exact
          path={AppRoutes.SignUp.path}
          element={<SignUp />}
        />
        <Route
          exact
          path={AppRoutes.SignIn.path}
          element={<SignIn />}
        />
        <Route
          exact
          path={AppRoutes.Registration.path}
          element={<Registration />}
        />
        <Route
          exact
          path={AppRoutes.Admin.path}
          element={<Admin />}
        />
        <Route
          exact
          path={AppRoutes.People.path}
          element={<PeopleManagement />}
        />
        <Route
          exact
          path={AppRoutes.Attendance.path}
          element={<AttendanceMainPage />}
        />
        <Route
          exact
          path={AppRoutes.Performance.path}
          element={<PerformanceMainPage />}
        />
        <Route
          exact
          path={AppRoutes.ManagersHub.path}
          element={<ManagersHubPage />}
        />
        <Route
          exact
          path={AppRoutes.Appointments.path}
          element={<AppointmentsPage />}
        />
        <Route
          exact
          path={AppRoutes.ManageExternamAppointments.path}
          element={<ManageExternamAppointmentsPage />}
        />
        <Route
          exact
          path={AppRoutes.ExternalAppointmentBooking.path}
          element={<ExternalAppointmentBookingPage />}
        />
        <Route
          exact
          path={AppRoutes.TeamSupervisorView.path}
          element={<SupervisorTeamViewPage />}
        />
        <Route
          exact
          path={AppRoutes.Employee.path}
          element={<EmployeeManagement />}
        />
        <Route
          exact
          path={AppRoutes.Payroll.path}
          element={<PayrollManagement />}
        />
        <Route
          exact
          path={AppRoutes.Recruitment.path}
          element={<RecruitmentManagement />}
        />
        <Route
          exact
          path={AppRoutes.Finance.path}
          element={<FinanceManagement />}
        />
        <Route
          exact
          path={AppRoutes.OrgChart.path}
          element={<OrganogramPage />}
        />
        <Route
          exact
          path={AppRoutes.OrgStructure.path}
          element={<OrganizationStructurePage />}
        />
        <Route
          exact
          path={AppRoutes.MyProfile.path}
          element={<MyProfilePage />}
        />
        <Route
          exact
          path={AppRoutes.TimeOffRequestsReview.path}
          element={<TimeOffRequestsReview />}
        />
        <Route
          exact
          path={AppRoutes.Schedule.path}
          element={<SchedulePage />}
        />
        <Route
          exact
          path={AppRoutes.TermsOfService.path}
          element={<TermsOfServicePage />}
        />
        <Route
          exact
          path={AppRoutes.PrivacyPolicy.path}
          element={<PrivacyPolicyPage />}
        />
        <Route
          exact
          path={AppRoutes.PasswordReset.path}
          element={<PasswordResetPage />}
        />
        <Route
          exact
          path={AppRoutes.PasswordChange.path}
          element={<PasswordResetPage isEmailEntry={false} />}
        />
        <Route
          exact
          path={AppRoutes.BulkAddEmployees.path}
          element={<BulkAddEmployeesPage />}
        />
        <Route
          exact
          path={"/contacts/:id"}
          element={<ContactCardDownload />}
        />
        <Route
          exact
          path={AppRoutes.Staff.path}
          element={<StaffMainPage />}
        />
        <Route
          exact
          path={AppRoutes.SubscriptionExpired.path}
          element={<SubscriptionExpiredPage />}
        />
        <Route
          exact
          path={AppRoutes.StaffOrgView.path}
          element={<StaffOrganizationViewPage />}
        />
        <Route
          exact
          path={AppRoutes.PerformanceReviewPeriod.path}
          element={<PerformanceReviewPeriodDetailsPage />}
        />
        <Route
          exact
          path={AppRoutes.PerformanceReviewPeriodForm.path}
          element={<PerformanceReviewPeriodFormPage />}
        />
        <Route
          exact
          path={AppRoutes.PerformanceReviews.path}
          element={<EmployeePerformanceReviewsPage />}
        />
        <Route
          exact
          path={AppRoutes.CompletePerformanceReview.path}
          element={<FillPerformanceReviewFormPagePage />}
        />
        <Route
          exact
          path={AppRoutes.ViewEmployeePerformanceReviews.path}
          element={<EmployeePerformancePeriodFormReviewsPage />}
        />
        <Route
          exact
          path={AppRoutes.Accounting.path}
          element={<AccountingMainPage />}
        />
        <Route
          exact
          path={AppRoutes.PayPeriod.path}
          element={<PayPeriodDetailsPage />}
        />
        <Route
          exact
          path={AppRoutes.TimeOff.path}
          element={<TimeOffMainPage />}
        />
        <Route
          exact
          path={AppRoutes.Documents.path}
          element={<DocumentsMainPage />}
        />
        <Route
          exact
          path={AppRoutes.DocumentDetais.path}
          element={<DocumentDetailsPage />}
        />
        <Route
          exact
          path={AppRoutes.DraftDocumentDetais.path}
          element={<DocumentDetailsPage />}
        />
        <Route
          exact
          path={AppRoutes.DocumentCollaborationDraft.path}
          element={<CollaborativeDocPage />}
        />
        <Route
          exact
          path={AppRoutes.DocumentCollaborationPublished.path}
          element={<CollaborativeDocPage />}
        />

        <Route
          exact
          path={AppRoutes.DocumentTemplateCollaborationDraft.path}
          element={<CollaborativeDocPage />}
        />
        <Route
          exact
          path={AppRoutes.DocumentTemplateCollaborationPublished.path}
          element={<CollaborativeDocPage />}
        />
        <Route
          exact
          path={AppRoutes.DocumentFolder.path}
          element={<DocumentsFolderPage />}
        />
        <Route
          exact
          path={AppRoutes.Requests.path}
          element={<ApprovalRequestsMainPage />}
        />
        <Route
          exact
          path={AppRoutes.FormDetails.path}
          element={<FormDetailsPage />}
        />
        <Route
          exact
          path={AppRoutes.FormMemberSubmitView.path}
          element={<FormMemberFormDetailsPage />}
        />
        <Route
          exact
          path={AppRoutes.FormMemberView.path}
          element={<FormMemberFormDetailsPage />}
        />
        <Route
          exact
          path={AppRoutes.FormApproverView.path}
          element={<FormMemberFormDetailsPage />}
        />
      </Routes>
    </Router>
  )
}

export default App
