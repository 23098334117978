import React, { useContext } from "react"
import { Image, Spinner } from "react-bootstrap"
import { AppContext } from "../../../utils/components/AppContext"
import { appConfigs } from "../../../configs"

export default function GlobalLoadingOverlay() {
  const { contextState } = useContext(AppContext)

  if (!contextState.globalLoading) return null // Don't render anything if show=false

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 99999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className={"flex-column"}
    >
      <Spinner
        animation="grow"
        role="status"
      >
        <Image
          src={appConfigs.primaryLogo}
          alt={`${appConfigs.appName} logo`}
          height={"45"}
          width={"45"}
          className="align-self-center"
        />
      </Spinner>

      <div className={"mt-4"}>
        <small>Please Wait...</small>
      </div>
    </div>
  )
}
