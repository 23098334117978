import React, { useContext, useEffect, useState } from "react"
import { LoadingView, TextWithLineBreaks } from "../../../utils/components"
import { Alert, Button, Card } from "react-bootstrap"
import { FormFields, FormFieldTypes } from "../../FormFields"
import { FaCheck, FaTimes, FaTimesCircle } from "react-icons/fa"
import ImageViewerComponent from "../Shared/ImageViewerComponent"
import PDFViewer from "../Shared/PDFViewer"
import { useAppModal } from "../../../utils/functions"
import { AppContext } from "../../../utils/components/AppContext"
import { dateFormats, formatDateInDayJs } from "../../../utils/dateTime"

function FormSubmissionResponsesView({
  submission,
  fields,
  onActionComplete,
  getRequest,
  postRequest,
  forApproval = false,
}) {
  const { showAppModal } = useAppModal()

  const { setGlobalLoading } = useContext(AppContext)

  const [entries, setEntries] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    getRequest(`/form/submission/${submission.id}`, setIsLoading, (res) => {
      setEntries(res.entries)
    })
  }, [])

  const submitAction = (isApproval) => {
    const action = isApproval ? "Approve" : "Reject"

    showAppModal({
      title: `${action} Submission`,
      component: (
        <Alert variant={isApproval ? "success" : "danger"}>
          <h5 className={"line-"}>
            Are you sure you want to {action} this submission?
            <br />
            <br />
            This cannot be undone.
          </h5>
        </Alert>
      ),
      truthyFunction: () => {
        postRequest(
          `/form/submission/${submission.id}/action/${action.toLowerCase()}`,
          setGlobalLoading,
          (res) => {
            onActionComplete(res.submission)
          },
          {
            comments: data.comments,
          }
        )
      },
    })
  }

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div className={"mb-5"}>
          {fields.map((field, index) => {
            const isImage = field.type === FormFieldTypes.IMAGE
            const isFile = field.type === FormFieldTypes.PDF_FILE
            const isList = [
              FormFieldTypes.STRING_SELECT,
              FormFieldTypes.MULTI_STRING_SELECT,
            ].includes(field.type)

            const isDate = field.type === FormFieldTypes.DATE

            const value = entries[field.id]

            return (
              <div
                key={field.id}
                className={"mb-3"}
              >
                <Card>
                  <Card.Header className={"py-3"}>
                    <h6 className={"mb-2"}>{field.labelName}</h6>
                    <small>{field.info}</small>
                  </Card.Header>
                  <Card.Body className={"py-4"}>
                    {value && (
                      <div>
                        {!isImage && !isFile && !isList && !isDate && (
                          <p>
                            <TextWithLineBreaks text={value} />
                          </p>
                        )}

                        {isImage && <ImageViewerComponent imageUrl={value} />}

                        {isFile && <PDFViewer pdfUrl={value} />}

                        {isDate && <p>{formatDateInDayJs(value)}</p>}

                        {isList && (
                          <ul>
                            {value.map((v, i) => (
                              <li key={i}>{v}</li>
                            ))}
                          </ul>
                        )}
                      </div>
                    )}

                    {!value && <p>No response</p>}
                  </Card.Body>
                </Card>
              </div>
            )
          })}

          {forApproval && submission.can_approve && (
            <Card className={"my-5"}>
              <Card.Header
                className={"bg-primary text-white text-center fs-4 py-3"}
              >
                Your Action
              </Card.Header>
              <Card.Body className={"pt-4"}>
                <FormFields
                  valueKey={"comments"}
                  state={data}
                  setState={setData}
                  isRequired={false}
                  info={"Enter comments to explain or support your action"}
                  type={FormFieldTypes.TEXT_AREA}
                />
              </Card.Body>
              <Card.Footer
                className={"py-4 gap-3 d-flex justify-content-between"}
              >
                <Button
                  variant={"danger"}
                  className={"w-50"}
                  onClick={() => {
                    submitAction(false)
                  }}
                >
                  <FaTimes /> Reject
                </Button>

                <Button
                  variant={"success"}
                  className={"w-50"}
                  onClick={() => {
                    submitAction(true)
                  }}
                >
                  <FaCheck /> Approve
                </Button>
              </Card.Footer>
            </Card>
          )}
        </div>
      }
    />
  )
}

export default FormSubmissionResponsesView
