import React, { useEffect, useState } from "react"
import EmployeeTable from "../EmployeeTable"
import PaginationComponent from "../../../utils/components/PaginationComponent"
import { LoadingView } from "../../../utils/components"
import { Button } from "react-bootstrap"
import { RiRefreshLine } from "react-icons/ri"

function PaginatedEmployeesDisplay({
  endpoint,
  onItemClick,
  getRequest,
  hide,
  extraColumns,
  minimal = false,
  noEmployeesMsg = "No employees to display",
  externalEmployees = null,
  setExternalEmployees = null,
  externalPagingInfo = null,
  setExternalPagingInfo = null,
}) {
  const [employees, setEmployees] = useState(externalEmployees || {}) // Local state if external is not provided
  const [pagingInfo, setPagingInfo] = useState(externalPagingInfo || {})
  const [activeIndex, setActiveIndex] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  // Sync local employees state when externalEmployees changes
  useEffect(() => {
    if (externalEmployees) {
      setEmployees(externalEmployees)
    }
  }, [externalEmployees])

  useEffect(() => {
    if (externalPagingInfo) {
      setPagingInfo(externalPagingInfo)
    }
  }, [externalPagingInfo])

  const getPageData = (page, refresh = false) => {
    const url = `${endpoint}/${page}`
    const pageStr = page.toString()

    // If data isn't already available for the requested page
    if (refresh || !employees[pageStr]) {
      getRequest(url, setIsLoading, (response) => {
        const updatedEmployees = {
          ...employees,
          [page]: response.employees,
        }
        const updatedPagingInfo = {
          ...pagingInfo,
          [page]: response.paging_info,
        }

        // If externalEmployees is managed outside, update it
        if (setExternalEmployees) {
          setExternalEmployees(updatedEmployees)
        } else {
          setEmployees(updatedEmployees)
        }

        if (setExternalPagingInfo) {
          setExternalPagingInfo(updatedPagingInfo)
        } else {
          setPagingInfo(updatedPagingInfo)
        }
      })
    }
    setActiveIndex(page)
  }

  useEffect(() => {
    getPageData(1)
  }, [])

  if (hide) {
    return null // Avoid unnecessary re-renders when hidden
  }

  return (
    <LoadingView
      fullHeight={false}
      centerLoader={false}
      isLoading={isLoading}
      view={
        <div>
          <div className={"d-flex justify-content-end mb-3"}>
            <Button
              variant={"outline-primary"}
              size={"sm"}
              onClick={() => {
                getPageData(1, true)
              }}
            >
              <RiRefreshLine />
            </Button>
          </div>
          <EmployeeTable
            pagingInfo={pagingInfo[activeIndex] || {}}
            employees={employees[activeIndex] || []}
            extraColumns={extraColumns}
            noEmployeesMsg={noEmployeesMsg}
            onClick={
              onItemClick
                ? (employee) => {
                    onItemClick(employee)
                  }
                : null
            }
            minimal={minimal}
          />

          {employees[activeIndex] && employees[activeIndex].length > 0 && (
            <div className="mt-5 d-flex justify-content-end">
              <PaginationComponent
                pagingInfo={pagingInfo[activeIndex] || {}}
                onPageChange={(page) => {
                  getPageData(page)
                }}
              />
            </div>
          )}
        </div>
      }
    />
  )
}

export default PaginatedEmployeesDisplay
