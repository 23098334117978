import React, { useState } from "react"
import { Form, Card, Button } from "react-bootstrap"
import { FaFileUpload, FaFileAlt } from "react-icons/fa"
import { globalReplaceComma, useAppModal } from "../../../utils/functions"
import { PDFDocument } from "pdf-lib"

const FileUpload = ({
  valueKey,
  maxFileMb = 5,
  minValue,
  maxValue,
  disabled,
  setState,
  allowedFileExt,
  allowedFileTypes,
}) => {
  const { showAppModal } = useAppModal()

  const [fileName, setFileName] = useState(null)

  allowedFileTypes = allowedFileTypes || [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/msword",
    "application/vnd.ms-excel",
    "application/vnd.ms-powerpoint",
  ]

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (
      file &&
      allowedFileTypes.includes(file.type) &&
      file.size <= maxFileMb * 1000000
    ) {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)

      reader.onloadend = async () => {
        try {
          const fileData = new Uint8Array(reader.result)

          // Additional validation for PDFs
          if (file.type === "application/pdf") {
            const pdfDoc = await PDFDocument.load(fileData)
            const numPages = pdfDoc.getPageCount()

            if (
              (!minValue || numPages >= minValue) &&
              (!maxValue || numPages <= maxValue)
            ) {
              setState((prevState) => ({
                ...prevState,
                [valueKey]: file,
                [`${valueKey}_url`]: reader.result,
                [`${valueKey}_size_mb`]: file.size / 1_000_000,
              }))
              setFileName(file.name)
            } else {
              showAppModal({
                title: "Invalid PDF",
                hideFooter: true,
                component: (
                  <p>
                    The uploaded PDF has {numPages} pages. It must have between{" "}
                    {parseInt(minValue)} and {parseInt(maxValue)} pages.
                  </p>
                ),
              })
            }
          } else {
            setState((prevState) => ({
              ...prevState,
              [valueKey]: file,
              [`${valueKey}_url`]: reader.result,
              [`${valueKey}_size_mb`]: file.size / 1_000_000,
            }))
            setFileName(file.name)
          }
        } catch (error) {
          showAppModal({
            title: "Error",
            hideFooter: true,
            component: <p>There was an error reading the file.</p>,
          })
        }
      }
    } else {
      showAppModal({
        title: "Invalid File",
        hideFooter: true,
        component: (
          <p>
            Invalid file type or size. Please select a valid file smaller than{" "}
            {maxFileMb} MB.
          </p>
        ),
      })
    }
  }

  return (
    <Card className="text-center p-4">
      <Card.Body>
        {fileName ? (
          <FaFileAlt
            size={50}
            className="text-primary mb-3"
          />
        ) : (
          <FaFileUpload
            size={50}
            className="text-secondary mb-3"
          />
        )}
        <Card.Title className={"mb-3"}>
          {fileName ? fileName : "No file selected"}
        </Card.Title>
        <Card.Subtitle className={"small text-muted"}>
          Accepts
          <br />
          {globalReplaceComma(allowedFileExt, ", ")}
          <br />
          <br />
          Max Size:
          {maxFileMb} MB
        </Card.Subtitle>
        <Form.Group controlId="formFileUpload">
          <Form.Control
            type={"file"}
            name={valueKey}
            accept={allowedFileExt}
            className={"d-none"}
            disabled={disabled}
            onChange={handleFileChange}
          />
          <Button
            variant="primary"
            className="mt-3"
            onClick={() => document.getElementById("formFileUpload").click()}
            disabled={disabled}
          >
            {fileName ? "Change File" : "Select File"}
          </Button>
        </Form.Group>
      </Card.Body>
    </Card>
  )
}

export default FileUpload
