import React, { useContext } from "react"
import { FaEdit, FaFolder } from "react-icons/fa"
import { Alert, Button, OverlayTrigger, Table } from "react-bootstrap"
import { dateFormats, formatDateInDayJs } from "../../../utils/dateTime"
import { NoInfoCard, TextWithLineBreaks } from "../../../utils/components"
import { AppContext } from "../../../utils/components/AppContext"
import { EmployeePopover } from "../EmployeeCards"
import ThreeDotMenu from "../Shared/ThreeDotMenu"
import { FaTrashCan } from "react-icons/fa6"
import { useAppModal, useOffCanvas } from "../../../utils/functions"
import GenericForm from "../../Forms/Shared/GenericForm"
import { SharedFormConfigs } from "../../Forms/FormsConfigs/SharedFormConfigs"

function FoldersList({
  folders,
  setFolders,
  onRowClick,
  deleteRequest,
  patchRequest,
}) {
  const { contextState, updateContextState, setGlobalLoading } =
    useContext(AppContext)
  const { employee } = contextState
  const { showAppModal } = useAppModal()
  const { showOffCanvas } = useOffCanvas()

  if (folders.length === 0) {
    return <NoInfoCard text={"There are no folders to display"} />
  } else {
    return (
      <div>
        <Table
          className={"table-striped"}
          responsive={true}
        >
          <thead>
            <tr>
              <th
                style={{
                  maxWidth: "50px",
                }}
              ></th>
              <th>Name</th>
              <th>Owner</th>
              <th>Created On</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {folders.map((folder) => {
              return (
                <tr
                  key={folder.id}
                  className={"align-middle"}
                >
                  <td
                    className={
                      "align-items-center text-center align-middle fs-6"
                    }
                  >
                    <FaFolder size={20} />
                  </td>
                  <td
                    className={"pointer"}
                    onClick={() => {
                      onRowClick(folder)
                    }}
                  >
                    {folder.name}
                    <br />
                    <span className={"flex-shrink-1 text-muted"}>
                      <TextWithLineBreaks text={folder.description} />
                    </span>
                  </td>
                  <td
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    {folder.author_employee.id !== employee.id ? (
                      <div>
                        <OverlayTrigger
                          trigger="click"
                          placement="top"
                          overlay={
                            <EmployeePopover
                              employee={folder.author_employee}
                            />
                          }
                          rootClose
                        >
                          <Button
                            variant="link"
                            className=""
                          >
                            {folder.author_employee.full_name}
                          </Button>
                        </OverlayTrigger>
                      </div>
                    ) : (
                      "You"
                    )}
                  </td>
                  <td>
                    {formatDateInDayJs(
                      folder.timestamp,
                      dateFormats.shortDayShortMonthFullDayYearTime12Hr
                    )}
                  </td>

                  <td className={"text-end"}>
                    {folder.is_owner && (
                      <ThreeDotMenu
                        items={[
                          {
                            text: (
                              <span>
                                <FaEdit className={"me-2"} /> Rename
                              </span>
                            ),
                            onClick: () => {
                              updateContextState({
                                formData: { ...folder },
                              })

                              showOffCanvas({
                                title: "Rename Folder",
                                subtitle:
                                  "Edit the name and description of the folder",
                                component: (
                                  <GenericForm
                                    formConfigs={SharedFormConfigs.nameAndDescription()}
                                    onSubmit={(data) => {
                                      patchRequest(
                                        `/document/folder/${folder.id}`,
                                        setGlobalLoading,
                                        (res) => {
                                          // Update folder in list
                                          setFolders(
                                            folders.map((f) => {
                                              if (f.id === folder.id) {
                                                return res.folder
                                              } else {
                                                return f
                                              }
                                            })
                                          )
                                        },
                                        data
                                      )
                                    }}
                                  />
                                ),
                              })
                            },
                          },
                          // {
                          //   text: (
                          //     <span>
                          //       <BiTransfer className={"me-2"} /> Move
                          //     </span>
                          //   ),
                          //   disabled: !folder.can_move,
                          //   onClick: () => {},
                          // },
                          {
                            text: (
                              <span className={"text-danger"}>
                                <FaTrashCan className={"me-2"} /> Delete
                              </span>
                            ),
                            disabled: !folder.can_delete,
                            onClick: () => {
                              showAppModal({
                                title: `Delete "${folder.name}"?`,
                                component: (
                                  <Alert variant={"danger"}>
                                    Are you sure? This cannot be undone.
                                  </Alert>
                                ),
                                truthyFunction: () => {
                                  deleteRequest(
                                    `/document/folder/${folder.id}`,
                                    setGlobalLoading,
                                    () => {
                                      // remove folder form list
                                      setFolders(
                                        folders.filter(
                                          (f) => f.id !== folder.id
                                        )
                                      )
                                    }
                                  )
                                },
                              })
                            },
                          },
                        ]}
                      />
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

export default FoldersList
