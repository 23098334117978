import React from "react"
import { Dropdown } from "react-bootstrap"
import { BsThreeDotsVertical } from "react-icons/bs"

// Custom dropdown toggle to remove the caret
const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <button
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
    className="bg-transparent border-0 p-0"
    style={{ cursor: "pointer" }}
  >
    <BsThreeDotsVertical size={20} />
  </button>
))

const ThreeDotMenu = ({ items = [] }) => {
  /*
   * items: array of objects with the following
   * [
   *  {
   *  text: string,
   *  onClick: function
   * disabled: boolean
   *  },
   * ]
   */

  if (items && items.length > 0) {
    return (
      <Dropdown align="end">
        <Dropdown.Toggle
          as={CustomToggle}
          id="dropdown-menu"
        />

        <Dropdown.Menu>
          {items.map((item, index) => (
            <Dropdown.Item
              key={index}
              onClick={item.onClick}
              disabled={item.disabled}
            >
              {item.text}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

export default ThreeDotMenu
