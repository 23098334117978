import React, { useContext, useEffect, useRef, useState } from "react";
import { ConditionalView, LoadingView } from "../utils/components";
import DashboardPageContainer from "./components/DashboardPageContainer";
import {
  deepCopyObject,
  objectKeysToSnakeCase,
  randomCharacters,
  sameObjectValues,
  toTitleCase,
  useAppModal,
} from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  ProgressBar,
  Row,
  Table,
} from "react-bootstrap";
import Papa from "papaparse";
import {
  FaExclamationTriangle,
  FaFileUpload,
  FaTimes,
  FaTools,
  FaTrash,
} from "react-icons/fa";
import { FormFields } from "./FormFields";
import { EmployeeFormFieldsDefinitions } from "./Forms/Employee/EmployeeFormFieldsDefinitions";
import {
  formatDate,
  formatDateStrings,
  formDate,
  stringToDateTime,
} from "../utils/dateTime";
import { COMMON_DATE_FORMATS } from "../data/dateFormats";
import { apiRequest } from "../utils/apiRequests";

const spreadSheetToCsv = [
  {
    id: 1,
    description:
      "Launch Microsoft Excel and open the spreadsheet file you want to convert by navigating to File > Open and selecting your file.",
  },
  {
    id: 2,
    description:
      "Ensure that your data is neatly organized, with each column having a clear header. Check for any merged cells and unmerge them if necessary, as they may not convert properly.",
  },
  {
    id: 3,
    description:
      "Go to File > Save As. In the dialog box that opens, choose the location where you want to save the file. In the ‘Save as type’ dropdown menu, select CSV (Comma delimited) (*.csv).",
  },
  {
    id: 4,
    description: "Enter a name for your CSV file in the ‘File Name’ field.",
  },
  {
    id: 5,
    description:
      "Click Save. If prompted with a warning that some features might be lost, click Yes to continue.",
  },
  {
    id: 6,
    description:
      "After saving, close your file to avoid making changes in the CSV format accidentally, as it does not support multiple sheets or some formatting and formulas.",
  },
  {
    id: 7,
    description:
      "Optionally, reopen the CSV file with Excel to check the output by going to File > Open and selecting your CSV file.",
  },
];

const usePreventBack = () => {
  useEffect(() => {
    const preventBack = () => {
      window.history.pushState(null, "", window.location.href);
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", preventBack);

    return () => {
      window.removeEventListener("popstate", preventBack);
    };
  }, []);
};

const CsvDataRow = ({ data, index, processedCsvData, setProcessedCsvData }) => {
  const [csvDataState, setCsvDataState] = useState(deepCopyObject(data));
  useEffect(() => {
    if (!sameObjectValues(csvDataState, data)) {
      let allCsvData = [...processedCsvData];
      allCsvData[index] = csvDataState;
      setProcessedCsvData(allCsvData);
    }
  }, [csvDataState]);

  return EmployeeFormFieldsDefinitions(
    csvDataState,
    setCsvDataState,
    true,
    60
  ).map((field, i) => {
    if (["allow_login"].includes(field.valueKey)) {
      return null;
    }
    return (
      <td
        key={i}
        style={{
          minWidth: "250px",
        }}
      >
        <FormFields
          {...field}
          state={csvDataState}
          setState={setCsvDataState}
          hideLabel={true}
        />
      </td>
    );
  });
};

function BulkAddEmployee({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
}) {
  const { contextState } = useContext(AppContext);
  const { permissions, labelsOverride } = contextState;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { showAppModal, closeModal } = useAppModal();

  const [putPercentage, setPutPercentage] = useState(null);
  const [allValid, setAllValid] = useState(false);
  const [bulkUpdateState, setBulkUpdateState] = useState({ country: "SL" });
  const [headersState, setHeadersState] = useState({ country: "SL" });
  const [processedCsvData, setProcessedCsvData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [successfulUploads, setSuccessfulUploads] = useState([]);
  const [failedUploads, setFailedUploads] = useState([]);
  const [uploadingData, setUploadingData] = useState(false);
  const [dateFormat, setDateFormat] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [file, setFile] = useState(null);

  const inputRef = useRef(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = () => {
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const updatedData = results.data.map((data, idx) => {
            const modifiedObj = objectKeysToSnakeCase(data);
            return {
              ...modifiedObj,
              id: `${randomCharacters()}-${idx}`,
              allow_login: true,
              profile_image: null,
              profile_image_url: null,
              wage_type: modifiedObj.wage_type || "Salary",
              country: modifiedObj.country || "SL",
              employment_type: modifiedObj.employment_type || "Full Time",
              start_date: modifiedObj.start_date
                ? formDate(stringToDateTime(modifiedObj.start_date, dateFormat))
                : null,
              date_of_birth: modifiedObj.date_of_birth
                ? formDate(
                    stringToDateTime(modifiedObj.date_of_birth, dateFormat)
                  )
                : null,
            };
          });
          setCsvData(updatedData);
          setProcessedCsvData(updatedData);
        },
      });
    }
  };

  const headerInfos = {
    date_of_birth: "Format should be YYYY-MM-DD e.g. 2021-01-01",
    start_date:
      (labelsOverride.start_date
        ? `This will be the same as "${labelsOverride.start_date}". Be sure to use the above header, we will take care of the rest`
        : "") + "Format should be YYYY-MM-DD e.g. 2021-01-01",
    work_phone:
      "Must start with the + sign followed by the country code e.g. +23230123455",
    middle_name: "Not Required",
    address_1: "Not Required",
    employee_number: labelsOverride.employee_number
      ? `This will become the "${labelsOverride.employee_number}" after upload. Just use the above header, we'll take care of the rest.`
      : null,
  };

  useEffect(() => {
    let isValid = true;

    for (const data of processedCsvData) {
      if (data.validations === undefined) {
        continue;
      } else if (Object.values(data.validations).includes(false)) {
        isValid = false;
        break;
      }
    }

    setAllValid(isValid);
  }, [processedCsvData]);

  const removeFromTable = (indexes) => {
    const updatedData = processedCsvData.filter(
      (_, idx) => !indexes.includes(idx)
    );
    setCsvData(updatedData);
    setProcessedCsvData(updatedData);
  };

  useEffect(() => {
    const updated = successfulUploads.length + failedUploads.length;
    const total = csvData.length;
    if (updated > 0 && total > 0) {
      setUploadPercentage(parseInt((updated / total) * 100));
    }
  }, [successfulUploads, failedUploads]);

  const saveEmployeeData = async (formData, rawData, index) => {
    const { success, response } = await apiRequest.put(
      "/employee?bulk=true",
      formData,
      true
    );
    if (success) {
      console.log("success", rawData.employee_number);
      setSuccessfulUploads((prevSuccessfulUploads) => [
        ...prevSuccessfulUploads,
        { ...rawData },
      ]);
    } else {
      setFailedUploads((prevFailedUploads) => [
        ...prevFailedUploads,
        { ...rawData, errorMessage: response.message },
      ]);
    }
  };

  const showFailedUploads = () => {
    showAppModal({
      title: "Failed Uploads",
      hideFooter: true,
      size: "lg",
      component: (
        <div>
          <Table
            striped
            bordered
            hover
            responsive={true}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Error Message</th>
              </tr>
            </thead>
            <tbody>
              {failedUploads.map((data, i) => {
                const fullName = `${data.first_name} ${
                  data.middle_name || ""
                } ${data.last_name}`;
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{fullName}</td>
                    <td>{data.errorMessage}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ),
    });
  };

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div className={"prevent-swipe-x"}>
          <div>
            <Card body={true} className={"py-3 mb-4"}>
              <h2 className={"text-center mb-3"}>Bulk Add Employees</h2>
              <div className={"text-center"}>
                <p>
                  You can add multiple employees at once by uploading a CSV file
                  containing the employee details. The CSV file should have the
                  following columns: <br />
                  <strong>
                    First Name, Last Name, Email, Phone Number, Date
                  </strong>
                </p>
              </div>
            </Card>

            {csvData.length === 0 ? (
              <Card body={true} className={"py-5"}>
                <Row>
                  <Col lg={6}>
                    <div
                      className={
                        "d-flex align-items-center justify-content-center flex-column"
                      }
                    >
                      <div>
                        <FaFileUpload
                          size={100}
                          className={"text-secondary mb-3"}
                        />
                      </div>

                      <p className={"text-center"}>
                        Upload a CSV file containing employees details.
                        <br />
                        The CSV file should have the listed columns
                      </p>
                    </div>

                    <p className={"mt-3"}>
                      <strong>Have a spreadsheet?</strong>
                      <br />
                      <small>
                        Follow the steps below to convert your spreadsheet into
                        a CSV file
                      </small>
                    </p>
                    <Button
                      variant={"link"}
                      onClick={() => {
                        showAppModal({
                          title: "Convert a Spreadsheet to CSV",
                          hideFooter: true,
                          component: (
                            <ListGroup>
                              {spreadSheetToCsv.map((step) => (
                                <ListGroup.Item key={step.id}>
                                  {step.description}
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          ),
                        });
                      }}
                    >
                      View Steps
                    </Button>

                    <Form className={"mt-4"}>
                      <Form.Group controlId="formFile">
                        <Form.Control
                          ref={inputRef}
                          type="file"
                          onChange={handleFileChange}
                          accept=".csv"
                          className={"d-none"}
                        />
                        <ConditionalView
                          condition={file}
                          trueView={
                            <div className={"mt-2"}>
                              <strong>File Selected: {file?.name}</strong>
                              <br />
                              <div className={"mt-3"}>
                                <Button onClick={handleFileUpload}>
                                  <FaFileUpload className={"mr-2"} /> Process
                                  CSV File
                                </Button>
                                <Button
                                  variant={"outline-danger"}
                                  className="ms-2"
                                  disabled={uploadingData}
                                  onClick={() => {
                                    setFile(null);
                                    setCsvData([]);
                                  }}
                                >
                                  <FaTimes className={"mr-2"} /> Clear File
                                </Button>
                              </div>
                            </div>
                          }
                          falseView={
                            <div className={"mt-3"}>
                              <h5 className={"mb-3"}>
                                Please select the format your dates are in
                              </h5>
                              <Form.Select
                                name="date_format"
                                value={dateFormat || ""}
                                onChange={(e) => {
                                  e.preventDefault();
                                  setDateFormat(e.target.value);
                                }}
                              >
                                <option value="">Select Date Format</option>
                                {COMMON_DATE_FORMATS.map((format, index) => {
                                  const now = new Date();
                                  return (
                                    <option value={format} key={format}>
                                      {format} | e.g. {formatDate(now, format)}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <Button
                                onClick={() => {
                                  inputRef.current.click();
                                }}
                                className={"mt-5 w-100"}
                                disabled={!dateFormat}
                              >
                                <FaFileUpload className={"mr-2"} /> Upload CSV
                                File
                              </Button>
                            </div>
                          }
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col lg={6}>
                    <h4 className={"text-center mb-3"}>CSV Structure</h4>
                    <ul>
                      <li>
                        Your CSV file should have the values listed below as
                        their column headers
                      </li>
                      <li>
                        The order of the columns does not matter, as long as the
                        headers are correct
                      </li>
                      <li>
                        You will have and opportunity to review and edit the
                        data here
                      </li>
                      <li>
                        For items that have restricted values, you will see the
                        allowed values below
                      </li>
                      <li>
                        For the address values, please explore the country,
                        region and city dropdowns to see the allowed values
                      </li>
                      <li>
                        All fields are required unless "Not Required" is
                        specified
                      </li>
                    </ul>

                    <ListGroup>
                      {EmployeeFormFieldsDefinitions(
                        headersState,
                        setHeadersState,
                        true
                      ).map((field, index) => {
                        if (
                          ["profile_image", "allow_login"].includes(
                            field.valueKey
                          )
                        ) {
                          return null;
                        }
                        return (
                          <ListGroup.Item key={index}>
                            <h5>{toTitleCase(field.valueKey)}</h5>

                            {field.optionsList && (
                              <div className={"mt-2"}>
                                <small>Explore Allowed Values</small>
                                <div className={"mb-1"} />
                                <FormFields
                                  {...field}
                                  state={headersState}
                                  setState={setHeadersState}
                                  hideLabel={true}
                                />
                              </div>
                            )}

                            {headerInfos[field.valueKey] && (
                              <div className={"mt-2"}>
                                <small>{headerInfos[field.valueKey]}</small>
                              </div>
                            )}
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </Col>
                </Row>
              </Card>
            ) : (
              <div className={"prevent-swipe-x"}>
                {!uploadingData && (
                  <div className={"d-flex justify-content-end"}>
                    <Button
                      variant={"outline-danger"}
                      className={"my-3"}
                      onClick={() => {
                        setCsvData([]);
                        setProcessedCsvData([]);
                      }}
                    >
                      <FaTimes className={"me-2"} />
                      Clear Data
                    </Button>
                  </div>
                )}
                <Table
                  striped
                  bordered
                  responsive
                  hover
                  className={"prevent-swipe-x"}
                >
                  <thead>
                    <tr className={"text-center fs-6 fw-bold"}>
                      {!uploadingData && (
                        <>
                          <th>Action</th>
                          <th>#</th>
                        </>
                      )}
                      {EmployeeFormFieldsDefinitions(
                        headersState,
                        setHeadersState,
                        true
                      ).map((field, index) => {
                        if (
                          ["allow_login"].includes(field.valueKey) ||
                          uploadingData
                        ) {
                          return null;
                        }
                        return (
                          <th key={index}>{toTitleCase(field.valueKey)}</th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {csvData.map((data, j) => {
                      if (uploadingData) {
                        return null;
                      }
                      return (
                        <tr key={data.id} className={"align-middle"}>
                          <td className={"align-middle"}>
                            <Button
                              variant={"link"}
                              className={"text-danger"}
                              size={"sm"}
                              onClick={() => {
                                removeFromTable([j]);
                              }}
                            >
                              <FaTrash className={"me-2"} />
                              Remove
                            </Button>
                          </td>
                          <td
                            className={"align-middle text-center"}
                            style={{ minWidth: "70px" }}
                          >
                            {j + 1}
                          </td>
                          <CsvDataRow
                            data={data}
                            index={j}
                            processedCsvData={processedCsvData}
                            setProcessedCsvData={setProcessedCsvData}
                          />
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                {!uploadingData && (
                  <div className={"my-5 pb-5 text-center"}>
                    <Button
                      className={"w-25"}
                      size={"lg"}
                      variant={"primary"}
                      disabled={!allValid || uploadingData}
                      onClick={async () => {
                        setFailedUploads([]);
                        setSuccessfulUploads([]);
                        setUploadPercentage(0);
                        setUploadingData(true);
                        let idx = 1;

                        for (const updateData of processedCsvData) {
                          const formData = new FormData();
                          if (
                            updateData.profile_image_url &&
                            updateData.profile_image
                          ) {
                            const image = updateData.profile_image;
                            formData.append("image", image);
                          }
                          formData.append("inputs", JSON.stringify(updateData));
                          await saveEmployeeData(formData, updateData, idx);
                          idx++;
                        }
                      }}
                    >
                      Add Employees
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>

          {uploadingData && (
            <div className={"mt-3"}>
              <Card>
                <Card.Header className={"py-4 text-center"}>
                  <h1 className={"mb-3"}>{`${uploadPercentage}%`}</h1>

                  <ProgressBar
                    animated={true}
                    min={0}
                    max={100}
                    now={uploadPercentage}
                    label={"Processing..."}
                    variant={"success"}
                  />
                  <Alert variant={"danger"} className={"mt-4"}>
                    <Alert.Heading>
                      <FaExclamationTriangle className={"me-2"} />
                      <strong>Important:</strong> Do not close or leave this
                      page until the number above is at 100%
                    </Alert.Heading>
                  </Alert>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col sm={6}>
                      <Card>
                        <Card.Header className={"bg-success"}>
                          <Card.Title className={"fw-bolder text-center"}>
                            Successful Uploads
                          </Card.Title>
                        </Card.Header>
                        <Card.Body className={"py-2"}>
                          <h1 className={"text-center"}>
                            {successfulUploads.length}
                          </h1>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={6}>
                      <Card>
                        <Card.Header className={"bg-danger"}>
                          <Card.Title className={"fw-bolder text-center"}>
                            Failed Uploads
                          </Card.Title>
                        </Card.Header>
                        <Card.Body className={"py-2 text-center"}>
                          <h1 className={"text-center"}>
                            {failedUploads.length}
                          </h1>
                          <div className={"mb-3"}>
                            <Button
                              variant={"outline-primary"}
                              className={"me-3"}
                              disabled={failedUploads.length === 0}
                              onClick={showFailedUploads}
                            >
                              View Failed Uploads
                            </Button>
                            <Button
                              variant={"outline-warning"}
                              disabled={failedUploads.length === 0}
                              onClick={() => {
                                setCsvData(failedUploads);
                                setProcessedCsvData(failedUploads);
                                setUploadingData(false);
                              }}
                            >
                              <FaTools className={"me-2"} />
                              Fix Errors / Update info
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className={"text-end"}>
                  <Button
                    variant={"outline-primary"}
                    // disabled={uploadPercentage < 100}
                    onClick={() => {
                      setUploadingData(false);
                      setSuccessfulUploads([]);
                      setFailedUploads([]);
                      setUploadPercentage(0);
                      setCsvData([]);
                      setProcessedCsvData([]);
                    }}
                  >
                    <FaTimes className={"me-2"} />
                    Close
                  </Button>
                </Card.Footer>
              </Card>
            </div>
          )}
        </div>
      }
    />
  );
}

function BulkAddEmployeePage() {
  return <DashboardPageContainer PageComponent={BulkAddEmployee} />;
}

export default BulkAddEmployeePage;
