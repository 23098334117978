import React from "react"
import { Offcanvas } from "react-bootstrap"
import { useOffCanvas } from "./OffCanvasContext"
import { AppConstants } from "../appConstants"

function AppOffCanvas() {
  const { show, content, closeOffCanvas } = useOffCanvas()

  const width = window.innerWidth
  let style = {}

  if (
    content.widthPercentage &&
    width >= AppConstants.bootstrapBreakpoints.lg
  ) {
    style.width = `${content.widthPercentage}%`
  }

  return (
    <Offcanvas
      show={show}
      onHide={closeOffCanvas}
      placement={"end"}
      backdrop={content.backdropClose ? undefined : "static"}
      style={style}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{content.title}</Offcanvas.Title>
      </Offcanvas.Header>
      <div className={"mb-4 px-3"}>{content.subtitle}</div>
      <Offcanvas.Body>{content.component}</Offcanvas.Body>
    </Offcanvas>
  )
}

export default AppOffCanvas
