import React, { useContext, useEffect, useState } from "react"
import { AddItemButton, LoadingView } from "../../../utils/components"
import {
  estimateUploadTime,
  updateArrayState,
  uploadFileAndMeasureSpeed,
  useAppModal,
  useOffCanvas,
} from "../../../utils/functions"
import { AppContext } from "../../../utils/components/AppContext"
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap"
import GenericForm from "../../Forms/Shared/GenericForm"
import DocumentFormConfig from "../../Forms/FormsConfigs/DocumentFormConfig"
import FileUploadingView from "../Shared/FileUploadingView"
import { OffCanvasContext } from "../../../utils/components/OffCanvasContext"
import {
  FaFileAlt,
  FaFilePdf,
  FaFileUpload,
  FaPlus,
  FaUsers,
} from "react-icons/fa"
import DocumentsTableList from "./DocumentsTableList"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AppRoutes } from "../../../utils/appRoutes"
import { FaFileCirclePlus, FaRegFolderClosed } from "react-icons/fa6"
import { SharedFormConfigs } from "../../Forms/FormsConfigs/SharedFormConfigs"
import FoldersList from "./FoldersTable"
import PagingItemsLoading from "../Shared/PagingItemsLoading"

function DocumentsContainer({
  getRequest,
  putRequest,
  patchRequest,
  deleteRequest,
  title,
  description,
  publishedEndpoint,
  draftsEndpoint,
  collabDraftsEndpoint,
  forTemplates,
  onMembersClick,
  uploadUrlEndpoint = "/document/generate-put-url",
  showAddButton = true,
  folderId = null,
  folderType = "Document",
}) {
  const { showOffCanvas } = useOffCanvas()
  const { contextState, updateContextState, setGlobalLoading } =
    useContext(AppContext)
  const { showAppModal, closeModal } = useAppModal()

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value })
  }

  let defaultTab = null //  searchParams.get("secondActiveTab")

  const isTemplate = forTemplates || folderType === "Template"
  const docNameType = isTemplate ? "Template Document" : "Document"

  if (!defaultTab) {
    if (publishedEndpoint) {
      defaultTab = "published"
    } else if (draftsEndpoint) {
      defaultTab = "draft"
    } else {
      defaultTab = "collaborative_draft"
    }
  }
  const { closeOffCanvas } = useContext(OffCanvasContext)

  const [documents, setDocuments] = useState([])
  const [documentsPaging, setDocumentsPaging] = useState({})

  const [uploadData, setUploadData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(defaultTab)
  const [fetchedTabs, setFetchedTabs] = useState([])

  const [draftDocuments, setDraftDocuments] = useState([])
  const [draftDocumentsPaging, setDraftDocumentsPaging] = useState({})

  const [newDocument, setNewDocument] = useState({})

  const [collarDraftDocuments, setCollarDraftDocuments] = useState([])
  const [collarDraftDocumentsPaging, setCollarDraftDocumentsPaging] = useState(
    {}
  )
  const [folders, setFolders] = useState([])

  useEffect(() => {
    if (publishedEndpoint) {
      getRequest(`/document${publishedEndpoint}`, setIsLoading, (response) => {
        setDocuments(response.documents)
        setFolders(response.folders)
        setDocumentsPaging(response.paging_info)
      })
    } else {
      activateTab(defaultTab)
    }
  }, [])

  const activateTab = (key) => {
    setActiveTab(key)
    if (draftsEndpoint && !fetchedTabs.includes(key) && key === "draft") {
      getRequest(`/document${draftsEndpoint}`, setIsLoading, (response) => {
        setDraftDocuments(response.documents)
        setFetchedTabs([...fetchedTabs, key])
        setDraftDocumentsPaging(response.paging_info)
      })
    }

    if (
      collabDraftsEndpoint &&
      !fetchedTabs.includes(key) &&
      key === "collaborative_draft"
    ) {
      getRequest(
        `/document${collabDraftsEndpoint}`,
        setIsLoading,
        (response) => {
          setCollarDraftDocuments(response.documents)
          setFetchedTabs([...fetchedTabs, key])
          setCollarDraftDocumentsPaging(response.paging_info)
        }
      )
    }
  }

  const updateDocItems = (response, setDocsState, setPagingState) => {
    updateArrayState(setDocsState, response.documents)
    setPagingState(response.paging_info)
  }

  if (uploadData.file) {
    return (
      <FileUploadingView
        {...uploadData}
        onSuccessBtnTxt={`View Document`}
        onSuccessAction={() => {
          navigate(
            `${AppRoutes.DraftDocumentDetais.paramLessPath}/${newDocument.id}`
          )
        }}
        onClose={() => {
          setUploadData({})
        }}
      />
    )
  } else {
    return (
      <LoadingView
        isLoading={isLoading}
        text={"Please Wait..."}
        view={
          <div className={"pb-5"}>
            <div className={"mb-4 gap-2 d-flex justify-content-end"}>
              {onMembersClick && (
                <Button
                  variant={"secondary"}
                  onClick={() => {
                    onMembersClick()
                  }}
                >
                  <FaUsers className={"me-2"} /> Members
                </Button>
              )}

              {showAddButton && (
                <div className={""}>
                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle
                      variant="primary"
                      id="dropdown-basic"
                    >
                      <FaPlus className={"me-"} /> New
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          showOffCanvas({
                            title: `${docNameType} Info`,
                            component: (
                              <div>
                                <GenericForm
                                  formConfigs={DocumentFormConfig.docInfo}
                                  onSubmit={async (data) => {
                                    putRequest(
                                      `/document/generate-version-id?template=${isTemplate}`,
                                      setGlobalLoading,
                                      (response) => {
                                        const endpoint = isTemplate
                                          ? AppRoutes.DocumentTemplateCollaborationDraft
                                          : AppRoutes.DocumentCollaborationDraft

                                        navigate(
                                          `${endpoint.paramLessPath}/${response.document_id}`
                                        )
                                      },
                                      {
                                        ...data,
                                        folder_id: folderId,
                                        is_template: isTemplate,
                                      }
                                    )
                                  }}
                                />
                              </div>
                            ),
                          })
                        }}
                      >
                        <FaFileCirclePlus className={"me-2"} />
                        {docNameType}
                      </Dropdown.Item>

                      {!isTemplate && (
                        <Dropdown.Item
                          onClick={() => {
                            updateContextState({
                              formData: {},
                            })
                            showOffCanvas({
                              title: `Add a new ${docNameType}`,
                              subtitle: `Add a new new ${docNameType}`,
                              component: (
                                <div>
                                  <GenericForm
                                    formConfigs={
                                      DocumentFormConfig.uploadDocument
                                    }
                                    onSubmit={async (data) => {
                                      setIsLoading(true)
                                      closeOffCanvas()
                                      const uploadSecsEstimate =
                                        await estimateUploadTime(
                                          data.document_file_size_mb
                                        )

                                      putRequest(
                                        uploadUrlEndpoint,
                                        setIsLoading,
                                        (response) => {
                                          setNewDocument(response.document)
                                          setUploadData({
                                            file: data.document_file,
                                            presignedUrl: response.put_url,
                                            documentName: data.name,
                                          })
                                        },
                                        {
                                          document_type: "Uploaded File",
                                          name: data.name,
                                          description: data.description,
                                          upload_secs_estimate:
                                            uploadSecsEstimate,
                                          document_extension:
                                            data.document_file.name
                                              .split(".")
                                              .pop(),
                                          folder_id: folderId,
                                        },
                                        false,
                                        true,
                                        false
                                      )
                                    }}
                                  />
                                </div>
                              ),
                            })
                          }}
                        >
                          <FaFileUpload className={"me-2"} />
                          File Upload
                        </Dropdown.Item>
                      )}

                      <Dropdown.Item
                        onClick={() => {
                          updateContextState({
                            formData: {},
                          })

                          showAppModal({
                            title: "New Folder",
                            hideFooter: true,
                            component: (
                              <div>
                                <GenericForm
                                  formConfigs={SharedFormConfigs.nameAndDescription()}
                                  onSubmit={(data) => {
                                    const submitData = {
                                      ...data,
                                      folder_type: folderType,
                                      folder_id: folderId,
                                    }

                                    putRequest(
                                      `/document/folder`,
                                      setGlobalLoading,
                                      (response) => {
                                        closeModal()
                                        setFolders([
                                          response.folder,
                                          ...folders,
                                        ])
                                      },
                                      submitData,
                                      false,
                                      false,
                                      false
                                    )
                                  }}
                                />
                              </div>
                            ),
                          })
                        }}
                      >
                        <FaRegFolderClosed className={"me-2"} />{" "}
                        {folderId ? "Sub Folder" : "Folder"}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>

            <Card>
              <Card.Header className={"py-4"}>
                <Card.Title>{title}</Card.Title>
                <Card.Subtitle
                  as={"p"}
                  className={"mt-3"}
                >
                  {description}
                </Card.Subtitle>
              </Card.Header>
            </Card>

            {folders.length > 0 && (
              <div className={"my-4"}>
                <h3>{`${folderId ? "Sub Folders" : "Folders"}`}</h3>
                <FoldersList
                  folders={folders}
                  setFolders={setFolders}
                  deleteRequest={deleteRequest}
                  patchRequest={patchRequest}
                  onRowClick={(folder) => {
                    navigate(
                      `${AppRoutes.DocumentFolder.paramLessPath}/${folder.id}`
                    )
                  }}
                />
              </div>
            )}

            <Tabs
              activeKey={activeTab}
              className={"mt-3"}
              onSelect={(key) => activateTab(key)}
            >
              {publishedEndpoint && (
                <Tab
                  eventKey="published"
                  title={isTemplate ? "My Published" : "Published"}
                >
                  <DocumentsTableList
                    documents={documents}
                    setDocuments={setDocuments}
                    deleteRequest={deleteRequest}
                    patchRequest={patchRequest}
                    onRowClick={(rowDoc) => {
                      if (rowDoc.type === "User Created") {
                        navigate(
                          `${AppRoutes.DocumentCollaborationPublished.paramLessPath}/${rowDoc.id}`
                        )
                      } else if (rowDoc.type === "Template") {
                        navigate(
                          `${AppRoutes.DocumentTemplateCollaborationPublished.paramLessPath}/${rowDoc.id}`
                        )
                      } else {
                        navigate(
                          `${AppRoutes.DocumentDetais.paramLessPath}/${rowDoc.id}`
                        )
                      }
                    }}
                  />
                  <PagingItemsLoading
                    pagingInfo={documentsPaging}
                    endpoint={`/document${publishedEndpoint}`}
                    getRequest={getRequest}
                    onLoadComplete={(res) => {
                      updateDocItems(res, setDocuments, setDocumentsPaging)
                    }}
                  />
                </Tab>
              )}

              {draftsEndpoint && (
                <Tab
                  eventKey="draft"
                  title={isTemplate ? "My Drafts" : "Drafts"}
                >
                  <DocumentsTableList
                    documents={draftDocuments}
                    setDocuments={setDraftDocuments}
                    deleteRequest={deleteRequest}
                    patchRequest={patchRequest}
                    onRowClick={(rowDoc) => {
                      if (rowDoc.type === "User Created") {
                        navigate(
                          `${AppRoutes.DocumentCollaborationDraft.paramLessPath}/${rowDoc.id}`
                        )
                      } else if (rowDoc.type === "Template") {
                        navigate(
                          `${AppRoutes.DocumentTemplateCollaborationDraft.paramLessPath}/${rowDoc.id}`
                        )
                      } else {
                        navigate(
                          `${AppRoutes.DraftDocumentDetais.paramLessPath}/${rowDoc.id}`
                        )
                      }
                    }}
                  />

                  <PagingItemsLoading
                    pagingInfo={draftDocuments}
                    endpoint={`/document${draftsEndpoint}`}
                    getRequest={getRequest}
                    onLoadComplete={(res) => {
                      updateDocItems(
                        res,
                        setDraftDocuments,
                        setDraftDocumentsPaging()
                      )
                    }}
                  />
                </Tab>
              )}

              {collabDraftsEndpoint && (
                <Tab
                  eventKey="collaborative_draft"
                  title={"Collaborative Drafts"}
                >
                  <DocumentsTableList
                    documents={collarDraftDocuments}
                    setDocuments={setCollarDraftDocuments}
                    deleteRequest={deleteRequest}
                    patchRequest={patchRequest}
                    onRowClick={(rowDoc) => {
                      navigate(
                        `${AppRoutes.DocumentTemplateCollaborationDraft.paramLessPath}/${rowDoc.id}`
                      )
                    }}
                  />
                  <PagingItemsLoading
                    pagingInfo={collarDraftDocumentsPaging}
                    endpoint={`/document${collabDraftsEndpoint}`}
                    getRequest={getRequest}
                    onLoadComplete={(res) => {
                      updateDocItems(
                        res,
                        setCollarDraftDocuments,
                        setCollarDraftDocumentsPaging
                      )
                    }}
                  />
                </Tab>
              )}
            </Tabs>
          </div>
        }
      />
    )
  }
}

export default DocumentsContainer
